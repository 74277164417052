.p-case {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 80px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__title {
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &-list {
    @include mq(tablet) {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      @include mq(tablet) {
        width: calc((100% - 16px * 3) / 4);
        margin-bottom: 24px;
        margin-right: 16px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-last-child(-n+4) {
          margin-bottom: 0;
        }
      }
    }
    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: colors(font);
      &:hover {
        opacity: .8;
      }
    }
    &__text {
      margin-top: 4px;
      font-size: rem(14);
    }
    &-photo {
      overflow: hidden;
      aspect-ratio: 4 / 3;
      &__image {
      }
    }
  }
  &-content {
    p,
    &__text {
      margin-bottom: 24px;
      font-size: rem(16);
      line-height: 1.8;
      &:last-of-type {
        margin-bottom: 64px;
      }
      @include mq(tablet) {
        margin-bottom: 32px;
      }
    }
    &-photo {
      max-width: 688px;
      margin-bottom: 24px;
      @include mq(tablet) {
        margin-bottom: 32px;
      }
    }
    &-author {
      display: flex;
      align-items: center;
      &__shop {
        margin-right: 16px;
        font-size: rem(16);
        font-weight: font(weight);
      }
      &__name {
        font-size: rem(16);
      }
    }
  }
}