/* #base
   -------------------------------------------------------------------------- */

/**
 * 1. `width`と`height`に`margin`と`border`を含めます。
 * 2. ドキュメントのルートフォントサイズを指定します（`rem`で参照）。
 */
*,
*::before,
*::after {
  box-sizing: border-box; /* 1 */
}

 html {
  font-size: font(size); /* 2 */
}

/**
 * 1. できる限り禁則処理が実行されるようにします。
 * 2. フォントの持っているカーニング情報を使用します。
 * 3. Chrome、Safari、FireFoxのフォントレンダリングを合わせます。
 */
body {
  word-wrap: break-word; /* 1 */
  overflow-wrap: break-word; /* 1 */
  background-color: colors(base);
  color: colors(font);
  font-family: font(family);
  font-weight: font(weight);
  font-kerning: normal; /* 2 */
  line-height: font(height);
  letter-spacing: font(spacing);
  -webkit-font-smoothing: subpixel-antialiased; /* 3 */
  -moz-osx-font-smoothing: auto; /* 3 */
}

/**
 * 'font-weight: bold;'を'font-weight: 500;'
 */
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b,
th {
  font-weight: font(weight);
}

/**
 * メインコンテンツ内は横スクロールをさせません。
 */
main {
  overflow-x: hidden;
}

/**
 * マージンをリセットします。
 */
figure {
  margin: 0;
}

/**
 * デフォルトのスタイルをリセットします。
 */
fieldset {
  min-width: auto;
  margin: 0;
  padding: 0;
  border: 0;
}

/**
 * normalize.cssのスタイルをリセットして、divのように扱います。
 */
legend {
  display: block;
}

/**
 * ブロック要素の上下のマージンをリセットします。
 */
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
pre {
  margin-top: 0;
  margin-bottom: 0;
}
blockquote {
  margin-right: 0;
  margin-left: 0;
}

/**
 * 'a'のデフォルトスタイルを指定します。
 * 1. iOSのSafariとChromeで、`text-decoration: underline;`の罫線が
 *    グリフやディセンダで途切れないようにします。
 *    https://css-tricks.com/almanac/properties/t/text-decoration-skip/
 */
a {
  text-decoration: none;
  color: colors(link);
  -webkit-text-decoration-skip: none; /* 1 */
  &:hover {
    text-decoration: underline;
    color: colors(link-dark);
  }
}


/**
 * `ul,ol`と`li`の左端を揃えにし、'list-style'をリセットます。
 */
 ul,
 ol {
   padding-left: 0;
   list-style: none;
 }

/**
 * `dt`と`dd`の左端を揃えます。
 */
dd {
  margin-left: 0;
}

code,
kbd,
samp {
  font-size: 1rem;
}

/**
 * 日本語では斜体を使用しないためリセットします。
 */
i,
cite,
em,
address,
dfn {
  [lang="ja"] & {
    font-style: normal;
  }
}

/**
 * //フルードイメージにします。
 * 'img'の下余白をリセットします。
 */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/**
 * キーボードでアクセスできない要素のフォーカス表示を消します。
 * Credit: https://github.com/suitcss/base
 */
[tabindex="-1"]:focus {
  outline: none !important;
}

/**
 * IE10以降の`input[type="text"]`で入力内容の消去機能を非表示にします。
 */
[type="text"]::-ms-clear {
  visibility: hidden;
}

/**
 * IE10以降の`input[type="password"]`でマスクの一時的解除機能を非表示にします。
 */
[type="password"]::-ms-reveal {
  visibility: hidden;
}

/**
 * IEとEdgeはスピンボタンに未対応のため、ChromeとFireFoxで非表示にします。
 */
[type="number"]::-webkit-outer-spin-button,
[type="number"]::-webkit-inner-spin-button {
  appearance: none;
}

// フォーカスインジゲータはキーボード操作のときにだけ表示します。
[data-whatinput="mouse"] *:focus,
[data-whatinput="touch"] *:focus {
  outline: none;
}

// 'button'のスタイルをリセット
button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
 }
