.p-campaign {
  @include mq(laptop) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: rem(14);
    font-weight: bold;
    color: colors(base);
    @include mq(laptop) {
      width: 1040px;
      border: 1px solid colors(base);
      font-size: rem(18);
    }
    &:hover {
      background-color: colors(base);
      text-decoration: none;
      color: colors(primary);
    }
  }
}