@font-face {
  font-family: 'tanuki-icon';
  src:
    url('https://www.hokuokagu-tanuki.com/apps/note/wp-content/themes/hokuokagu-tanuki/assets/fonts/tanuki-icon.ttf?akjswh') format('truetype'),
    url('https://www.hokuokagu-tanuki.com/apps/note/wp-content/themes/hokuokagu-tanuki/assets/fonts/tanuki-icon.woff?akjswh') format('woff'),
    url('https://www.hokuokagu-tanuki.com/apps/note/wp-content/themes/hokuokagu-tanuki/assets/fonts/tanuki-icon.svg?akjswh#tanuki-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

$icons: (
  tanuki_logo: "\e900",
  user: "\e901",
  search: "\e902",
  cart: "\e903",
  cart-line: "\e904",
  phone: "\e905",
  mail: "\e906",
  payment: "\e907",
  truck: "\e908",
  youtube: "\e909",
  instagram: "\e90a",
  facebook: "\e90b",
  line: "\e90c",
  twitter: "\e90d",
  form: "\e90e",
  circle-add: "\e90f",
  circle-remove: "\e910",
  arrow-up: "\e911",
  arrow-right: "\e912",
  arrow-down: "\e913",
  arrow-left: "\e914",
  chair: "\e915",
  dining-chair: "\e916",
  arm-chair: "\e917",
  stool: "\e918",
  bench: "\e919",
  sofa: "\e91a",
  single-sofa: "\e91b",
  double-sofa: "\e91c",
  triple-sofa: "\e91d",
  ottoman: "\e91e",
  sofa-bed: "\e91f",
  table: "\e920",
  dining-table: "\e921",
  low-table: "\e922",
  side-table: "\e923",
  wagon-table: "\e924",
  nest-table: "\e925",
  sewing-table: "\e926",
  shelf: "\e927",
  book-shelf: "\e928",
  open-shelf: "\e929",
  wall-shelf: "\e92a",
  chest: "\e92b",
  low-chest: "\e92d",
  high-chest: "\e92c",
  side-chest: "\e92e",
  side-board: "\e92f",
  desk: "\e930",
  bureau: "\e931",
  pen: "\e932",
  stocklist: "\e933",
  basket: "\e934",
  container: "\e935",
  wholesaler: "\e936",
  sale: "\e937",
  maintenance: "\e938",
  dish: "\e939",
  light: "\e93a",
  miror: "\e93b",
  calendar: "\e93c",
  category: "\e93d",
  x: "\e93e",
);