.p-maintenance {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    margin-bottom: 8px;
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &__lead {
    font-size: rem(14);
    @include mq(laptop) {
      font-size: rem(16);
    }
  }
  &-movie {
    margin-bottom: 64px;
    &__inner {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      @include mq(tablet) {
        height: auto;
        padding-top: 0;
      }
    }
    &__media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include mq(tablet) {
        position: relative;
        width: 560px;
        height: 315px;
      }
    }
  }
  &-sect {
    display: flex;
    flex-flow: column-reverse;
    align-items: center;
    justify-content: space-between;
    &:not(:first-of-type) {
      margin-top: 32px;
    }
    @include mq(tablet) {
      flex-flow: row;
      &:nth-of-type(even) {
        flex-flow: row-reverse;
      }
    }
    &__cont {
      padding: 0 8px;
      @include mq(tablet) {
        width: calc((100% - 16px) / 2);
        padding: 0;
      }
    }
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(20);
      @include mq(laptop) {
        margin-bottom: 16px;
        font-size: rem(24);
      }
    }
    &__txt {
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__photo {
      margin-bottom: 24px;
      @include mq(tablet) {
        width: calc((100% - 16px) / 2);
        margin-bottom: 0;
      }
    }
  }
  &-difference {
    margin-top: 40px;
    @include mq(laptop) {
      margin-top: 80px;
    }
    &__ttl {
      margin-bottom: 16px;
      font-size: rem(20);
      @include mq(laptop) {
        margin-bottom: 24px;
        font-size: rem(24);
      }
    }
    &__maker {
      padding-right: 8px;
      background: linear-gradient(transparent 80%, colors(tertiary) 0%);
      @include mq(tablet) {
        padding-right: 16px;
      }
    }
    &__txt {
      font-size: rem(14);
      &:not(:first-of-type) {
        margin-top: 8px;
      }
      @include mq(laptop) {
        font-size: rem(16);
        &:not(:first-of-type) {
          margin-top: 16px;
        }
      }
    }
    &__photo {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      @include mq(laptop) {
        margin-top: 24px;
      }
    }
    &__img {
      width: 864px;
    }
    &-summary {
      margin-top: 32px;
      &__ttl {
        padding-left: 8px;
        margin-bottom: 8px;
        border-left: 4px solid colors(tertiary);
        font-size: rem(18);
        @include mq(laptop) {
          font-size: rem(22);
        }
      }
      &__txt {
        font-size: rem(14);
        @include mq(laptop) {
          font-size: rem(16);
        }    
      }
    }  
  }
  &-process {
    margin-top: 16px;
    @include mq(laptop) {
      margin-top: 24px;
    }
    &__desc {
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__txt {
      margin-top: 4px;
      font-size: rem(12);
      @include mq(laptop) {
        font-size: rem(14);
      }
    }
    &__cont {
      margin-top: 8px;
    }
    &-list {
      @include mq(tablet) {
        display: flex;
      }
      &__item {
        &:not(:first-child) {
          margin-top: 4px;
        }
        @include mq(tablet) {
          width: calc((100% - 16px) / 2);
          &:not(:first-child) {
            margin-top: 0;
            margin-left: 16px;
          }
        }
      }
    }
  }
  &-comparison {
    margin-top: 16px;
    @include mq(laptop) {
      margin-top: 24px;
    }
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(18);
      @include mq(laptop) {
        font-size: rem(22);
      }
    }
    &__txt {
      margin-top: 8px;
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &-list {
      display: flex;
      &__item {
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
      &__photo {
      }
      &__img {
      }
      &__caption {
        margin-top: 4px;
        font-size: rem(12);
        text-align: center;
        @include mq(laptop) {
          font-size: rem(14);
        }
      }
    }
  }
}
