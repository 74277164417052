.p-fabric {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 128px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__title {
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &__lead {
    margin-top: 8px;
    font-size: rem(14);
    @include mq(laptop) {
      font-size: rem(16);
    }
  }
  &-section {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__header {
      margin-bottom: 16px;
    }
    &__title {
      font-size: rem(20);
      @include mq(laptop) {
        font-size: rem(24);
      }
    }
    &__maker {
      padding-right: 8px;
      background: linear-gradient(transparent 80%, colors(tertiary) 0%);
      @include mq(tablet) {
        padding-right: 16px;
        padding-left: 0;
      }
    }
    &__link {
      display: flex;
      justify-content: flex-end;
      position: relative;
      padding-right: 8px;
      margin-top: 16px;
      font-size: rem(14);
      @include mq(laptop) {
        margin-top: 32px;
      }
      &:after {
        @include arrow_line(right,8,1,colors(link),middle);
        right: 0;
      }
    }
  }
  &-block {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__header {
      margin-bottom: 8px;
      @include mq(laptop) {
        display: flex;
        align-items: baseline;
        margin-bottom: 16px;
      }
    }
    &__title {
      padding-left: 8px;
      margin-bottom: 4px;
      border-left: 4px solid colors(tertiary);
      font-size: rem(18);
      @include mq(laptop) {
        font-size: rem(22);
      }
    }
  }
  &-list {
    padding-left: 24px;
    list-style: disc;
    &__item {
      margin-bottom: 8px;
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &-composition {
    display: flex;
    align-items: baseline;
    @include mq(laptop) {
      margin-left: 16px;
    }
    &__head {
      flex: 0 0 auto;
      margin-right: 8px;
      font-size: rem(14);
      font-weight: bold;
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__body {
      flex: 1 1 auto;
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
  }
  &-product {
    display: flex;
    flex-wrap: wrap;
    &__item {
      width: calc((100% - (16px * 2)) / 3);
      margin-right: 16px;
      margin-bottom: 16px;
      @include mq(tablet) {
        width: calc((100% - (16px * 5)) / 6);
      }
      &:nth-child(3n) {
        margin-right: 0;
        @include mq(tablet) {
          margin-right: 16px;
        }
      }
      &:nth-child(6n) {
        @include mq(tablet) {
          margin-right: 0;
        }
      }
      &:nth-last-child(-n+3) {
        margin-bottom: 0;
      }
      &:nth-last-child(-n+6) {
        @include mq(tablet) {
          margin-bottom: 0;
        }
      }
    }
    &__photo {
    }
    &__lightbox {
      color: colors(font);
      &:hover {
        text-decoration: none;
        opacity: .7;
      }
    }
    &__name {
      font-size: rem(12);
      text-align: center;
      @include mq(laptop) {
        margin-top: 4px;
        font-size: rem(16);
      }
    }
  }
}