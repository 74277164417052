.p-gallery {
  &-main {
    z-index: 1;
    &__container {
      z-index: 1;
      margin-bottom: 8px;
    }
    &__item {
      @include mq(laptop) {
        padding: 0 40px;
      }
      @include mq(desktop) {
        padding: 0 44px;
      }
      .-review & {
        padding: 0;
      }
    }
  }
  &-pagination {
    display: flex;
    overflow-x: scroll;
    padding: 8px;
    -webkit-overflow-scrolling: touch;
    @include mq(tablet) {
      flex-wrap: wrap;
      overflow-x: visible;
      padding: 0;
    }
    @include mq(laptop) {
      padding: 0 40px;
    }
    @include mq(desktop) {
      padding: 0 44px;
    }
    &__item {
      display: block;
      flex: 0 0 20%;
      width: 64px;
      height: 100%;
      margin-right: 8px;
      box-shadow: 0 0 0 2px transparent;
      border-radius: 0;
      opacity: 1;
      cursor: pointer;
      &:hover {
        opacity: .7;
      }
      &:last-child {
        margin-right: 0;
      }
      &.is-on {
        box-shadow: 0 0 0 2px colors(icon);
      }
      @include mq(tablet) {
        flex-basis: calc((100% - 16px * (6 - 1)) / 6 * 1 + 16px * (1 - 1));
        margin-right: 16px;
        margin-bottom: 8px;
        &:nth-child(6n) {
          margin-right: 0;
        }
      }
      @include mq(laptop) {
        flex-basis: 64px;
        &:nth-child(6n) {
          margin-right: 16px;
        }
        &:nth-child(7n) {
          margin-right: 0;
        }
      }
      @include mq(desktop) {
        flex-basis: 72px;
      }
    }
  }
}