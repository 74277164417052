// @desc - 'IconFont'に必要なスタイルを出力します。
// @param {Bool, String} - 引数$keyに使用するアイコンフォント名を渡すと、`content: "アイコンフォント名"`の指定ができます。
// @param {Bool, String} - 引数$positionに"middle"（天地中央）か"center"（上下左右中央）を渡すと、`position: absolute;`の指定ができます。
// @example scss - Usage
// .foo:before { @include icon(horsdoeuvre) }
// .bar:before { @include icon(horsdoeuvre,middle) }
// @example css - CSS output
// .foo:before {
//  content: '\e90c';
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  font-family: 'icomoon' !important;
//  speak: none;
//  font-style: normal;
//  font-weight: normal;
//  font-variant: normal;
//  text-transform: none;
//  line-height: 1;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
// }
// .bar:before {
//  content: '\e90c';
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  position: absolute; /* 第2引数の"middle"で出力されます */
//  top: 0; /* 第2引数の"middle"で出力されます */
//  bottom: 0; /* 第2引数の"middle"で出力されます */
//  margin: auto 0; /* 第2引数の"middle"で出力されます */
//  font-family: 'icomoon' !important;
//  speak: none;
//  font-style: normal;
//  font-weight: normal;
//  font-variant: normal;
//  text-transform: none;
//  line-height: 1;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
// }
@mixin icon($key: false, $position: false) {
  @if $key {
    content: icons($key);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  @if $position == middle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  } @else if $position == center {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  font-family: 'tanuki-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}