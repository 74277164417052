.p-about {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    font-size: rem(24);
    text-align: center;
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &-contents {
  }
  &__txt {
    margin-bottom: 64px;
    font-size: rem(14);
    text-align: center;
    line-height: 3;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include mq(laptop) {
      font-size: rem(16);
    }
  }
  &-photo {
    margin-bottom: 32px;
    @include mq(tablet) {
      width: 50%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}