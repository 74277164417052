.p-utility {
  &-nav {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 48px;
    margin: auto 0;
    @include mq(desktop) {
      position: static;
    }
    &__item {
      position: relative;
      &:nth-child(2) {
        display: none;
      }
      @include mq(desktop) {
        margin-right: 8px;
        &:nth-child(2) {
          display: block;
        }
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 40px;
      height: 48px;
      &::before {
        font-size: rem(16);
      }
      &:hover {
        opacity: .7;
      }
    }
  }
  &-search {
    display: none;
    position: fixed;
    top: 64px;
    left: 0;
    z-index: 20;
    width: 100%;
    background-color: colors(base);
    border-top: 1px solid colors(border);
    border-bottom: 1px solid colors(border);
    &.is-show {
      display: block;
    }
    @include mq(desktop) {
      top: 88px;
      .is-thin & {
        top: 64px;
      }
    }
    &__select {
      position: relative;
      width: 100%;
      height: 40px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 24px;
        bottom: 4px;
        width: 8px;
        height: 8px;
        margin: auto 0;
        border-right: 2px solid colors(icon);
        border-bottom: 2px solid colors(icon);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        pointer-events: none;
      }
      & > select {
        vertical-align: middle;
        width: 100%;
        height: 40px;
        padding: 0 40px 0 16px;
        line-height: 1;
        border: 1px solid colors(border);
        border-radius: 24px;
        font-size: rem(16);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        @include mq(tablet) {
          border-right-color: transparent;
          border-radius: 24px 0 0 24px;
        }
        &::-ms-expand {
          display: none;
        }
        &:focus {
          outline: none;
          border-color: colors(secondary);
        }
      }
    }
    &__input {
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: 0 8px 0 16px;
      border-top: 1px solid colors(border);
      border-bottom: 1px solid colors(border);
      border-left: 1px solid colors(border);
      border-radius: 24px 0 0 24px;
      font-size: rem(16);
      -webkit-appearance: none;
      @include mq(tablet) {
        border-radius: 0;
      }
      &:focus {
        border-color: colors(secondary);
        outline: none;
      }
    }
    &__btn {
      width: 72px;
      height: 100%;
      background-color: colors(primary);
      border-radius: 0 24px 24px 0;
      font-size: rem(16);
      text-align: center;
      color: colors(base);
      &:hover {
        background-color: colors(primary-light);
      }
      @include mq(desktop) {
        width: 88px;
      }
    }
    &-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 104px;
      padding: 8px;
      @include mq(tablet) {
        flex-wrap: nowrap;
        max-width: device(desktop-inner);
        height: 56px;
        margin: 0 auto;
      }
      &__item {
        height: 40px;
        &:first-child {
          width: 100%;
          margin-bottom: 8px;
          @include mq(tablet) {
            flex: 0 0 184px;
            margin-bottom: 0;
          }
        }
        &:nth-child(2) {
          flex: 1;
        }
        &:last-child {
          flex: 0;
        }
      }
    }
  }
}