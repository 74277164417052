.p-update {
  &-box {
    padding: 16px;
    &__ttl {
      margin-bottom: 16px;
      font-size: rem(16);
      font-weight: bold;
    }
  }
  &-list {
    &__item {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
    &__date {
      flex: 0 0 72px;
      margin-right: 8px;
      font-size: rem(12);
    }
    &__txt {
      font-size: rem(12);
    }
  }
}