.p-history {
  position: relative;
  padding: 0 8px;
  margin-bottom: 64px;
  @include mq(laptop) {
    padding: 0;
  }
  &-list {
    display: flex;
    &__item {
      @include mq(laptop) {
        width: calc((100% - 16px * 7) / 8);
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &-block {
    position: relative;
    &:hover {
      opacity: .8;
    }
    &__ttl {
      overflow: hidden;
      margin-bottom: 4px;
      font-size: rem(12);
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__link {
      color: colors(font);
      text-decoration: none;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__feature {
      display: flex;
      align-items: center;
      overflow: hidden;
      aspect-ratio: 3 / 2;
      margin-bottom: 8px;
    }
    &__img {
      width: 100%;
    }
    &__info {
      font-size: rem(14);
    }
    &__price {
      display: inline-block;
      margin-right: 4px;
    }
    &__tax {
      font-size: rem(10);
    }
  }
}