.p-privacy {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    margin-bottom: 8px;
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &__lead {
    font-size: rem(14);
    @include mq(laptop) {
      font-size: rem(16);
    }
  }
  &-sect {
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__ttl {
      margin-bottom: 16px;
      font-size: rem(20);
      @include mq(laptop) {
        font-size: rem(24);
      }
    }
    &__txt {
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
  }
}