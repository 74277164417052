.p-stocklist {
  margin-bottom: 24px;
  background-color: colors(bg);
  &-box {
    position: relative;
    overflow: hidden;
    height: 88px;
    padding: 16px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 16px;
      background-image: linear-gradient(180deg, rgba(colors(bg),.5), rgba(colors(bg),1));
    }
    &.is-close {
      height: auto;
      &::before {
        display: none;
      }
    }
    @include mq(tablet) {
      overflow: visible;
      height: auto;
    }
    &__ttl {
      margin-bottom: 16px;
      font-size: rem(16);
      font-weight: bold;
      color: colors(alert);
    }
  }
  &-desc {
    padding-left: 16px;
    font-size: rem(12);
    list-style-type: disc;
    &__item {
      margin-bottom: 8px;
      font-size: rem(12);
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__note {
      display: block;
      font-size: rem(10);
    }
  }
  &__readmore {
    position: relative;
    width: 100%;
    height: 24px;
    &::before {
      @include arrow_line(bottom,10,1,colors(icon));
      top: 4px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    &.is-close {
      position: relative;
      &::before {
        @include arrow_line(top,10,1,colors(icon));
        top: 8px;
      }
    }
    @include mq(tablet) {
      display: none;
    }
  }
}