.p-guide {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &-sect {
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__ttl {
      display: flex;
      align-items: center;
      position: relative;
      height: 24px;
      padding-left: 40px;
      margin-bottom: 8px;
      font-size: rem(20);
      &::before {
        left: 0;
        font-size: rem(24);
      }
      @include mq(laptop) {
        margin-bottom: 16px;
        font-size: rem(24);
      }
    }
  }
  &-box {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(16);
      font-weight: bold;
    }
    &__txt {
      margin-bottom: 8px;
      font-size: rem(14);
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-credit {
    display: flex;
    align-items: center;
    margin-top: 8px;
    &__item {
      width: calc((100% - 16px * 4) / 5);
      max-width: 64px;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-list {
    padding-left: 24px;
    margin-bottom: 24px;
    list-style: disc;
    &__item {
      margin-bottom: 8px;
      font-size: rem(14);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &-tbl {
    width: 100%;
    border-bottom: 1px solid colors(border);
    &__head {
    }
    &__body {
    }
    &__tr {
      &:nth-child(odd) {
        background-color: colors(tertiary);
      }
    }
    &__th {
      padding: 8px;
      background-color: colors(primary);
      border-right: 1px solid colors(base);
      border-bottom: 1px solid colors(base);
      font-size: rem(13);
      color: colors(base);
      vertical-align: middle;
      text-align: center;
      &.-pref {
        background-color: colors(primary-light);
      }
    }
    &__td {
      padding: 8px;
      border-right: 1px solid colors(border);
      font-size: rem(13);
      text-align: right;
      vertical-align: middle;
      &:last-child {
        border-right: transparent;
      }
    }
  }
}

.p-tbl-wrapper {
  overflow-x: auto;
  padding: 0 0 8px;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include mq(laptop) {
    overflow-x: visible;
    padding: 0;
    white-space: normal;
  }
}