.p-service {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    margin-bottom: 8px;
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &__lead {
    font-size: rem(14);
    @include mq(laptop) {
      font-size: rem(16);
    }
  }
  &-sect {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__header {
      margin-bottom: 16px;
    }
    &__ttl {
      font-size: rem(20);
      text-align: center;
      @include mq(tablet) {
        text-align: left;
      }
      @include mq(laptop) {
        font-size: rem(24);
      }
    }
    &__maker {
      padding-right: 8px;
      padding-left: 8px;
      background: linear-gradient(transparent 80%, colors(tertiary) 0%);
      @include mq(tablet) {
        padding-right: 16px;
        padding-left: 0;
      }
    }
    &__lead {
      margin-top: 8px;
      font-size: rem(14);
      text-align: center;
      @include mq(tablet) {
        text-align: left;
      }
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__txt {
      font-size: rem(14);
      @include mq(tablet) {
        flex-grow: 1;
      }
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__inner {
      @include mq(tablet) {
        display: flex;
      }
    }
    &__banner {
      @include grid(8, 6, 16px);
      margin-right: auto;
      margin-bottom: 16px;
      margin-left: auto;
      @include mq(tablet) {
        @include grid(8, 3, 16px);
        max-width: none;
        margin-right: 16px;
        margin-bottom: 0;
      }
      @include mq(laptop) {
        @include grid(12, 4, 16px);
      }
    }
    &__cont {
      @include mq(tablet) {
        display: flex;
        flex-flow: column;
        @include grid(8, 5, 16px);
      }
      @include mq(laptop) {
        @include grid(12, 8, 16px);
      }
    }
    &__footer {
      margin-top: 8px;
      text-align: right;
    }
    &__link {
      position: relative;
      width: 100%;
      padding-right: 16px;
      margin-top: 8px;
      font-size: rem(14);
      text-align: right;
      &:after {
        @include arrow_line(right,8,1,colors(link),middle);
        right: 4px;
      }
    }
    &-list {
      @include mq(tablet) {
        display: flex;
        flex-wrap: wrap;
      }
      &__item {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        @include mq(tablet) {
          width: calc((100% - 16px) / 2);
          margin-right: 16px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(n + 3) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &-purchase {
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(18);
      @include mq(laptop) {
        font-size: rem(20);
      }
    }
    &__txt {
      font-size: rem(14);
      @include mq(laptop) {
        @include grid(6, 3, 16px);
      }
    }
    &__inner {
      @include clearfix;
      @include mq(laptop) {
        display: flex;
      }
    }
    &__banner {
      float: left;
      width: calc((100% - 16px) / 2);
      margin-right: 16px;
      @include mq(laptop) {
        float: none;
        @include grid(6, 3, 16px);
      }
    }
  }
  &-note {
    padding-left: 24px;
    margin-top: 16px;
    &__item {
      position: relative;
      margin-bottom: 4px;
      font-size: rem(14);
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: '※';
        position: absolute;
        left: -20px;
      }
    }
  }
  &-important {
    margin-top: 16px;
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(18);
      @include mq(laptop) {
        font-size: rem(20);
      }
    }
    &-list {
      padding-left: 24px;
      list-style: disc;
      &__item {
        margin-bottom: 4px;
        font-size: rem(14);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}