.product_detail {
  display: none;
}

.data-info {
  th,
  td {
    display: block;
    text-align: left;
  }
  tr {
    display: block;
    margin-bottom: 16px;
  }
  th {
    margin-bottom: 8px;
    font-size: rem(16);
    font-weight: bold;
  }
  td {
    font-size: rem(16);
  }
}