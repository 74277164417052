.l-footer {
  &-nav {
    background-color: colors(primary);
    &__inner {
      padding: 24px 32px 40px;
      @include mq(tablet) {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        padding-right: 8px;
        padding-left: 8px;
      }
      @include mq(laptop) {
        width: device(laptop-inner);
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
      }
      @include mq(desktop) {
        width: device(desktop-inner);
      }
    }
  }
  &-shop {
    background-color: colors(primary-dark);
    &__inner {
      padding: 16px 8px;
      @include mq(tablet) {
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        justify-content: space-between;
      }
      @include mq(laptop) {
        width: device(laptop-inner);
        padding: 16px 0;
        margin-right: auto;
        margin-left: auto;
      }
      @include mq(desktop) {
        width: device(desktop-inner);
      }
    }
  }
}

.p-footer {
  &-menu {
    @include mq(tablet) {
      display: flex;
      flex-wrap: wrap;
      @include grid(8,5,16px);
    }
    @include mq(laptop) {
      @include grid(12,9,16px);
    }
    &-list {
      &__item {
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__link {
        font-size: rem(14);
        color: colors(border);
      }
    }
    &-sect {
      margin-bottom: 24px;
      @include mq(tablet) {
        width: calc((100% - 16px) / 2);
        margin-right: 16px;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
      @include mq(laptop) {
        width: calc((100% - 32px) / 3);
        &:nth-of-type(2n) {
          margin-right: 16px;
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
      &__ttl {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid colors(border-dark);
        font-size: rem(18);
        color: colors(base);
      }
    }
  }
  &-info {
    @include mq(tablet) {
      @include grid(8,3,16px);
    }
    @include mq(laptop) {
      @include grid(12,3,16px);
    }
  }
  &-logo {
    @include grid(8,6,16px);
    margin-right: auto;
    margin-bottom: 16px;
    margin-left: auto;
    @include mq(laptop) {
      width: 100%;
      margin-bottom: 32px;
    }
    &__img {
      width: 100%;
    }
  }
  &-box {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__ttl {
      @include grid(8,2,16px);
      margin-right: 16px;
      font-size: rem(13);
      text-align: right;
      color: colors(border);
      white-space: nowrap;
    }
    &__cont {
      @include grid(8,6,16px);
      font-size: rem(13);
      color: colors(border);
    }
    &__txt {
      display: block;
    }
    &__link {
      color: colors(border);
    }
  }
  &-sns {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    @include mq(tablet) {
      margin-bottom: 0;
    }
    &__item {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      color: colors(base);
      &:hover {
        text-decoration: none;
        @include mq(laptop) {
          opacity: .7;
        }
      }
      &.-facebook {
        &::before {
          @include icon(facebook);
          font-size: rem(32);
        }
      }
      &.-instagram {
        &::before {
          @include icon(instagram);
          font-size: rem(32);
        }
      }
      &.-youtube {
        &::before {
          @include icon(youtube);
          font-size: rem(32);
        }
      }
      &.-line {
        &::before {
          @include icon(line);
          font-size: rem(32);
        }
      }
    }
  }
  &-copyright {
    &__txt {
      display: block;
      font-size: rem(12);
      text-align: center;
      color: colors(border);
    }
  }
}