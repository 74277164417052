.p-breadcrumb {
  overflow: hidden;
  height: 40px;
  padding-left: 8px;
  background-color: colors(bg);
  @include mq(laptop) {
    padding-left: 0;
  }
  &-list {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 100%;
    @include mq(laptop) {
      overflow-x: visible;
      width: device(laptop-inner);
      margin: 0 auto;
    }
    @include mq(desktop) {
      width: device(desktop-inner);
    }
    &__item {
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
    &__link {
      position: relative;
      padding-right: 16px;
      font-size: rem(12);
      text-decoration: none;
      color: colors(font);
      &::before {
        @include arrow_line(right,6,1,colors(icon),middle);
        right: 0;
      }
    }
    &__txt {
      font-size: rem(12);
    }
  }
}