.p-blog {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    display: flex;
    padding: 32px 0 80px;
  }
  &-primary {
    @include mq(laptop) {
      flex: 1 1 auto;
    }
    &__header {
      margin-bottom: 32px;
    }
    &__title {
      font-size: rem(24);
      @include mq(laptop) {
        font-size: rem(28);
      }
    }
    &__content {
      margin-bottom: 32px;
      > p {
        line-height: 2;
        &:not(:first-child) {
          margin-top: 2em;
        }
      }
    }
    &-meta {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 8px;
      background-color: colors(bg);
      &__item {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-left: 8px;
        }
        &.-time {
          &::before {
            @include icon(calendar);
            font-size: rem(16);
          }
        }
        &.-category {
          &::before {
            @include icon(category);
            font-size: rem(16);
          }
        }
      }
      &__time {
        font-size: rem(12);
      }
      &__category {
        font-size: rem(12);
        &:not(:first-of-type) {
          &::before {
            content: ', ';
          }
        }
      }
    }
    &-noresult {
      max-width: 600px;
      &__text {
        font-size: rem(14);
        @include mq(laptop) {
          font-size: rem(16);
        }
      }
      &__form {
        margin-top: 32px;
      }
    }
  }
  &-secondary {
    display: none;
    @include mq(laptop) {
      display: block;
      flex: 0 0 248px;
      margin-left: 16px;
    }
    &-block {
      &:not(:first-of-type) {
        margin-top: 32px;
      }
      &__title {
        margin-bottom: 8px;
        font-size: rem(18);
      }
    }
    &-list {
      &__item {
        &:not(:first-child) {
          padding-top: 8px;
          margin-top: 8px;
          border-top: 1px solid colors(border);
        }
      }
      &__link {
        font-size: rem(14);
      }
    }
  }
  &-list {
    margin-bottom: 40px;
    &__item {
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
    &__link {
      color: colors(font);
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-post {
    display: flex;
    flex-direction: column-reverse;
    @include mq(tablet) {
      flex-direction: row-reverse;
    }
    &__inner {
      margin-top: 8px;
      @include mq(tablet) {
        flex: 1 1 auto;
        margin-top: 0;
      }
    }
    &__title {
      margin-top: 4px;
      font-size: rem(20);
      line-height: 1.2;
    }
    &__excerpt {
      margin-top: 4px;
      font-size: rem(14);
    }
    &__time {
      display: flex;
      align-items: center;
      font-size: rem(12);
      color: colors(font-light);
      &::before {
        @include icon(calendar);
        font-size: rem(16);
      }
    }
    &-thumbnail {
      overflow: hidden;
      aspect-ratio: 8/5;
      @include mq(tablet) {
        flex: 0 0 248px;
        margin-right: 16px;
      }
      &__image {
        width: 100%;
      }
    }
  }
  &-search {
    &-form {
      display: flex;
      &__input {
        flex: 1 1 auto;
        height: 40px;
        padding: 4px;
        margin-right: 8px;
        border: 1px solid colors(border);
        border-radius: 4px;
        font-size: rem(14);
        &:focus {
          border-color: colors(secondary);
          outline: none;
        }
      }
      &__button {
        width: 56px;
        height: 40px;
        background-color: colors(primary);
        border-radius: 4px;
        font-size: rem(14);
        text-align: center;
        color: colors(base);
        &:hover {
          background-color: colors(primary-light);
        }
      }
    }
  }
}