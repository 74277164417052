@mixin arrow($arrow, $size, $color, $position: default) {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: $size + px solid transparent;
  @if $arrow == top {
    border-bottom: ($size * 1.5) + px solid $color;
  } @else if $arrow == bottom {
    border-top: ($size * 1.5) + px solid $color;
  } @else if $arrow == right {
    border-left: ($size * 1.5) + px solid $color;
  } @else if $arrow == left {
    border-right: ($size * 1.5) + px solid $color;
  }
  @if $position == middle {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}

@mixin arrow_line($arrow: top, $size: 10, $weight: 2, $color: colors(icon), $position: default) {
  content: '';
  display: block;
  position: absolute;
  width: $size + px;
  height: $size + px;
  border-right: $weight + px solid $color;
  border-bottom: $weight + px solid $color;
  @if $arrow == top {
    transform: rotate(-135deg);
  } @else if $arrow == bottom {
    transform: rotate(45deg);
  } @else if $arrow == right {
    transform: rotate(-45deg);
  } @else if $arrow == left {
    transform: rotate(135deg);
  }
  @if $position == middle {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}