.p-product-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  margin-bottom: 64px;
  @include mq(laptop) {
    padding: 0;
  }
  &__item {
    width: calc((100% - 16px) / 2);
    margin-right: 16px;
    margin-bottom: 16px;
    &:nth-child(even) {
      margin-right: 0;
    }
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
    @include mq(tablet) {
      width: calc((100% - 16px * 3) / 4);
      &:nth-child(even) {
        margin-right: 16px;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-last-child(-n+4) {
        margin-bottom: 0;
      }
    }
  }
}
.p-product-block {
  position: relative;
  &:hover {
    opacity: .8;
  }
  &__ttl {
    margin-bottom: 4px;
    font-size: rem(12);
    @include mq(laptop) {
      font-size: rem(14);
    }
  }
  &__link {
    color: colors(font);
    text-decoration: none;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__feature {
    display: flex;
    align-items: center;
    overflow: hidden;
    aspect-ratio: 3 / 2;
    margin-bottom: 8px;
  }
  &__img {
  }
  &__info {
    font-size: rem(16);
    @include mq(laptop) {
      font-size: rem(18);
    }
  }
  &__price {
    display: inline-block;
    margin-right: 4px;
  }
  &__tax {
    font-size: rem(12);
    @include mq(laptop) {
      font-size: rem(14);
    }
  }
  &__soldout {
  }
}