.p-faq {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 80px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__title {
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &-list {
    border-bottom: 1px solid colors(border);
    &:first-of-type {
      border-top: 1px solid colors(border);
    }
    &.is-open > &__question {
      &:after {
        @include icon(circle-remove);
      }
    }
    &.is-open > &__answer {
      display: flex;
    }
    &__question {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      position: relative;
      padding-top: 24px;
      padding-right: 40px;
      padding-bottom: 24px;
      cursor: pointer;
      &:hover {
        opacity: .7;
      }
      &::before {
        content: 'Q';
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 28px;
        height: 28px;
        margin-right: 8px;
        background-color: colors(primary-light);
        border-radius: 50%;
        font-size: rem(18);
        color: colors(base);
      }
      &:after {
        @include icon(circle-add);
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 28px;
        height: 28px;
        font-size: rem(28);
      }
    }
    &__title {
      flex: 1 1 auto;
      font-size: rem(16);
    }
    &__answer {
      display: none;
      align-items: baseline;
      padding-bottom: 24px;
      font-size: rem(16);
      &::before {
        content: 'A';
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 28px;
        height: 28px;
        margin-right: 8px;
        background-color: colors(alert);
        border-radius: 50%;
        font-size: rem(18);
        color: colors(base);
      }
    }
  }
}