.p-404 {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__title {
    font-size: rem(24);
    text-align: center;
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &__lead {
    margin-top: 16px;
    font-size: rem(18);
    text-align: center;
    @include mq(laptop) {
      font-size: rem(22);
    }
  }
  &__text {
    font-size: rem(14);
    line-height: 2;
    text-align: center;
    &:not(:first-of-type) {
      margin-top: 24px;
    }
    @include mq(laptop) {
      font-size: rem(16);
    }
  }
  &-contents {
    margin-bottom: 32px;
    @include mq(laptop) {
      margin-bottom: 64px;
    }
  }
  &-action {
    display: flex;
    justify-content: center;
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 480px;
      height: 48px;
      background-color: colors(secondary);
      font-weight: bold;
      color: colors(base);
      &:hover {
        text-decoration: none;
        opacity: .7;
      }
    }
  }
}