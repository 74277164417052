.l-wrapper {
  overflow: hidden;
  width: 100%;
  padding-top: 64px;
  @include mq(desktop) {
    padding-top: 88px;
  }
}

.l-main {
  &__inner {
    border-bottom: 1px solid colors(border);
    @include mq(laptop) {
      width: device(laptop-inner);
      margin: 0 auto;
    }
    @include mq(desktop) {
      width: device(desktop-inner);
    }
  }
}

.p-main {
  &-sect {
    overflow: hidden;
    &:nth-of-type(even) {
      background-color: colors(bg);
    }
    &__inner {
      overflow: hidden;
      height: 100%;
      padding-top: 32px;
      @include mq(tablet) {
        padding-top: 40px;
      }
      @include mq(laptop) {
        width: device(laptop-inner);
        margin: 0 auto;
      }
      @include mq(desktop) {
        width: device(desktop-inner);
      }
    }
    &__ttl {
      margin-bottom: 32px;
      font-size: rem(24);
      text-align: center;
      letter-spacing: .1em;
      @include mq(tablet) {
        margin-bottom: 40px;
        font-size: rem(28);
      }
    }
    &__ja {
      display: block;
      font-size: rem(12);
      @include mq(tablet) {
        font-size: rem(14);
      }
    }
  }
}