.p-swiper {
  &-controller {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0;
      width: 100%;
      &.-recommend {
        top: 26%;
        @include mq(tablet) {
          top: 30%;
        }
      }
      &.-history,
      &.-soldout {
        top: 20%;
        @include mq(laptop) {
          display: none;
        }
      }
      &.-gallery {
        top: 0;
        bottom: 0;
        height: 40px;
        margin: auto;
      }
  }
  &-pagination {
    display: flex;
    justify-content: center;
    &-bullet {
      display: block;
      width: 14px;
      height: 14px;
      margin-right: 7px;
      &:last-child {
        margin-right: 0;
      }
      border: 2px solid colors(icon);
      border-radius: 50%;
      &.is-swiper-active {
        background-color: colors(icon);
      }
    }
  }
  &-btn {
    position: relative;
    z-index: 1;
    width: 32px;
    height: 40px;
    background-color: rgba(colors(base), .6);
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      margin: auto 0;
      border-top: 1px solid colors(icon);
      border-right: 1px solid colors(icon);
    }
    &.is-disabled {
      background-color: transparent;
      &::before {
        display: none;
      }
    }
    &.-prev {
      &::before {
        left: 12px;
        transform: rotate(-135deg);
      }
    }
    &.-next {
      &::before {
        right: 12px;
        transform: rotate(45deg);
      }
    }
  }
}