.p-introduction {
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 32px;
  @include mq(tablet) {
    padding-right: 0;
    padding-left: 0;
  }
  &__ttl {
    margin-bottom: 8px;
    font-size: rem(18);
  }
  &__txt {
    font-size: rem(14);
  }
}