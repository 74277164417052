.p-buy {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    margin-bottom: 8px;
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &__lead {
    font-size: rem(14);
    @include mq(laptop) {
      font-size: rem(16);
    }
  }
  &-sect {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__header {
      margin-bottom: 16px;
    }
    &__ttl {
      font-size: rem(20);
      @include mq(laptop) {
        font-size: rem(24);
      }
    }
    &__maker {
      padding-right: 8px;
      background: linear-gradient(transparent 80%, colors(tertiary) 0%);
      @include mq(tablet) {
        padding-right: 16px;
        padding-left: 0;
      }
    }
  }
  &-step {
    margin-bottom: 32px;
    @include mq(tablet) {
      display: flex;
    }
    &__item {
      position: relative;
      padding: 16px 16px 24px;
      margin-bottom: 16px;
      border: 2px solid colors(primary-light);
      border-radius: 2px;
      &::before {
        @include icon(arrow-down);
        position: absolute;
        right: 0;
        bottom: -32px;
        left: 0;
        z-index: 1;
        width: 48px;
        height: 48px;
        margin-right: auto;
        margin-left: auto;
        background-color: colors(secondary);
        border-radius: 50%;
        font-size: rem(24);
        color: colors(base);
      }
      &:last-child {
        padding-bottom: 16px;
        margin-bottom: 0;
        &::before {
          display: none;
        }
      }
      @include mq(tablet) {
        width: calc((100% - 16px * 3) / 4);
        padding-bottom: 16px;
        margin-right: 16px;
        margin-bottom: 0;
        &::before {
          @include icon(arrow-right);
          top: 0;
          right: -32px;
          bottom: 0;
          left: auto;
          margin-top: auto;
          margin-right: 0;
          margin-bottom: auto;
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      @include mq(laptop) {
        padding: 32px;
      }
    }
    &-box {
      &__ttl {
        margin-bottom: 8px;
        font-size: rem(18);
        text-align: center;
        @include mq(laptop) {
          font-size: rem(22);
        }
      }
      &__cont {
        font-size: rem(14);
        text-align: center;
        @include mq(laptop) {
          font-size: rem(16);
        }
      }
    }
  }
  &-block {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 64px;
    }
    &__ttl {
      padding-left: 8px;
      margin-bottom: 8px;
      border-left: 4px solid colors(tertiary);
      font-size: rem(18);
      @include mq(laptop) {
        font-size: rem(22);
      }
    }
    &__lead {
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__inner {
      margin-top: 16px;
      @include mq(tablet) {
        display: flex;
        justify-content: space-between;
      }
    }
    &-list {
      padding-left: 24px;
      list-style-type: disc;
      @include mq(laptop) {
        margin-top: 16px;
      }
      &__item {
        margin-bottom: 8px;
        font-size: rem(14);
        &:last-child {
          margin-bottom: 0;
        }
        @include mq(laptop) {
          font-size: rem(16);
        }
      }
    }
  }
  &-example {
    margin-top: 8px;
    @include mq(laptop) {
      margin-top: 16px;
    }
    &__ttl {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      font-size: rem(14);
      &::before {
        content: '■';
        display: block;
      }
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__inner {
      @include mq(laptop) {
        display: flex;
        align-items: flex-start;
      }
    }
    &__base {
      margin-bottom: 16px;
      @include mq(laptop) {
        width: calc((100% - 16px) / 2);
        max-width: 512px;
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
    &-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &__item {
        width: calc((100% - 16px) / 2);
        margin-bottom: 16px;
        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
        @include mq(tablet) {
          width: calc((100% - 16px * 3) / 4);
        }
        @include mq(laptop) {
          width: calc((100% - 16px * 2) / 3);
          max-width: 160px;
        }
      }
    }
    &-photo {
      &__img {
        width: 100%;
      }
      &__txt {
        margin-top: 4px;
        font-size: rem(12);
      }
    }
  }
  &-reduction {
    position: relative;
    left: 50%;
    right: 50%;
    width: 100vw;
    padding: 8px;
    margin-right: -50vw;
    margin-left: -50vw;
    background-color: colors(bg);
    @include mq(laptop) {
      position: static;
      width: calc((100% - 16px) / 2);
      max-width: 400px;
      padding: 16px 32px;
      margin-right: 0;
      margin-left: 0;
    }
    &__ttl {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      font-size: rem(14);
      &::before {
        content: '※';
        display: block;
      }
    }
    &-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &__item {
        width: calc((100% - 16px) / 2);
        margin-bottom: 16px;
        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
        @include mq(tablet) {
          width: calc((100% - 16px * 3) / 4);
        }
        @include mq(laptop) {
          width: calc((100% - 16px) / 2);
          max-width: 160px;
        }
      }
    }
    &-photo {
      &__img {
        width: 100%;
      }
      &__txt {
        margin-top: 4px;
        font-size: rem(12);
        color: colors(alert);
      }
    }
  }
  &-case {
    padding: 16px 16px 32px;
    border: 1px solid colors(border);
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include mq(tablet) {
      width: calc((100% - 16px) / 2);
      margin-bottom: 0;
    }
    @include mq(laptop) {
      padding: 32px;
    }
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(16);
      text-align: center;
      @include mq(laptop) {
        margin-bottom: 16px;
        font-size: rem(20);
      }
    }
    &__lead {
      font-size: rem(14);
    }
    &-box {
      margin-top: 16px;
      &__ttl {
        display: flex;
        align-items: baseline;
        margin-bottom: 4px;
        font-size: rem(16);
        &::before {
          content: '■';
          display: block;
          margin-right: 4px;
        }
        @include mq(laptop) {
          margin-bottom: 8px;
        }
      }
      &__cont {
        font-size: rem(14);
        @include mq(laptop) {
          margin-bottom: 4px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    &-list {
      padding-left: 24px;
      list-style: disc;
      &__item {
        margin-bottom: 4px;
        font-size: rem(14);
        &:last-child {
          margin-bottom: 0;
        }
        @include mq(laptop) {
          margin-bottom: 8px;
        }
      }
    }
    &-note {
      margin-top: 16px;
      &__item {
        display: flex;
        align-items: baseline;
        margin-bottom: 4px;
        font-size: rem(14);
        color: colors(alert);
        &::before {
          content: '※';
          display: block;
          margin-right: 4px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        @include mq(laptop) {
          margin-bottom: 8px;
        }
      }
    }
    &-flow {
      padding-left: 24px;
      list-style: decimal;
      &__item {
        margin-bottom: 8px;
        font-size: rem(14);
        &:last-child {
          margin-bottom: 0;
        }
        @include mq(laptop) {
          margin-bottom: 16px;
          font-size: rem(16);
        }
      }
    }
  }
}