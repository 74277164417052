@charset "UTF-8";
@import "destyle.css/destyle.css";
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@font-face {
  font-family: "tanuki-icon";
  src: url("https://www.hokuokagu-tanuki.com/apps/note/wp-content/themes/hokuokagu-tanuki/assets/fonts/tanuki-icon.ttf?akjswh") format("truetype"), url("https://www.hokuokagu-tanuki.com/apps/note/wp-content/themes/hokuokagu-tanuki/assets/fonts/tanuki-icon.woff?akjswh") format("woff"), url("https://www.hokuokagu-tanuki.com/apps/note/wp-content/themes/hokuokagu-tanuki/assets/fonts/tanuki-icon.svg?akjswh#tanuki-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/* #base
   -------------------------------------------------------------------------- */
/**
 * 1. `width`と`height`に`margin`と`border`を含めます。
 * 2. ドキュメントのルートフォントサイズを指定します（`rem`で参照）。
 */
*,
*::before,
*::after {
  box-sizing: border-box; /* 1 */
}

html {
  font-size: 100%; /* 2 */
}

/**
 * 1. できる限り禁則処理が実行されるようにします。
 * 2. フォントの持っているカーニング情報を使用します。
 * 3. Chrome、Safari、FireFoxのフォントレンダリングを合わせます。
 */
body {
  word-wrap: break-word; /* 1 */
  overflow-wrap: break-word; /* 1 */
  background-color: #ffffff;
  color: #2F2F2F;
  font-family: "Roboto", "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
  font-weight: 500;
  font-kerning: normal; /* 2 */
  line-height: 1.5;
  letter-spacing: 0.03em;
  -webkit-font-smoothing: subpixel-antialiased; /* 3 */
  -moz-osx-font-smoothing: auto; /* 3 */
}

/**
 * 'font-weight: bold;'を'font-weight: 500;'
 */
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b,
th {
  font-weight: 500;
}

/**
 * メインコンテンツ内は横スクロールをさせません。
 */
main {
  overflow-x: hidden;
}

/**
 * マージンをリセットします。
 */
figure {
  margin: 0;
}

/**
 * デフォルトのスタイルをリセットします。
 */
fieldset {
  min-width: auto;
  margin: 0;
  padding: 0;
  border: 0;
}

/**
 * normalize.cssのスタイルをリセットして、divのように扱います。
 */
legend {
  display: block;
}

/**
 * ブロック要素の上下のマージンをリセットします。
 */
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
pre {
  margin-top: 0;
  margin-bottom: 0;
}

blockquote {
  margin-right: 0;
  margin-left: 0;
}

/**
 * 'a'のデフォルトスタイルを指定します。
 * 1. iOSのSafariとChromeで、`text-decoration: underline;`の罫線が
 *    グリフやディセンダで途切れないようにします。
 *    https://css-tricks.com/almanac/properties/t/text-decoration-skip/
 */
a {
  text-decoration: none;
  color: #3579BF;
  -webkit-text-decoration-skip: none; /* 1 */
}
a:hover {
  text-decoration: underline;
}

/**
 * `ul,ol`と`li`の左端を揃えにし、'list-style'をリセットます。
 */
ul,
ol {
  padding-left: 0;
  list-style: none;
}

/**
 * `dt`と`dd`の左端を揃えます。
 */
dd {
  margin-left: 0;
}

code,
kbd,
samp {
  font-size: 1rem;
}

/**
 * 日本語では斜体を使用しないためリセットします。
 */
[lang=ja] i,
[lang=ja] cite,
[lang=ja] em,
[lang=ja] address,
[lang=ja] dfn {
  font-style: normal;
}

/**
 * //フルードイメージにします。
 * 'img'の下余白をリセットします。
 */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/**
 * キーボードでアクセスできない要素のフォーカス表示を消します。
 * Credit: https://github.com/suitcss/base
 */
[tabindex="-1"]:focus {
  outline: none !important;
}

/**
 * IE10以降の`input[type="text"]`で入力内容の消去機能を非表示にします。
 */
[type=text]::-ms-clear {
  visibility: hidden;
}

/**
 * IE10以降の`input[type="password"]`でマスクの一時的解除機能を非表示にします。
 */
[type=password]::-ms-reveal {
  visibility: hidden;
}

/**
 * IEとEdgeはスピンボタンに未対応のため、ChromeとFireFoxで非表示にします。
 */
[type=number]::-webkit-outer-spin-button,
[type=number]::-webkit-inner-spin-button {
  appearance: none;
}

[data-whatinput=mouse] *:focus,
[data-whatinput=touch] *:focus {
  outline: none;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.l-footer-nav {
  background-color: #4A545F;
}
.l-footer-nav__inner {
  padding: 24px 32px 40px;
}
@media (min-width: 768px) {
  .l-footer-nav__inner {
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 8px;
  }
}
@media (min-width: 1024px) {
  .l-footer-nav__inner {
    width: 944px;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1440px) {
  .l-footer-nav__inner {
    width: 1040px;
  }
}
.l-footer-shop {
  background-color: #343B42;
}
.l-footer-shop__inner {
  padding: 16px 8px;
}
@media (min-width: 768px) {
  .l-footer-shop__inner {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .l-footer-shop__inner {
    width: 944px;
    padding: 16px 0;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1440px) {
  .l-footer-shop__inner {
    width: 1040px;
  }
}

@media (min-width: 768px) {
  .p-footer-menu {
    display: flex;
    flex-wrap: wrap;
    width: calc((100% - 16px * (8 - 1)) / 8 * 5 + 16px * (5 - 1));
  }
}
@media (min-width: 1024px) {
  .p-footer-menu {
    width: calc((100% - 16px * (12 - 1)) / 12 * 9 + 16px * (9 - 1));
  }
}
.p-footer-menu-list__item {
  margin-bottom: 4px;
}
.p-footer-menu-list__item:last-child {
  margin-bottom: 0;
}
.p-footer-menu-list__link {
  font-size: 0.875rem;
  color: #E5E5E5;
}
.p-footer-menu-sect {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .p-footer-menu-sect {
    width: calc((100% - 16px) / 2);
    margin-right: 16px;
  }
  .p-footer-menu-sect:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .p-footer-menu-sect {
    width: calc((100% - 32px) / 3);
  }
  .p-footer-menu-sect:nth-of-type(2n) {
    margin-right: 16px;
  }
  .p-footer-menu-sect:nth-of-type(3n) {
    margin-right: 0;
  }
}
.p-footer-menu-sect__ttl {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #cbcbcb;
  font-size: 1.125rem;
  color: #ffffff;
}
@media (min-width: 768px) {
  .p-footer-info {
    width: calc((100% - 16px * (8 - 1)) / 8 * 3 + 16px * (3 - 1));
  }
}
@media (min-width: 1024px) {
  .p-footer-info {
    width: calc((100% - 16px * (12 - 1)) / 12 * 3 + 16px * (3 - 1));
  }
}
.p-footer-logo {
  width: calc((100% - 16px * (8 - 1)) / 8 * 6 + 16px * (6 - 1));
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
}
@media (min-width: 1024px) {
  .p-footer-logo {
    width: 100%;
    margin-bottom: 32px;
  }
}
.p-footer-logo__img {
  width: 100%;
}
.p-footer-box {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}
.p-footer-box:last-of-type {
  margin-bottom: 0;
}
.p-footer-box__ttl {
  width: calc((100% - 16px * (8 - 1)) / 8 * 2 + 16px * (2 - 1));
  margin-right: 16px;
  font-size: 0.8125rem;
  text-align: right;
  color: #E5E5E5;
  white-space: nowrap;
}
.p-footer-box__cont {
  width: calc((100% - 16px * (8 - 1)) / 8 * 6 + 16px * (6 - 1));
  font-size: 0.8125rem;
  color: #E5E5E5;
}
.p-footer-box__txt {
  display: block;
}
.p-footer-box__link {
  color: #E5E5E5;
}
.p-footer-sns {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .p-footer-sns {
    margin-bottom: 0;
  }
}
.p-footer-sns__item {
  margin-right: 16px;
}
.p-footer-sns__item:last-child {
  margin-right: 0;
}
.p-footer-sns__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #ffffff;
}
.p-footer-sns__link:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .p-footer-sns__link:hover {
    opacity: 0.7;
  }
}
.p-footer-sns__link.-facebook::before {
  content: "\e90b";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
}
.p-footer-sns__link.-instagram::before {
  content: "\e90a";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
}
.p-footer-sns__link.-youtube::before {
  content: "\e909";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
}
.p-footer-sns__link.-line::before {
  content: "\e90c";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
}
.p-footer-copyright__txt {
  display: block;
  font-size: 0.75rem;
  text-align: center;
  color: #E5E5E5;
}

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 64px;
  background-color: #ffffff;
}
@media (min-width: 1440px) {
  .l-header {
    height: 88px;
    transition: all 0.3s ease;
  }
  .l-header.is-thin {
    height: 64px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.075);
  }
}
.l-header__inner {
  padding: 8px 0;
}
@media (min-width: 1440px) {
  .l-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 32px;
    margin: 0 auto;
  }
}

.p-logo {
  margin: auto;
  width: 144px;
  font-size: 1rem;
}
@media (min-width: 1440px) {
  .p-logo {
    width: 160px;
    margin-right: 0;
    margin-left: 0;
  }
}
.p-logo__link {
  display: block;
}
.p-logo__img {
  width: 100%;
}

.p-hamburger {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 64px;
}
@media (min-width: 1440px) {
  .p-hamburger {
    display: none;
  }
}
.p-hamburger__line {
  display: block;
  position: relative;
  width: 16px;
  height: 2px;
  margin: auto;
  background: #666666;
}
.p-hamburger__line:before, .p-hamburger__line:after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  width: 16px;
  height: 2px;
  margin: 0 auto;
  background: #666666;
}
.p-hamburger__line::before {
  margin-top: -6px;
}
.p-hamburger__line:after {
  margin-top: 6px;
}

.p-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
}
.p-overlay:before, .p-overlay:after {
  content: "";
  display: block;
  position: absolute;
  top: 24px;
  right: 16px;
  width: 24px;
  height: 2px;
  background: #666666;
}
.p-overlay::before {
  transform: rotate(45deg);
}
.p-overlay:after {
  transform: rotate(135deg);
}
.p-overlay.is-show {
  display: block;
}

.l-wrapper {
  overflow: hidden;
  width: 100%;
  padding-top: 64px;
}
@media (min-width: 1440px) {
  .l-wrapper {
    padding-top: 88px;
  }
}

.l-main__inner {
  border-bottom: 1px solid #E5E5E5;
}
@media (min-width: 1024px) {
  .l-main__inner {
    width: 944px;
    margin: 0 auto;
  }
}
@media (min-width: 1440px) {
  .l-main__inner {
    width: 1040px;
  }
}

.p-main-sect {
  overflow: hidden;
}
.p-main-sect:nth-of-type(even) {
  background-color: #F7F7F7;
}
.p-main-sect__inner {
  overflow: hidden;
  height: 100%;
  padding-top: 32px;
}
@media (min-width: 768px) {
  .p-main-sect__inner {
    padding-top: 40px;
  }
}
@media (min-width: 1024px) {
  .p-main-sect__inner {
    width: 944px;
    margin: 0 auto;
  }
}
@media (min-width: 1440px) {
  .p-main-sect__inner {
    width: 1040px;
  }
}
.p-main-sect__ttl {
  margin-bottom: 32px;
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  .p-main-sect__ttl {
    margin-bottom: 40px;
    font-size: 1.75rem;
  }
}
.p-main-sect__ja {
  display: block;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .p-main-sect__ja {
    font-size: 0.875rem;
  }
}

.c-icon {
  position: relative;
}
.c-icon::before {
  display: inline-block;
  position: absolute;
  background-image: none !important;
  font-family: "tanuki-icon" !important;
  font-style: normal !important;
  font-weight: 500;
  font-variant: normal !important;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: none;
  color: #666666;
}
.c-icon.-tanuki_logo::before {
  content: "\e900";
}
.c-icon.-user::before {
  content: "\e901";
}
.c-icon.-search::before {
  content: "\e902";
}
.c-icon.-cart::before {
  content: "\e903";
}
.c-icon.-cart-line::before {
  content: "\e904";
}
.c-icon.-phone::before {
  content: "\e905";
}
.c-icon.-mail::before {
  content: "\e906";
}
.c-icon.-payment::before {
  content: "\e907";
}
.c-icon.-truck::before {
  content: "\e908";
}
.c-icon.-youtube::before {
  content: "\e909";
}
.c-icon.-instagram::before {
  content: "\e90a";
}
.c-icon.-facebook::before {
  content: "\e90b";
}
.c-icon.-line::before {
  content: "\e90c";
}
.c-icon.-twitter::before {
  content: "\e90d";
}
.c-icon.-form::before {
  content: "\e90e";
}
.c-icon.-circle-add::before {
  content: "\e90f";
}
.c-icon.-circle-remove::before {
  content: "\e910";
}
.c-icon.-arrow-up::before {
  content: "\e911";
}
.c-icon.-arrow-right::before {
  content: "\e912";
}
.c-icon.-arrow-down::before {
  content: "\e913";
}
.c-icon.-arrow-left::before {
  content: "\e914";
}
.c-icon.-chair::before {
  content: "\e915";
}
.c-icon.-dining-chair::before {
  content: "\e916";
}
.c-icon.-arm-chair::before {
  content: "\e917";
}
.c-icon.-stool::before {
  content: "\e918";
}
.c-icon.-bench::before {
  content: "\e919";
}
.c-icon.-sofa::before {
  content: "\e91a";
}
.c-icon.-single-sofa::before {
  content: "\e91b";
}
.c-icon.-double-sofa::before {
  content: "\e91c";
}
.c-icon.-triple-sofa::before {
  content: "\e91d";
}
.c-icon.-ottoman::before {
  content: "\e91e";
}
.c-icon.-sofa-bed::before {
  content: "\e91f";
}
.c-icon.-table::before {
  content: "\e920";
}
.c-icon.-dining-table::before {
  content: "\e921";
}
.c-icon.-low-table::before {
  content: "\e922";
}
.c-icon.-side-table::before {
  content: "\e923";
}
.c-icon.-wagon-table::before {
  content: "\e924";
}
.c-icon.-nest-table::before {
  content: "\e925";
}
.c-icon.-sewing-table::before {
  content: "\e926";
}
.c-icon.-shelf::before {
  content: "\e927";
}
.c-icon.-book-shelf::before {
  content: "\e928";
}
.c-icon.-open-shelf::before {
  content: "\e929";
}
.c-icon.-wall-shelf::before {
  content: "\e92a";
}
.c-icon.-chest::before {
  content: "\e92b";
}
.c-icon.-low-chest::before {
  content: "\e92d";
}
.c-icon.-high-chest::before {
  content: "\e92c";
}
.c-icon.-side-chest::before {
  content: "\e92e";
}
.c-icon.-side-board::before {
  content: "\e92f";
}
.c-icon.-desk::before {
  content: "\e930";
}
.c-icon.-bureau::before {
  content: "\e931";
}
.c-icon.-pen::before {
  content: "\e932";
}
.c-icon.-stocklist::before {
  content: "\e933";
}
.c-icon.-basket::before {
  content: "\e934";
}
.c-icon.-container::before {
  content: "\e935";
}
.c-icon.-wholesaler::before {
  content: "\e936";
}
.c-icon.-sale::before {
  content: "\e937";
}
.c-icon.-maintenance::before {
  content: "\e938";
}
.c-icon.-dish::before {
  content: "\e939";
}
.c-icon.-light::before {
  content: "\e93a";
}
.c-icon.-miror::before {
  content: "\e93b";
}

.p-404 {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-404 {
    padding: 32px 0 64px;
  }
}
.p-404__header {
  margin-bottom: 32px;
}
.p-404__title {
  font-size: 1.5rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-404__title {
    font-size: 1.75rem;
  }
}
.p-404__lead {
  margin-top: 16px;
  font-size: 1.125rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-404__lead {
    font-size: 1.375rem;
  }
}
.p-404__text {
  font-size: 0.875rem;
  line-height: 2;
  text-align: center;
}
.p-404__text:not(:first-of-type) {
  margin-top: 24px;
}
@media (min-width: 1024px) {
  .p-404__text {
    font-size: 1rem;
  }
}
.p-404-contents {
  margin-bottom: 32px;
}
@media (min-width: 1024px) {
  .p-404-contents {
    margin-bottom: 64px;
  }
}
.p-404-action {
  display: flex;
  justify-content: center;
}
.p-404-action__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 480px;
  height: 48px;
  background-color: #EECF56;
  font-weight: bold;
  color: #ffffff;
}
.p-404-action__button:hover {
  text-decoration: none;
  opacity: 0.7;
}

.p-about {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-about {
    padding: 32px 0 64px;
  }
}
.p-about__header {
  margin-bottom: 32px;
}
.p-about__ttl {
  font-size: 1.5rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-about__ttl {
    font-size: 1.75rem;
  }
}
.p-about__txt {
  margin-bottom: 64px;
  font-size: 0.875rem;
  text-align: center;
  line-height: 3;
}
.p-about__txt:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .p-about__txt {
    font-size: 1rem;
  }
}
.p-about-photo {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .p-about-photo {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
}

.p-blog {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-blog {
    display: flex;
    padding: 32px 0 80px;
  }
}
@media (min-width: 1024px) {
  .p-blog-primary {
    flex: 1 1 auto;
  }
}
.p-blog-primary__header {
  margin-bottom: 32px;
}
.p-blog-primary__title {
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-blog-primary__title {
    font-size: 1.75rem;
  }
}
.p-blog-primary__content {
  margin-bottom: 32px;
}
.p-blog-primary__content > p {
  line-height: 2;
}
.p-blog-primary__content > p:not(:first-child) {
  margin-top: 2em;
}
.p-blog-primary-meta {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px;
  background-color: #F7F7F7;
}
.p-blog-primary-meta__item {
  display: flex;
  align-items: center;
}
.p-blog-primary-meta__item:not(:first-child) {
  margin-left: 8px;
}
.p-blog-primary-meta__item.-time::before {
  content: "\e93c";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}
.p-blog-primary-meta__item.-category::before {
  content: "\e93d";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}
.p-blog-primary-meta__time {
  font-size: 0.75rem;
}
.p-blog-primary-meta__category {
  font-size: 0.75rem;
}
.p-blog-primary-meta__category:not(:first-of-type)::before {
  content: ", ";
}
.p-blog-primary-noresult {
  max-width: 600px;
}
.p-blog-primary-noresult__text {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-blog-primary-noresult__text {
    font-size: 1rem;
  }
}
.p-blog-primary-noresult__form {
  margin-top: 32px;
}
.p-blog-secondary {
  display: none;
}
@media (min-width: 1024px) {
  .p-blog-secondary {
    display: block;
    flex: 0 0 248px;
    margin-left: 16px;
  }
}
.p-blog-secondary-block:not(:first-of-type) {
  margin-top: 32px;
}
.p-blog-secondary-block__title {
  margin-bottom: 8px;
  font-size: 1.125rem;
}
.p-blog-secondary-list__item:not(:first-child) {
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid #E5E5E5;
}
.p-blog-secondary-list__link {
  font-size: 0.875rem;
}
.p-blog-list {
  margin-bottom: 40px;
}
.p-blog-list__item:not(:first-child) {
  margin-top: 32px;
}
.p-blog-list__link {
  color: #2F2F2F;
}
.p-blog-list__link:hover {
  text-decoration: none;
}
.p-blog-post {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .p-blog-post {
    flex-direction: row-reverse;
  }
}
.p-blog-post__inner {
  margin-top: 8px;
}
@media (min-width: 768px) {
  .p-blog-post__inner {
    flex: 1 1 auto;
    margin-top: 0;
  }
}
.p-blog-post__title {
  margin-top: 4px;
  font-size: 1.25rem;
  line-height: 1.2;
}
.p-blog-post__excerpt {
  margin-top: 4px;
  font-size: 0.875rem;
}
.p-blog-post__time {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: #919191;
}
.p-blog-post__time::before {
  content: "\e93c";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}
.p-blog-post-thumbnail {
  overflow: hidden;
  aspect-ratio: 8/5;
}
@media (min-width: 768px) {
  .p-blog-post-thumbnail {
    flex: 0 0 248px;
    margin-right: 16px;
  }
}
.p-blog-post-thumbnail__image {
  width: 100%;
}
.p-blog-search-form {
  display: flex;
}
.p-blog-search-form__input {
  flex: 1 1 auto;
  height: 40px;
  padding: 4px;
  margin-right: 8px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  font-size: 0.875rem;
}
.p-blog-search-form__input:focus {
  border-color: #EECF56;
  outline: none;
}
.p-blog-search-form__button {
  width: 56px;
  height: 40px;
  background-color: #4A545F;
  border-radius: 4px;
  font-size: 0.875rem;
  text-align: center;
  color: #ffffff;
}
.p-blog-search-form__button:hover {
  background-color: #606D7C;
}

.p-breadcrumb {
  overflow: hidden;
  height: 40px;
  padding-left: 8px;
  background-color: #F7F7F7;
}
@media (min-width: 1024px) {
  .p-breadcrumb {
    padding-left: 0;
  }
}
.p-breadcrumb-list {
  overflow-x: scroll;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 100%;
}
@media (min-width: 1024px) {
  .p-breadcrumb-list {
    overflow-x: visible;
    width: 944px;
    margin: 0 auto;
  }
}
@media (min-width: 1440px) {
  .p-breadcrumb-list {
    width: 1040px;
  }
}
.p-breadcrumb-list__item {
  margin-left: 8px;
}
.p-breadcrumb-list__item:first-child {
  margin-left: 0;
}
.p-breadcrumb-list__link {
  position: relative;
  padding-right: 16px;
  font-size: 0.75rem;
  text-decoration: none;
  color: #2F2F2F;
}
.p-breadcrumb-list__link::before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
}
.p-breadcrumb-list__txt {
  font-size: 0.75rem;
}

.p-buy {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-buy {
    padding: 32px 0 64px;
  }
}
.p-buy__header {
  margin-bottom: 32px;
}
.p-buy__ttl {
  margin-bottom: 8px;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-buy__ttl {
    font-size: 1.75rem;
  }
}
.p-buy__lead {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-buy__lead {
    font-size: 1rem;
  }
}
.p-buy-sect {
  margin-bottom: 32px;
}
.p-buy-sect:last-of-type {
  margin-bottom: 0;
}
.p-buy-sect__header {
  margin-bottom: 16px;
}
.p-buy-sect__ttl {
  font-size: 1.25rem;
}
@media (min-width: 1024px) {
  .p-buy-sect__ttl {
    font-size: 1.5rem;
  }
}
.p-buy-sect__maker {
  padding-right: 8px;
  background: linear-gradient(transparent 80%, #F3EEDD 0%);
}
@media (min-width: 768px) {
  .p-buy-sect__maker {
    padding-right: 16px;
    padding-left: 0;
  }
}
.p-buy-step {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .p-buy-step {
    display: flex;
  }
}
.p-buy-step__item {
  position: relative;
  padding: 16px 16px 24px;
  margin-bottom: 16px;
  border: 2px solid #606D7C;
  border-radius: 2px;
}
.p-buy-step__item::before {
  content: "\e913";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0;
  bottom: -32px;
  left: 0;
  z-index: 1;
  width: 48px;
  height: 48px;
  margin-right: auto;
  margin-left: auto;
  background-color: #EECF56;
  border-radius: 50%;
  font-size: 1.5rem;
  color: #ffffff;
}
.p-buy-step__item:last-child {
  padding-bottom: 16px;
  margin-bottom: 0;
}
.p-buy-step__item:last-child::before {
  display: none;
}
@media (min-width: 768px) {
  .p-buy-step__item {
    width: calc((100% - 48px) / 4);
    padding-bottom: 16px;
    margin-right: 16px;
    margin-bottom: 0;
  }
  .p-buy-step__item::before {
    content: "\e912";
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "tanuki-icon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 0;
    right: -32px;
    bottom: 0;
    left: auto;
    margin-top: auto;
    margin-right: 0;
    margin-bottom: auto;
    margin-left: 0;
  }
  .p-buy-step__item:last-child {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .p-buy-step__item {
    padding: 32px;
  }
}
.p-buy-step-box__ttl {
  margin-bottom: 8px;
  font-size: 1.125rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-buy-step-box__ttl {
    font-size: 1.375rem;
  }
}
.p-buy-step-box__cont {
  font-size: 0.875rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-buy-step-box__cont {
    font-size: 1rem;
  }
}
.p-buy-block {
  margin-bottom: 32px;
}
.p-buy-block:last-of-type {
  margin-bottom: 64px;
}
.p-buy-block__ttl {
  padding-left: 8px;
  margin-bottom: 8px;
  border-left: 4px solid #F3EEDD;
  font-size: 1.125rem;
}
@media (min-width: 1024px) {
  .p-buy-block__ttl {
    font-size: 1.375rem;
  }
}
.p-buy-block__lead {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-buy-block__lead {
    font-size: 1rem;
  }
}
.p-buy-block__inner {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .p-buy-block__inner {
    display: flex;
    justify-content: space-between;
  }
}
.p-buy-block-list {
  padding-left: 24px;
  list-style-type: disc;
}
@media (min-width: 1024px) {
  .p-buy-block-list {
    margin-top: 16px;
  }
}
.p-buy-block-list__item {
  margin-bottom: 8px;
  font-size: 0.875rem;
}
.p-buy-block-list__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .p-buy-block-list__item {
    font-size: 1rem;
  }
}
.p-buy-example {
  margin-top: 8px;
}
@media (min-width: 1024px) {
  .p-buy-example {
    margin-top: 16px;
  }
}
.p-buy-example__ttl {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.875rem;
}
.p-buy-example__ttl::before {
  content: "■";
  display: block;
}
@media (min-width: 1024px) {
  .p-buy-example__ttl {
    font-size: 1rem;
  }
}
@media (min-width: 1024px) {
  .p-buy-example__inner {
    display: flex;
    align-items: flex-start;
  }
}
.p-buy-example__base {
  margin-bottom: 16px;
}
@media (min-width: 1024px) {
  .p-buy-example__base {
    width: calc((100% - 16px) / 2);
    max-width: 512px;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.p-buy-example-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.p-buy-example-list__item {
  width: calc((100% - 16px) / 2);
  margin-bottom: 16px;
}
.p-buy-example-list__item:nth-last-child(-n+2) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-buy-example-list__item {
    width: calc((100% - 48px) / 4);
  }
}
@media (min-width: 1024px) {
  .p-buy-example-list__item {
    width: calc((100% - 32px) / 3);
    max-width: 160px;
  }
}
.p-buy-example-photo__img {
  width: 100%;
}
.p-buy-example-photo__txt {
  margin-top: 4px;
  font-size: 0.75rem;
}
.p-buy-reduction {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  padding: 8px;
  margin-right: -50vw;
  margin-left: -50vw;
  background-color: #F7F7F7;
}
@media (min-width: 1024px) {
  .p-buy-reduction {
    position: static;
    width: calc((100% - 16px) / 2);
    max-width: 400px;
    padding: 16px 32px;
    margin-right: 0;
    margin-left: 0;
  }
}
.p-buy-reduction__ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 0.875rem;
}
.p-buy-reduction__ttl::before {
  content: "※";
  display: block;
}
.p-buy-reduction-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.p-buy-reduction-list__item {
  width: calc((100% - 16px) / 2);
  margin-bottom: 16px;
}
.p-buy-reduction-list__item:nth-last-child(-n+2) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-buy-reduction-list__item {
    width: calc((100% - 48px) / 4);
  }
}
@media (min-width: 1024px) {
  .p-buy-reduction-list__item {
    width: calc((100% - 16px) / 2);
    max-width: 160px;
  }
}
.p-buy-reduction-photo__img {
  width: 100%;
}
.p-buy-reduction-photo__txt {
  margin-top: 4px;
  font-size: 0.75rem;
  color: #ee5656;
}
.p-buy-case {
  padding: 16px 16px 32px;
  border: 1px solid #E5E5E5;
  margin-bottom: 16px;
}
.p-buy-case:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-buy-case {
    width: calc((100% - 16px) / 2);
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .p-buy-case {
    padding: 32px;
  }
}
.p-buy-case__ttl {
  margin-bottom: 8px;
  font-size: 1rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-buy-case__ttl {
    margin-bottom: 16px;
    font-size: 1.25rem;
  }
}
.p-buy-case__lead {
  font-size: 0.875rem;
}
.p-buy-case-box {
  margin-top: 16px;
}
.p-buy-case-box__ttl {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
  font-size: 1rem;
}
.p-buy-case-box__ttl::before {
  content: "■";
  display: block;
  margin-right: 4px;
}
@media (min-width: 1024px) {
  .p-buy-case-box__ttl {
    margin-bottom: 8px;
  }
}
.p-buy-case-box__cont {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-buy-case-box__cont {
    margin-bottom: 4px;
  }
  .p-buy-case-box__cont:last-of-type {
    margin-bottom: 0;
  }
}
.p-buy-case-list {
  padding-left: 24px;
  list-style: disc;
}
.p-buy-case-list__item {
  margin-bottom: 4px;
  font-size: 0.875rem;
}
.p-buy-case-list__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .p-buy-case-list__item {
    margin-bottom: 8px;
  }
}
.p-buy-case-note {
  margin-top: 16px;
}
.p-buy-case-note__item {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
  font-size: 0.875rem;
  color: #ee5656;
}
.p-buy-case-note__item::before {
  content: "※";
  display: block;
  margin-right: 4px;
}
.p-buy-case-note__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .p-buy-case-note__item {
    margin-bottom: 8px;
  }
}
.p-buy-case-flow {
  padding-left: 24px;
  list-style: decimal;
}
.p-buy-case-flow__item {
  margin-bottom: 8px;
  font-size: 0.875rem;
}
.p-buy-case-flow__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .p-buy-case-flow__item {
    margin-bottom: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .p-campaign {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
}
.p-campaign__link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
}
@media (min-width: 1024px) {
  .p-campaign__link {
    width: 1040px;
    border: 1px solid #ffffff;
    font-size: 1.125rem;
  }
}
.p-campaign__link:hover {
  background-color: #ffffff;
  text-decoration: none;
  color: #4A545F;
}

.p-case {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-case {
    padding: 32px 0 80px;
  }
}
.p-case__header {
  margin-bottom: 32px;
}
.p-case__title {
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-case__title {
    font-size: 1.75rem;
  }
}
@media (min-width: 768px) {
  .p-case-list {
    display: flex;
    flex-wrap: wrap;
  }
}
.p-case-list__item {
  margin-bottom: 16px;
}
.p-case-list__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-case-list__item {
    width: calc((100% - 48px) / 4);
    margin-bottom: 24px;
    margin-right: 16px;
  }
  .p-case-list__item:nth-child(4n) {
    margin-right: 0;
  }
  .p-case-list__item:nth-last-child(-n+4) {
    margin-bottom: 0;
  }
}
.p-case-list__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2F2F2F;
}
.p-case-list__link:hover {
  opacity: 0.8;
}
.p-case-list__text {
  margin-top: 4px;
  font-size: 0.875rem;
}
.p-case-list-photo {
  overflow: hidden;
  aspect-ratio: 4/3;
}
.p-case-content p, .p-case-content__text {
  margin-bottom: 24px;
  font-size: 1rem;
  line-height: 1.8;
}
.p-case-content p:last-of-type, .p-case-content__text:last-of-type {
  margin-bottom: 64px;
}
@media (min-width: 768px) {
  .p-case-content p, .p-case-content__text {
    margin-bottom: 32px;
  }
}
.p-case-content-photo {
  max-width: 688px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .p-case-content-photo {
    margin-bottom: 32px;
  }
}
.p-case-content-author {
  display: flex;
  align-items: center;
}
.p-case-content-author__shop {
  margin-right: 16px;
  font-size: 1rem;
  font-weight: 500;
}
.p-case-content-author__name {
  font-size: 1rem;
}

.p-comment {
  margin-top: 32px;
}
.p-comment__title {
  font-size: 1.125rem;
  font-weight: bold;
}
.p-corporate {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-corporate {
    padding: 32px 0 64px;
  }
}
.p-corporate__header {
  margin-bottom: 32px;
}
.p-corporate__ttl {
  margin-bottom: 8px;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-corporate__ttl {
    font-size: 1.75rem;
  }
}
.p-corporate__lead {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-corporate__lead {
    font-size: 1rem;
  }
}
.p-corporate-service {
  margin-bottom: 32px;
}
.p-corporate-service:last-of-type {
  margin-bottom: 0;
}
.p-corporate-service__ttl {
  margin-bottom: 8px;
  font-size: 1.25rem;
  text-align: center;
}
@media (min-width: 768px) {
  .p-corporate-service__ttl {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .p-corporate-service__ttl {
    font-size: 1.5rem;
  }
}
.p-corporate-service__maker {
  padding-right: 8px;
  padding-left: 8px;
  background: linear-gradient(transparent 80%, #F3EEDD 0%);
}
@media (min-width: 768px) {
  .p-corporate-service__maker {
    padding-right: 16px;
    padding-left: 0;
  }
}
.p-corporate-service__desc {
  font-size: 0.875rem;
  text-align: center;
}
@media (min-width: 768px) {
  .p-corporate-service__desc {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .p-corporate-service__desc {
    font-size: 1rem;
  }
}
.p-corporate-photo {
  margin-top: 16px;
}
.p-corporate-photo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.p-corporate-photo-list__item {
  width: calc((100% - 16px) / 2);
  margin-bottom: 16px;
}
.p-corporate-photo-list__item:last-child {
  margin-bottom: 0;
}
.p-corporate-photo-list__item:nth-child(n+5) {
  width: 100%;
}
@media (min-width: 768px) {
  .p-corporate-photo-list__item:nth-child(-n+4) {
    width: calc((100% - 48px) / 4);
  }
  .p-corporate-photo-list__item:nth-child(n+5) {
    width: calc((100% - 16px) / 2);
    margin-bottom: 0;
  }
}
.p-corporate-case {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .p-corporate-case__inner {
    display: flex;
  }
}
.p-corporate-case__banner {
  width: calc((100% - 16px * (8 - 1)) / 8 * 6 + 16px * (6 - 1));
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
}
@media (min-width: 768px) {
  .p-corporate-case__banner {
    width: calc((100% - 16px * (8 - 1)) / 8 * 3 + 16px * (3 - 1));
    margin-right: 16px;
    margin-bottom: 0;
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .p-corporate-case__banner {
    width: calc((100% - 16px * (12 - 1)) / 12 * 4 + 16px * (4 - 1));
  }
}
@media (min-width: 768px) {
  .p-corporate-case__cont {
    display: flex;
    flex-flow: column;
    width: calc((100% - 16px * (8 - 1)) / 8 * 5 + 16px * (5 - 1));
  }
}
@media (min-width: 1024px) {
  .p-corporate-case__cont {
    width: calc((100% - 16px * (12 - 1)) / 12 * 8 + 16px * (8 - 1));
  }
}
.p-corporate-case__ttl {
  margin-bottom: 8px;
  font-size: 1.125rem;
  text-align: center;
}
@media (min-width: 768px) {
  .p-corporate-case__ttl {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .p-corporate-case__ttl {
    margin-bottom: 16px;
    font-size: 1.25rem;
  }
}
.p-corporate-case__txt {
  font-size: 0.8125rem;
}
@media (min-width: 768px) {
  .p-corporate-case__txt {
    flex-grow: 1;
  }
}
@media (min-width: 1024px) {
  .p-corporate-case__txt {
    font-size: 1rem;
  }
}
.p-corporate-case__link {
  position: relative;
  display: block;
  width: 100%;
  padding-right: 16px;
  margin-top: 8px;
  font-size: 0.875rem;
  text-align: right;
}
.p-corporate-case__link:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 1px solid #3579BF;
  border-bottom: 1px solid #3579BF;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 4px;
}
@media (min-width: 1024px) {
  .p-corporate-case__link {
    font-size: 0.875rem;
  }
}

.p-covid19 {
  max-width: 688px;
  margin-right: auto;
  margin-bottom: 32px;
  margin-left: auto;
  border: 2px solid #ee5656;
  border-radius: 4px;
  color: #ee5656;
  font-weight: bold;
}
.p-covid19__title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 48px;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .p-covid19__title {
    height: 56px;
    font-size: 1.375rem;
  }
  .p-covid19__title:hover {
    opacity: 0.7;
  }
}
.p-covid19__title::before {
  content: "\e90f";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 8px;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-covid19__title::before {
    right: 16px;
    font-size: 2rem;
  }
}
.is-open .p-covid19__title::before {
  content: "\e910";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-covid19__inner {
  display: none;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
}
@media (min-width: 1024px) {
  .p-covid19__inner {
    padding-right: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
  }
}
.is-open .p-covid19__inner {
  display: block;
}
.p-covid19__text {
  font-size: 0.875rem;
}
.p-covid19-measures {
  margin-top: 16px;
}
.p-covid19-measures__title {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .p-covid19-measures__title {
    font-size: 1.25rem;
  }
}
.p-covid19-measures-list {
  padding-left: 16px;
  list-style: decimal;
}
.p-covid19-measures-list__item {
  margin-bottom: 4px;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-covid19-measures-list__item {
    margin-bottom: 8px;
  }
}
.p-covid19-measures-list__item:last-child {
  margin-bottom: 0;
}

.p-designer {
  padding: 32px 8px;
}
@media (min-width: 1024px) {
  .p-designer {
    padding: 32px 0;
  }
}
.p-designer__header {
  margin-bottom: 32px;
}
.p-designer__ttl {
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  padding-left: 32px;
  margin-bottom: 16px;
  font-size: 1.375rem;
}
.p-designer__ttl::before {
  left: 0;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .p-designer__ttl {
    padding-left: 48px;
    margin-bottom: 32px;
    font-size: 1.5rem;
  }
  .p-designer__ttl::before {
    font-size: 2rem;
  }
}
.p-designer__desc {
  font-size: 0.875rem;
}
.p-designer-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 64px;
}
.p-designer-list__item {
  width: calc((100% - 16px) / 2);
  margin-right: 16px;
  margin-bottom: 16px;
}
.p-designer-list__item:nth-child(even) {
  margin-right: 0;
}
.p-designer-list__item:nth-last-child(-n+2) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-designer-list__item {
    width: calc((100% - 48px) / 4);
    margin-bottom: 24px;
  }
  .p-designer-list__item:nth-child(even) {
    margin-right: 16px;
  }
  .p-designer-list__item:nth-child(4n) {
    margin-right: 0;
  }
  .p-designer-list__item:nth-last-child(-n+4) {
    margin-bottom: 0;
  }
}
.p-designer-block {
  position: relative;
}
.p-designer-block:hover {
  opacity: 0.8;
}
.p-designer-block__photo {
  margin-bottom: 16px;
}
.p-designer-block__ttl {
  display: flex;
  justify-content: center;
}
.p-designer-block__link {
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  color: #2F2F2F;
}
.p-designer-block__link::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-designer-block__en {
  display: block;
  font-size: 0.75rem;
  letter-spacing: 0.1em;
}

.product_detail {
  display: none;
}

.data-info th,
.data-info td {
  display: block;
  text-align: left;
}
.data-info tr {
  display: block;
  margin-bottom: 16px;
}
.data-info th {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
}
.data-info td {
  font-size: 1rem;
}

.p-detail {
  display: flex;
  flex-flow: column-reverse;
}
@media (min-width: 768px) {
  .p-detail {
    flex-flow: row-reverse;
    justify-content: space-between;
    padding: 16px 8px;
  }
}
@media (min-width: 1024px) {
  .p-detail {
    padding: 32px 0;
  }
}
.p-detail__inner {
  padding: 0 8px 32px;
}
@media (min-width: 768px) {
  .p-detail__inner {
    width: calc((100% - 16px * (8 - 1)) / 8 * 3 + 16px * (3 - 1));
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .p-detail__inner {
    width: 304px;
  }
}
@media (min-width: 1440px) {
  .p-detail__inner {
    width: 336px;
  }
}
.p-detail-photo {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .p-detail-photo {
    width: calc((100% - 16px * (8 - 1)) / 8 * 5 + 16px * (5 - 1));
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .p-detail-photo {
    width: 624px;
  }
}
@media (min-width: 1440px) {
  .p-detail-photo {
    width: 688px;
  }
}
.p-detail__ttl {
  margin-bottom: 8px;
  font-size: 1.375rem;
}
@media (min-width: 768px) {
  .p-detail__ttl {
    margin-bottom: 16px;
  }
}
.p-detail__price {
  display: inline-block;
  margin-left: 4px;
}
.p-detail__tax {
  margin-left: 8px;
  font-size: 0.875rem;
}
.p-detail-designer {
  margin-bottom: 8px;
}
.p-detail-designer__link {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  text-decoration: none;
  color: #919191;
}
.p-detail-designer__link:hover {
  opacity: 0.7;
}
.p-detail-designer__link:hover::before {
  opacity: 0.7;
}
.p-detail-designer__link::before {
  position: static;
  margin-right: 8px;
  font-size: 1rem;
  color: #919191;
}
.p-detail-action__cart {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 56px;
  background-color: #EECF56;
  border-radius: 2px;
  font-size: 1.125rem;
  font-weight: bold;
  color: #ffffff;
}
.p-detail-action__cart::before {
  position: static;
  margin-right: 8px;
  font-size: 1.5rem;
  color: #ffffff;
}
.p-detail-action__cart:hover {
  opacity: 0.7;
}
.p-detail-action__disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 48px;
  background-color: #d9d9d9;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}
.p-detail-shipping {
  margin-top: 16px;
}
.p-detail-shipping__button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 48px;
  border: 2px solid #E5E5E5;
  border-radius: 2px;
  font-size: 0.875rem;
  font-weight: bold;
  color: #2F2F2F;
}
.p-detail-shipping__button::before {
  position: static;
  margin-right: 8px;
  font-size: 1.25rem;
}
.p-detail-shipping__button:hover {
  opacity: 0.7;
}
.p-detail-shipping__image {
  width: 862px;
}
.p-detail-price {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
}
.p-detail-price__ttl {
  position: relative;
  width: 64px;
  margin-right: 8px;
  font-size: 0.875rem;
}
.p-detail-price__ttl::before {
  content: "：";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.p-detail-price__body {
  flex: 1;
  font-size: 1.5rem;
}
.p-detail-price__num {
  margin-left: 4px;
  font-size: 1.5rem;
}
.p-detail-price__tax {
  font-size: 0.875rem;
}
.p-detail-id {
  display: flex;
  margin-bottom: 8px;
}
.p-detail-id__ttl {
  position: relative;
  width: 64px;
  margin-right: 8px;
  font-size: 0.875rem;
}
.p-detail-id__ttl::before {
  content: "：";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.p-detail-id__body {
  flex: 1;
  font-size: 1rem;
}
.p-detail-stock {
  display: flex;
  align-items: baseline;
  margin-bottom: 24px;
}
.p-detail-stock__ttl {
  position: relative;
  width: 64px;
  margin-right: 8px;
  font-size: 0.875rem;
}
.p-detail-stock__ttl::before {
  content: "：";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.p-detail-stock__body {
  font-size: 1rem;
}
.p-detail-service {
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
}
.p-detail-service__txt {
  margin-bottom: 8px;
  font-size: 1rem;
  color: #ee5656;
}
.p-detail-service__link {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 8px;
  font-size: 0.875rem;
}
.p-detail-service__link:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 1px solid #3579BF;
  border-bottom: 1px solid #3579BF;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
}
.p-detail-service-list {
  padding-left: 24px;
  margin-bottom: 8px;
  list-style: disc;
}
.p-detail-service-list__item {
  margin-bottom: 4px;
  font-size: 0.875rem;
}
.p-detail-service-list__item:last-child {
  margin-bottom: 0;
}
.p-detail-explain {
  padding-bottom: 16px;
  margin: 32px 0 16px;
  border-bottom: 1px solid #E5E5E5;
}
.p-detail-box {
  margin-bottom: 16px;
}
.p-detail-box__ttl {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
}
.p-detail-box__body {
  font-size: 1rem;
}
.p-detail-sect__ttl {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
}
.p-detail-sect__txt {
  font-size: 1rem;
}
.p-detail-gallery {
  margin-bottom: 8px;
}
.p-detail-inquiry {
  margin-bottom: 32px;
}
.p-detail-inquiry__ttl {
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
}
.p-detail-inquiry-list {
  display: flex;
  justify-content: space-between;
}
.p-detail-inquiry-list__item {
  flex: 1 1 auto;
}
.p-detail-inquiry-list__item:first-child {
  width: calc((100% - 16px) / 3 * 2);
  margin-right: 16px;
}
.p-detail-inquiry-list__item:last-child {
  width: calc((100% - 16px) / 3 * 1);
}
.p-detail-inquiry-list__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 2px;
  font-size: 0.875rem;
  font-weight: bold;
}
.p-detail-inquiry-list__btn:hover {
  text-decoration: none;
}
.p-detail-inquiry-list__btn::before {
  margin-right: 8px;
  font-size: 1.5rem;
}
.p-detail-inquiry-list__btn.-form {
  border: 1px solid #00BCD4;
  color: #2F2F2F;
}
.p-detail-inquiry-list__btn.-form::before {
  content: "\e90e";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #00BCD4;
}
@media (min-width: 1024px) {
  .p-detail-inquiry-list__btn.-form:hover {
    background-color: #00BCD4;
    color: #ffffff;
  }
  .p-detail-inquiry-list__btn.-form:hover::before {
    color: #ffffff;
  }
}
.p-detail-inquiry-list__btn.-line {
  border: 1px solid #00C300;
  color: #2F2F2F;
}
.p-detail-inquiry-list__btn.-line::before {
  content: "\e90c";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #00C300;
}
@media (min-width: 1024px) {
  .p-detail-inquiry-list__btn.-line:hover {
    background-color: #00C300;
    color: #ffffff;
  }
  .p-detail-inquiry-list__btn.-line:hover::before {
    color: #ffffff;
  }
}
.p-detail-menu {
  margin-bottom: 32px;
}
.p-detail-menu__item {
  margin-bottom: 16px;
}
.p-detail-menu__item:last-child {
  margin-bottom: 0;
}
.p-detail-menu__link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: 32px;
  font-size: 0.875rem;
  color: #3579BF;
}
.p-detail-menu__link:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 1px solid #3579BF;
  border-bottom: 1px solid #3579BF;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 16px;
}
.p-detail-share__ttl {
  margin-bottom: 8px;
  font-size: 0.875rem;
  text-align: center;
}
.p-detail-share-list {
  display: flex;
  padding: 8px;
  background-color: #F7F7F7;
}
.p-detail-share-list__item {
  width: calc((100% - 48px) / 4);
  margin-right: 16px;
}
.p-detail-share-list__item:last-child {
  margin-right: 0;
}
.p-detail-share-list__link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 32px;
}
.p-detail-share-list__link::before {
  font-size: 1.25rem;
  color: #ffffff;
}
.p-detail-share-list__link:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .p-detail-share-list__link:hover {
    opacity: 0.7;
  }
}
.p-detail-share-list__link.-facebook {
  background-color: #1877F2;
}
.p-detail-share-list__link.-facebook::before {
  content: "\e90b";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-detail-share-list__link.-x {
  background-color: #0F1419;
}
.p-detail-share-list__link.-x::before {
  content: "\e93e";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-detail-share-list__link.-line {
  background-color: #00C300;
}
.p-detail-share-list__link.-line::before {
  content: "\e90c";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-detail-share-list__link.-mail {
  background-color: #666666;
}
.p-detail-share-list__link.-mail::before {
  content: "\e906";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-detail-share-list__txt {
  display: none;
}

.p-fabric {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-fabric {
    padding: 32px 0 128px;
  }
}
.p-fabric__header {
  margin-bottom: 32px;
}
.p-fabric__title {
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-fabric__title {
    font-size: 1.75rem;
  }
}
.p-fabric__lead {
  margin-top: 8px;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-fabric__lead {
    font-size: 1rem;
  }
}
.p-fabric-section {
  margin-bottom: 32px;
}
.p-fabric-section:last-of-type {
  margin-bottom: 0;
}
.p-fabric-section__header {
  margin-bottom: 16px;
}
.p-fabric-section__title {
  font-size: 1.25rem;
}
@media (min-width: 1024px) {
  .p-fabric-section__title {
    font-size: 1.5rem;
  }
}
.p-fabric-section__maker {
  padding-right: 8px;
  background: linear-gradient(transparent 80%, #F3EEDD 0%);
}
@media (min-width: 768px) {
  .p-fabric-section__maker {
    padding-right: 16px;
    padding-left: 0;
  }
}
.p-fabric-section__link {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 8px;
  margin-top: 16px;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-fabric-section__link {
    margin-top: 32px;
  }
}
.p-fabric-section__link:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 1px solid #3579BF;
  border-bottom: 1px solid #3579BF;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
}
.p-fabric-block {
  margin-bottom: 32px;
}
.p-fabric-block:last-of-type {
  margin-bottom: 0;
}
.p-fabric-block__header {
  margin-bottom: 8px;
}
@media (min-width: 1024px) {
  .p-fabric-block__header {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;
  }
}
.p-fabric-block__title {
  padding-left: 8px;
  margin-bottom: 4px;
  border-left: 4px solid #F3EEDD;
  font-size: 1.125rem;
}
@media (min-width: 1024px) {
  .p-fabric-block__title {
    font-size: 1.375rem;
  }
}
.p-fabric-list {
  padding-left: 24px;
  list-style: disc;
}
.p-fabric-list__item {
  margin-bottom: 8px;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-fabric-list__item {
    font-size: 1rem;
  }
}
.p-fabric-list__item:last-child {
  margin-bottom: 0;
}
.p-fabric-composition {
  display: flex;
  align-items: baseline;
}
@media (min-width: 1024px) {
  .p-fabric-composition {
    margin-left: 16px;
  }
}
.p-fabric-composition__head {
  flex: 0 0 auto;
  margin-right: 8px;
  font-size: 0.875rem;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .p-fabric-composition__head {
    font-size: 1rem;
  }
}
.p-fabric-composition__body {
  flex: 1 1 auto;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-fabric-composition__body {
    font-size: 1rem;
  }
}
.p-fabric-product {
  display: flex;
  flex-wrap: wrap;
}
.p-fabric-product__item {
  width: calc((100% - 32px) / 3);
  margin-right: 16px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .p-fabric-product__item {
    width: calc((100% - 80px) / 6);
  }
}
.p-fabric-product__item:nth-child(3n) {
  margin-right: 0;
}
@media (min-width: 768px) {
  .p-fabric-product__item:nth-child(3n) {
    margin-right: 16px;
  }
}
@media (min-width: 768px) {
  .p-fabric-product__item:nth-child(6n) {
    margin-right: 0;
  }
}
.p-fabric-product__item:nth-last-child(-n+3) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-fabric-product__item:nth-last-child(-n+6) {
    margin-bottom: 0;
  }
}
.p-fabric-product__lightbox {
  color: #2F2F2F;
}
.p-fabric-product__lightbox:hover {
  text-decoration: none;
  opacity: 0.7;
}
.p-fabric-product__name {
  font-size: 0.75rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-fabric-product__name {
    margin-top: 4px;
    font-size: 1rem;
  }
}

.p-faq {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-faq {
    padding: 32px 0 80px;
  }
}
.p-faq__header {
  margin-bottom: 32px;
}
.p-faq__title {
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-faq__title {
    font-size: 1.75rem;
  }
}
.p-faq-list {
  border-bottom: 1px solid #E5E5E5;
}
.p-faq-list:first-of-type {
  border-top: 1px solid #E5E5E5;
}
.p-faq-list.is-open > .p-faq-list__question:after {
  content: "\e910";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-faq-list.is-open > .p-faq-list__answer {
  display: flex;
}
.p-faq-list__question {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  padding-top: 24px;
  padding-right: 40px;
  padding-bottom: 24px;
  cursor: pointer;
}
.p-faq-list__question:hover {
  opacity: 0.7;
}
.p-faq-list__question::before {
  content: "Q";
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  background-color: #606D7C;
  border-radius: 50%;
  font-size: 1.125rem;
  color: #ffffff;
}
.p-faq-list__question:after {
  content: "\e90f";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
  font-size: 1.75rem;
}
.p-faq-list__title {
  flex: 1 1 auto;
  font-size: 1rem;
}
.p-faq-list__answer {
  display: none;
  align-items: baseline;
  padding-bottom: 24px;
  font-size: 1rem;
}
.p-faq-list__answer::before {
  content: "A";
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  background-color: #ee5656;
  border-radius: 50%;
  font-size: 1.125rem;
  color: #ffffff;
}

.p-gallery-main {
  z-index: 1;
}
.p-gallery-main__container {
  z-index: 1;
  margin-bottom: 8px;
}
@media (min-width: 1024px) {
  .p-gallery-main__item {
    padding: 0 40px;
  }
}
@media (min-width: 1440px) {
  .p-gallery-main__item {
    padding: 0 44px;
  }
}
.-review .p-gallery-main__item {
  padding: 0;
}
.p-gallery-pagination {
  display: flex;
  overflow-x: scroll;
  padding: 8px;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
  .p-gallery-pagination {
    flex-wrap: wrap;
    overflow-x: visible;
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .p-gallery-pagination {
    padding: 0 40px;
  }
}
@media (min-width: 1440px) {
  .p-gallery-pagination {
    padding: 0 44px;
  }
}
.p-gallery-pagination__item {
  display: block;
  flex: 0 0 20%;
  width: 64px;
  height: 100%;
  margin-right: 8px;
  box-shadow: 0 0 0 2px transparent;
  border-radius: 0;
  opacity: 1;
  cursor: pointer;
}
.p-gallery-pagination__item:hover {
  opacity: 0.7;
}
.p-gallery-pagination__item:last-child {
  margin-right: 0;
}
.p-gallery-pagination__item.is-on {
  box-shadow: 0 0 0 2px #666666;
}
@media (min-width: 768px) {
  .p-gallery-pagination__item {
    flex-basis: calc((100% - 80px) / 6 * 1 + 0px);
    margin-right: 16px;
    margin-bottom: 8px;
  }
  .p-gallery-pagination__item:nth-child(6n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .p-gallery-pagination__item {
    flex-basis: 64px;
  }
  .p-gallery-pagination__item:nth-child(6n) {
    margin-right: 16px;
  }
  .p-gallery-pagination__item:nth-child(7n) {
    margin-right: 0;
  }
}
@media (min-width: 1440px) {
  .p-gallery-pagination__item {
    flex-basis: 72px;
  }
}

.p-global {
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: -264px;
  z-index: 30;
  width: 264px;
  height: 100vh;
  background-color: #343B42;
  transition: left 0.2s ease-in-out;
}
.p-global.is-show {
  left: 0;
}
@media (min-width: 1440px) {
  .p-global {
    overflow: visible;
    position: static;
    left: auto;
    top: auto;
    max-width: 1040px;
    width: calc(100% - 160px - 96px - 32px);
    height: 100%;
    background-color: transparent;
    transition: none;
  }
}
@media (min-width: 1440px) {
  .p-global-nav {
    display: flex;
    justify-content: center;
    height: 100%;
  }
}
.p-global-nav__item {
  border-bottom: 1px solid #606D7C;
}
@media (min-width: 1440px) {
  .p-global-nav__item {
    margin-right: 24px;
    border-bottom: none;
  }
  .p-global-nav__item:last-child {
    margin-right: 0;
  }
}
.p-global-nav__txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 56px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 1rem;
  color: #ffffff;
  cursor: pointer;
}
.p-global-nav__txt:before, .p-global-nav__txt:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  width: 16px;
  height: 1px;
  margin-top: auto;
  margin-bottom: auto;
  background: #ffffff;
  transition-duration: 0.3s;
}
.p-global-nav__txt::before {
  transform: rotate(90deg);
}
.p-global-nav__txt.is-open::before {
  transform: rotate(0deg);
}
@media (min-width: 1440px) {
  .p-global-nav__txt {
    height: 100%;
    padding-left: 0;
    font-size: 0.875rem;
    color: #2F2F2F;
  }
  .p-global-nav__txt::before {
    display: none;
  }
  .p-global-nav__txt:after {
    top: -6px;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: transparent;
    border-top: 1px solid #666666;
    border-right: 1px solid #666666;
    transition-duration: 0;
    transform: rotate(135deg);
  }
  .p-global-nav__txt:hover {
    opacity: 0.7;
  }
}
.p-global-nav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 56px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 1rem;
  color: #ffffff;
}
.p-global-nav__link:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: rotate(45deg);
}
@media (min-width: 1440px) {
  .p-global-nav__link {
    height: 100%;
    padding-right: 0;
    padding-left: 0;
    font-size: 0.875rem;
    color: #2F2F2F;
  }
  .p-global-nav__link:after {
    display: none;
  }
  .p-global-nav__link:hover {
    text-decoration: none;
    opacity: 0.7;
  }
}
.p-global-nav__inner {
  display: none;
  background-color: #4A545F;
}
@media (min-width: 1440px) {
  .p-global-nav__inner {
    position: absolute;
    left: 0;
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .p-cat-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1040px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-right: auto;
    margin-left: auto;
  }
  .p-cat-nav::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom: 12px solid #4A545F;
    top: -20px;
    right: 672px;
    left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1440px) {
  .p-cat-nav__block {
    width: calc((100% - 16px * (12 - 1)) / 12 * 4 + 16px * (4 - 1));
  }
  .p-cat-nav__block:nth-of-type(n+4) {
    margin-top: 32px;
  }
}
.p-cat-nav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-right: 16px;
  padding-left: 40px;
  border-top: 1px solid #606D7C;
  font-size: 1rem;
  color: #ffffff;
}
.p-cat-nav__link::before {
  left: 8px;
  font-size: 1.5rem;
  color: inherit;
}
.p-cat-nav__link:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: rotate(45deg);
}
@media (min-width: 1440px) {
  .p-cat-nav__link {
    height: 32px;
    padding-right: 0;
    padding-left: 48px;
    margin-bottom: 16px;
    border-top: none;
    font-size: 1.125rem;
  }
  .p-cat-nav__link::before {
    left: 0;
    font-size: 2rem;
  }
  .p-cat-nav__link:after {
    display: none;
  }
}
.p-cat-menu {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #606D7C;
}
@media (min-width: 1440px) {
  .p-cat-menu {
    border-top: none;
  }
}
.p-cat-menu__item {
  width: 50%;
  border-right: 1px solid #606D7C;
  border-bottom: 1px solid #606D7C;
}
.p-cat-menu__item:nth-child(even) {
  border-right: none;
}
.p-cat-menu__item:first-child:nth-last-child(odd):last-child, .p-cat-menu__item:first-child:nth-last-child(odd) ~ li:last-child {
  border-bottom: none;
}
.p-cat-menu__item:first-child:nth-last-child(even):nth-last-child(-n+2), .p-cat-menu__item:first-child:nth-last-child(even) ~ li:nth-last-child(-n+2) {
  border-bottom: none;
}
@media (min-width: 1440px) {
  .p-cat-menu__item {
    margin-bottom: 16px;
    border-right: none;
    border-bottom: none;
  }
  .p-cat-menu__item:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
.p-cat-menu__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 0.6875rem;
  color: #ffffff;
}
.p-cat-menu__link::before {
  display: none;
}
.p-cat-menu__link:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: rotate(45deg);
}
@media (min-width: 1440px) {
  .p-cat-menu__link {
    height: 24px;
    padding-right: 0;
    padding-left: 32px;
    font-size: 0.75rem;
  }
  .p-cat-menu__link::before {
    display: block;
    left: 0;
    font-size: 1.5rem;
    color: inherit;
  }
  .p-cat-menu__link:after {
    display: none;
  }
}

@media (min-width: 1440px) {
  .p-designer-nav {
    width: 1040px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-right: auto;
    margin-left: auto;
  }
  .p-designer-nav::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom: 12px solid #4A545F;
    top: -20px;
    right: 432px;
    left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1440px) {
  .p-designer-nav__header {
    margin-bottom: 24px;
  }
}
.p-designer-nav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-right: 16px;
  padding-left: 40px;
  border-top: 1px solid #606D7C;
  font-size: 1rem;
  color: #ffffff;
}
.p-designer-nav__link::before {
  left: 8px;
  font-size: 1.5rem;
  color: inherit;
}
.p-designer-nav__link:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: rotate(45deg);
}
@media (min-width: 1440px) {
  .p-designer-nav__link {
    padding-right: 0;
    padding-left: 48px;
    border-top: none;
    font-size: 1.125rem;
  }
  .p-designer-nav__link::before {
    left: 0;
    font-size: 2rem;
  }
  .p-designer-nav__link:after {
    display: none;
  }
}
.p-designer-menu {
  border-top: 1px solid #606D7C;
}
@media (min-width: 1440px) {
  .p-designer-menu {
    display: flex;
    flex-wrap: wrap;
    border-top: none;
  }
}
.p-designer-menu__item {
  border-bottom: 1px solid #606D7C;
}
@media (min-width: 1440px) {
  .p-designer-menu__item {
    display: flex;
    width: calc((100% - 16px * (12 - 1)) / 12 * 3 + 16px * (3 - 1));
    margin-right: 16px;
    margin-bottom: 16px;
    border-bottom: none;
  }
  .p-designer-menu__item:nth-child(4n) {
    margin-right: 0;
  }
  .p-designer-menu__item:nth-last-child(-n+4) {
    margin-bottom: 0;
  }
}
.p-designer-menu__photo {
  display: none;
}
@media (min-width: 1440px) {
  .p-designer-menu__photo {
    display: block;
    width: 64px;
    margin-right: 8px;
  }
}
.p-designer-menu__img {
  display: none;
}
@media (min-width: 1440px) {
  .p-designer-menu__img {
    display: block;
  }
}
.p-designer-menu__link {
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
  height: 48px;
  padding-right: 32px;
  padding-left: 8px;
  font-size: 0.75rem;
  color: #ffffff;
}
.p-designer-menu__link:after {
  content: "";
  display: block;
  position: absolute;
  right: 16px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: rotate(45deg);
}
@media (min-width: 1440px) {
  .p-designer-menu__link {
    flex: 1;
    justify-content: flex-start;
    height: auto;
    padding-right: 0;
    padding-left: 0;
    font-size: 0.875rem;
  }
  .p-designer-menu__link:after {
    display: none;
  }
}
.p-designer-menu__en {
  display: block;
  font-size: 0.625rem;
  letter-spacing: 0.06em;
}
@media (min-width: 1440px) {
  .p-designer-menu__en {
    margin-top: 4px;
    font-size: 0.75rem;
  }
}

@media (min-width: 1440px) {
  .p-stock-nav {
    width: 1040px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-right: auto;
    margin-left: auto;
  }
  .p-stock-nav::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom: 12px solid #4A545F;
    top: -20px;
    right: 208px;
    left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1440px) {
  .p-stock-nav__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
}
.p-stock-nav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-right: 16px;
  padding-left: 40px;
  border-top: 1px solid #606D7C;
  font-size: 1rem;
  color: #ffffff;
}
.p-stock-nav__link::before {
  left: 8px;
  font-size: 1.5rem;
  color: inherit;
}
.p-stock-nav__link:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: rotate(45deg);
}
@media (min-width: 1440px) {
  .p-stock-nav__link {
    padding-right: 0;
    padding-left: 48px;
    border-top: none;
    font-size: 1.125rem;
  }
  .p-stock-nav__link::before {
    left: 0;
    font-size: 2rem;
  }
  .p-stock-nav__link:after {
    display: none;
  }
}
.p-stock-nav__desc {
  display: none;
}
@media (min-width: 1440px) {
  .p-stock-nav__desc {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 8px;
    background-color: #ffffff;
    font-size: 0.8125rem;
  }
}
.p-stock-nav__note {
  font-size: 0.6875rem;
}
.p-stock-menu {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #606D7C;
}
@media (min-width: 1440px) {
  .p-stock-menu {
    justify-content: flex-start;
    border-top: none;
  }
}
.p-stock-menu__item {
  width: 50%;
  border-right: 1px solid #606D7C;
  border-bottom: 1px solid #606D7C;
}
.p-stock-menu__item:nth-child(even) {
  border-right: none;
}
.p-stock-menu__item:first-child:nth-last-child(odd):last-child, .p-stock-menu__item:first-child:nth-last-child(odd) ~ li:last-child {
  border-bottom: none;
}
.p-stock-menu__item:first-child:nth-last-child(even):nth-last-child(-n+2), .p-stock-menu__item:first-child:nth-last-child(even) ~ li:nth-last-child(-n+2) {
  border-bottom: none;
}
@media (min-width: 1440px) {
  .p-stock-menu__item {
    width: calc((100% - 16px * (12 - 1)) / 12 * 3 + 16px * (3 - 1));
    border-right: none;
    border-bottom: none;
  }
}
.p-stock-menu__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 0.6875rem;
  color: #ffffff;
}
.p-stock-menu__link::before {
  display: none;
}
.p-stock-menu__link:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: rotate(45deg);
}
@media (min-width: 1440px) {
  .p-stock-menu__link {
    height: 48px;
    padding-right: 0;
    padding-left: 40px;
    font-size: 0.875rem;
  }
  .p-stock-menu__link::before {
    display: block;
    left: 0;
    font-size: 1.5rem;
    color: inherit;
  }
  .p-stock-menu__link:after {
    display: none;
  }
}

.p-guide {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-guide {
    padding: 32px 0 64px;
  }
}
.p-guide__header {
  margin-bottom: 32px;
}
.p-guide__ttl {
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-guide__ttl {
    font-size: 1.75rem;
  }
}
.p-guide-sect {
  margin-bottom: 40px;
}
.p-guide-sect:last-of-type {
  margin-bottom: 0;
}
.p-guide-sect__ttl {
  display: flex;
  align-items: center;
  position: relative;
  height: 24px;
  padding-left: 40px;
  margin-bottom: 8px;
  font-size: 1.25rem;
}
.p-guide-sect__ttl::before {
  left: 0;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-guide-sect__ttl {
    margin-bottom: 16px;
    font-size: 1.5rem;
  }
}
.p-guide-box {
  margin-bottom: 32px;
}
.p-guide-box:last-of-type {
  margin-bottom: 0;
}
.p-guide-box__ttl {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
}
.p-guide-box__txt {
  margin-bottom: 8px;
  font-size: 0.875rem;
}
.p-guide-box__txt:last-of-type {
  margin-bottom: 0;
}
.p-guide-credit {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.p-guide-credit__item {
  width: calc((100% - 64px) / 5);
  max-width: 64px;
  margin-right: 16px;
}
.p-guide-credit__item:last-child {
  margin-right: 0;
}
.p-guide-list {
  padding-left: 24px;
  margin-bottom: 24px;
  list-style: disc;
}
.p-guide-list__item {
  margin-bottom: 8px;
  font-size: 0.875rem;
}
.p-guide-list__item:last-child {
  margin-bottom: 0;
}
.p-guide-tbl {
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
}
.p-guide-tbl__tr:nth-child(odd) {
  background-color: #F3EEDD;
}
.p-guide-tbl__th {
  padding: 8px;
  background-color: #4A545F;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  font-size: 0.8125rem;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
}
.p-guide-tbl__th.-pref {
  background-color: #606D7C;
}
.p-guide-tbl__td {
  padding: 8px;
  border-right: 1px solid #E5E5E5;
  font-size: 0.8125rem;
  text-align: right;
  vertical-align: middle;
}
.p-guide-tbl__td:last-child {
  border-right: transparent;
}

.p-tbl-wrapper {
  overflow-x: auto;
  padding: 0 0 8px;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 1024px) {
  .p-tbl-wrapper {
    overflow-x: visible;
    padding: 0;
    white-space: normal;
  }
}

.p-history {
  position: relative;
  padding: 0 8px;
  margin-bottom: 64px;
}
@media (min-width: 1024px) {
  .p-history {
    padding: 0;
  }
}
.p-history-list {
  display: flex;
}
@media (min-width: 1024px) {
  .p-history-list__item {
    width: calc((100% - 112px) / 8);
    margin-right: 16px;
  }
  .p-history-list__item:last-child {
    margin-right: 0;
  }
}
.p-history-block {
  position: relative;
}
.p-history-block:hover {
  opacity: 0.8;
}
.p-history-block__ttl {
  overflow: hidden;
  margin-bottom: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.p-history-block__link {
  color: #2F2F2F;
  text-decoration: none;
}
.p-history-block__link::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-history-block__feature {
  display: flex;
  align-items: center;
  overflow: hidden;
  aspect-ratio: 3/2;
  margin-bottom: 8px;
}
.p-history-block__img {
  width: 100%;
}
.p-history-block__info {
  font-size: 0.875rem;
}
.p-history-block__price {
  display: inline-block;
  margin-right: 4px;
}
.p-history-block__tax {
  font-size: 0.625rem;
}

.p-home-hero {
  position: relative;
  height: 40vh;
  background-image: url("https://file001.shop-pro.jp/PA01327/545/assets/img/home-hero.webp");
  background-size: cover;
  background-position: 50% 0;
}
@media (min-width: 768px) {
  .p-home-hero {
    background-position: bottom;
    height: 80vh;
    background-image: url("https://file001.shop-pro.jp/PA01327/545/assets/img/home-hero_large.jpg");
  }
}
.p-home-free {
  background-color: #F7F7F7;
}
.p-home-free__link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px 8px 8px;
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  color: #2F2F2F;
}
.p-home-free__link::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-home-free__link:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 8px;
}
.p-home-free__link:hover {
  text-decoration: underline;
  opacity: 0.7;
}
@media (min-width: 1024px) {
  .p-home-free__link:after {
    position: relative;
    right: 0;
  }
}
.p-home-info {
  background-color: #F3EEDD;
}
.p-home-info__inner {
  padding: 8px;
}
.p-home-info__ttl {
  font-size: 0.8125rem;
  font-weight: bold;
  text-align: center;
}
.p-home-info__txt {
  font-size: 0.75rem;
  text-align: center;
  margin-top: 4px;
}
@media (min-width: 1440px) {
  .p-home-sect__inner {
    width: 1040px;
  }
}
@media (min-width: 1024px) {
  .p-home-cat {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}
@media (min-width: 1024px) {
  .p-home-cat__block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    width: calc((100% - 32px) / 3);
    margin-bottom: 32px;
  }
  .p-home-cat__block:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}
.p-home-cat__link {
  display: flex;
  align-items: center;
  position: relative;
  height: 56px;
  padding-right: 16px;
  padding-left: 48px;
  border-top: 1px solid #cbcbcb;
  font-size: 1rem;
  color: #2F2F2F;
  text-decoration: none;
}
.p-home-cat__link::before {
  left: 8px;
  font-size: 2rem;
}
.p-home-cat__link:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 16px;
}
.p-home-cat__link:hover {
  opacity: 0.7;
}
@media (min-width: 1024px) {
  .p-home-cat__link {
    border-top: none;
    width: calc((100% - 16px) / 2);
    padding-right: 0;
    font-size: 0.875rem;
  }
  .p-home-cat__link::before {
    left: 0;
  }
  .p-home-cat__link:after {
    display: none;
  }
}
@media (min-width: 1440px) {
  .p-home-cat__link {
    font-size: 1rem;
  }
}
.p-home-menu {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #E5E5E5;
}
@media (min-width: 1024px) {
  .p-home-menu {
    border-top: none;
  }
}
.p-home-menu__item {
  width: 50%;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}
.p-home-menu__item:nth-child(even) {
  border-right: none;
}
.p-home-menu__item:first-child:nth-last-child(odd):last-child, .p-home-menu__item:first-child:nth-last-child(odd) ~ li:last-child {
  border-bottom: none;
}
.p-home-menu__item:first-child:nth-last-child(even):nth-last-child(-n+2), .p-home-menu__item:first-child:nth-last-child(even) ~ li:nth-last-child(-n+2) {
  border-bottom: none;
}
@media (min-width: 1024px) {
  .p-home-menu__item {
    width: calc((100% - 16px) / 2);
    border-right: none;
    border-bottom: none;
  }
}
.p-home-menu__link {
  display: flex;
  align-items: center;
  position: relative;
  height: 48px;
  padding-right: 16px;
  padding-left: 32px;
  font-size: 0.6875rem;
  color: #2F2F2F;
  text-decoration: none;
}
.p-home-menu__link::before {
  left: 8px;
  font-size: 1rem;
}
.p-home-menu__link:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 8px;
}
.p-home-menu__link:hover {
  opacity: 0.7;
}
@media (min-width: 1024px) {
  .p-home-menu__link {
    padding-right: 0;
  }
  .p-home-menu__link::before {
    font-size: 1.5rem;
    left: 0;
  }
  .p-home-menu__link:after {
    display: none;
  }
}
@media (min-width: 1440px) {
  .p-home-menu__link {
    font-size: 0.75rem;
  }
}
.p-home-news {
  overflow-y: auto;
  padding: 0 8px;
  margin: 0 0 64px;
}
@media (min-width: 1024px) {
  .p-home-news {
    width: 624px;
    padding: 0;
    margin: 0 auto 64px;
  }
}
@media (min-width: 1440px) {
  .p-home-news {
    width: 688px;
  }
}
.p-home-news__item {
  display: flex;
  margin-bottom: 8px;
}
.p-home-news__item:last-child {
  margin-bottom: 0;
}
.p-home-news__date {
  display: inline-block;
  width: 72px;
  margin-right: 8px;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .p-home-news__date {
    width: 88px;
    margin-right: 16px;
    font-size: 0.875rem;
  }
}
.p-home-news__txt {
  width: calc(100% - 72px - 8px);
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .p-home-news__txt {
    width: calc(100% - 88px - 16px);
    font-size: 0.875rem;
  }
}

.imagezoom-view img {
  max-width: inherit;
}

.p-inquiry {
  padding: 16px;
  margin-top: 48px;
  border: 4px solid #F3EEDD;
  border-radius: 4px;
}
@media (min-width: 1024px) {
  .p-inquiry {
    max-width: 864px;
    padding: 24px 88px;
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
  }
}
.p-inquiry__ttl {
  margin-bottom: 8px;
  font-size: 1rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-inquiry__ttl {
    font-size: 1.25rem;
  }
}
.p-inquiry__txt {
  font-size: 0.75rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-inquiry__txt {
    font-size: 0.875rem;
  }
}
.p-inquiry-list {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .p-inquiry-list {
    display: flex;
    justify-content: space-between;
  }
}
.p-inquiry-list__item {
  width: 100%;
}
@media (min-width: 768px) {
  .p-inquiry-list__item {
    flex: 1 1 auto;
  }
}
.p-inquiry-list__item:first-child {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .p-inquiry-list__item:first-child {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.p-inquiry-list__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}
.p-inquiry-list__link:hover {
  text-decoration: none;
  opacity: 0.7;
}
.p-inquiry-list__link::before {
  margin-right: 8px;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-inquiry-list__link {
    height: 64px;
  }
  .p-inquiry-list__link:before {
    font-size: 2rem;
  }
}
.p-inquiry-list__link.-line {
  background-color: #00C300;
}
.p-inquiry-list__link.-line::before {
  content: "\e90c";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-inquiry-list__link.-form {
  background-color: #00BCD4;
}
.p-inquiry-list__link.-form::before {
  content: "\e90e";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-inquiry-list__txt {
  margin-top: 4px;
  font-size: 0.75rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-inquiry-list__txt {
    margin-top: 8px;
  }
}
.p-inquiry-tel {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .p-inquiry-tel {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.p-inquiry-tel__num {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 1.75rem;
  color: #666666;
}
.p-inquiry-tel__num::before {
  content: "\e905";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 8px;
  font-size: 1.5rem;
}
.p-inquiry-tel__num:hover {
  text-decoration: none;
  opacity: 0.7;
}
.p-inquiry-tel-box {
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .p-inquiry-tel-box {
    margin-bottom: 0;
  }
}
.p-inquiry-tel-box__ttl {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-inquiry-tel-box__ttl {
    font-size: 1.125rem;
  }
}
.p-inquiry-tel-box__cont {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .p-inquiry-tel-box__cont {
    flex-direction: row;
  }
}
.p-inquiry-tel-box__txt {
  font-size: 0.75rem;
}
@media (min-width: 1024px) {
  .p-inquiry-tel-box__txt {
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  .p-inquiry-tel-box__txt:first-child {
    margin-right: 16px;
  }
}

.p-introduction {
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .p-introduction {
    padding-right: 0;
    padding-left: 0;
  }
}
.p-introduction__ttl {
  margin-bottom: 8px;
  font-size: 1.125rem;
}
.p-introduction__txt {
  font-size: 0.875rem;
}

.p-law {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-law {
    padding: 32px 0 64px;
  }
}
.p-law__header {
  margin-bottom: 32px;
}
.p-law__ttl {
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-law__ttl {
    font-size: 1.75rem;
  }
}
.p-law-box {
  margin-bottom: 24px;
}
.p-law-box:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-law-box {
    display: flex;
  }
}
.p-law-box__ttl {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
}
@media (min-width: 768px) {
  .p-law-box__ttl {
    min-width: 200px;
    margin-bottom: 0;
  }
}
.p-law-box__body {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .p-law-box__body {
    width: calc(100% - 200px);
  }
}

.p-list {
  padding: 32px 0 64px;
}
.p-list__header {
  padding: 0 8px;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .p-list__header {
    padding: 0;
  }
}
.p-list__ttl {
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  padding-left: 32px;
  margin-bottom: 32px;
  font-size: 1.375rem;
  line-height: 1.3;
}
.p-list__ttl::before {
  left: 0;
  font-size: 1.5rem;
}
.p-list__ttl.-search {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .p-list__ttl {
    padding-left: 48px;
    font-size: 1.5rem;
  }
  .p-list__ttl::before {
    font-size: 2rem;
  }
  .p-list__ttl.-search {
    font-size: 1.5rem;
  }
}
.p-list__num {
  display: inline-block;
  margin-left: 8px;
  font-size: 0.875rem;
}
.p-list-designer {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .p-list-designer {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .p-list-designer__inner {
    flex: 1 1 auto;
  }
}
.p-list-designer__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  font-size: 1.125rem;
}
@media (min-width: 768px) {
  .p-list-designer__title {
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 1.375rem;
  }
}
.p-list-designer__en {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .p-list-designer__en {
    font-size: 1rem;
    margin-left: 8px;
  }
}
.p-list-designer__text {
  position: relative;
  overflow: hidden;
  height: 80px;
  font-size: 0.875rem;
}
.p-list-designer__text::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 16px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.8), #ffffff);
}
.p-list-designer__text.is-close {
  height: auto;
}
.p-list-designer__text.is-close::before {
  display: none;
}
@media (min-width: 768px) {
  .p-list-designer__text {
    height: 120px;
  }
}
@media (min-width: 1024px) {
  .p-list-designer__text {
    height: 144px;
  }
}
@media (min-width: 1440px) {
  .p-list-designer__text {
    overflow: visible;
    height: auto;
  }
  .p-list-designer__text::before {
    display: none;
  }
}
.p-list-designer__feature {
  width: calc((100% - 16px * (16 - 1)) / 16 * 12 + 16px * (12 - 1));
  margin-right: auto;
  margin-bottom: 8px;
  margin-left: auto;
  text-align: center;
}
@media (min-width: 768px) {
  .p-list-designer__feature {
    flex: 0 0 calc((100% - 48px) / 4);
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.p-list__msg {
  padding: 16px;
  margin-bottom: 24px;
  border: 4px solid #F3EEDD;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
}
.p-list__desc {
  position: relative;
  margin-bottom: 24px;
}
.p-list__txt {
  position: relative;
  overflow: hidden;
  height: 40px;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .p-list__txt {
    font-size: 0.875rem;
  }
}
.p-list__txt::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 16px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.8), #ffffff);
}
.p-list__txt.is-close {
  height: auto;
}
.p-list__txt.is-close::before {
  display: none;
}
@media (min-width: 768px) {
  .p-list__txt {
    overflow: visible;
    height: auto;
  }
  .p-list__txt::before {
    display: none;
  }
}
.p-list__readmore {
  position: relative;
  width: 100%;
  height: 24px;
}
.p-list__readmore::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(45deg);
  top: 4px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.p-list__readmore.is-close {
  position: relative;
}
.p-list__readmore.is-close::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(-135deg);
  top: 8px;
}
@media (min-width: 1440px) {
  .p-list__readmore {
    display: none;
  }
}
.p-list-filter {
  position: relative;
  margin-bottom: 24px;
}
.p-list-filter__btn {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
  padding-left: 16px;
  background-color: #ffffff;
  border: 1px solid #E5E5E5;
  font-size: 0.875rem;
  font-weight: bold;
}
.p-list-filter__btn::before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(45deg);
  top: -4px;
  right: 16px;
  bottom: 0;
  margin: auto 0;
}
.p-list-filter__btn.-show::before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(-135deg);
  top: 6px;
}
@media (min-width: 1024px) {
  .p-list-filter__btn {
    display: none;
  }
}
.p-list-menu {
  display: none;
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: #ffffff;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  border-left: 1px solid #E5E5E5;
}
@media (min-width: 1024px) {
  .p-list-menu {
    display: flex;
    flex-wrap: wrap;
    position: static;
    border: none;
  }
}
.p-list-menu__item {
  border-bottom: 1px solid #E5E5E5;
}
.p-list-menu__item:last-child {
  border-bottom: none;
}
@media (min-width: 1024px) {
  .p-list-menu__item {
    margin-right: 16px;
    margin-bottom: 16px;
    border: none;
  }
  .p-list-menu__item:last-child {
    margin-right: 0;
  }
}
.p-list-menu__link:hover {
  opacity: 0.7;
}
.p-list-menu__txt, .p-list-menu__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 48px;
  padding-right: 8px;
  padding-left: 40px;
  font-size: 0.875rem;
  text-decoration: none;
  color: #2F2F2F;
}
@media (min-width: 1024px) {
  .p-list-menu__txt, .p-list-menu__link {
    padding-right: 16px;
    border: 1px solid #E5E5E5;
    font-size: 0.75rem;
    font-weight: bold;
  }
}
.p-list-menu__txt::before, .p-list-menu__link::before {
  left: 8px;
  font-size: 1.5rem;
  color: inherit;
}
.p-list-menu__txt.-current, .p-list-menu__link.-current {
  background-color: #d9d9d9;
  color: #ffffff;
}
.p-list-menu__txt.-stocklist, .p-list-menu__link.-stocklist {
  background-color: #4A545F;
  color: #ffffff;
}
.p-list-order {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 1024px) {
  .p-list-order {
    width: 280px;
    margin-left: auto;
  }
}
.p-list-order__item {
  position: relative;
  width: calc((100% - 32px) / 3);
  margin-right: 16px;
}
.p-list-order__item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -8px;
  bottom: 0;
  margin: auto 0;
  width: 1px;
  height: 12px;
  border-right: 1px solid #E5E5E5;
}
.p-list-order__item:last-child {
  margin-right: 0;
}
.p-list-order__item:last-child::before {
  display: none;
}
.p-list-order__txt, .p-list-order__link {
  display: block;
  font-size: 0.875rem;
  text-align: center;
}
.p-list-order__txt {
  font-weight: bold;
}
.p-list-result {
  font-size: 0.75rem;
  text-align: center;
}

.p-maintenance {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-maintenance {
    padding: 32px 0 64px;
  }
}
.p-maintenance__header {
  margin-bottom: 32px;
}
.p-maintenance__ttl {
  margin-bottom: 8px;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-maintenance__ttl {
    font-size: 1.75rem;
  }
}
.p-maintenance__lead {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-maintenance__lead {
    font-size: 1rem;
  }
}
.p-maintenance-movie {
  margin-bottom: 64px;
}
.p-maintenance-movie__inner {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}
@media (min-width: 768px) {
  .p-maintenance-movie__inner {
    height: auto;
    padding-top: 0;
  }
}
.p-maintenance-movie__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .p-maintenance-movie__media {
    position: relative;
    width: 560px;
    height: 315px;
  }
}
.p-maintenance-sect {
  display: flex;
  flex-flow: column-reverse;
  align-items: center;
  justify-content: space-between;
}
.p-maintenance-sect:not(:first-of-type) {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .p-maintenance-sect {
    flex-flow: row;
  }
  .p-maintenance-sect:nth-of-type(even) {
    flex-flow: row-reverse;
  }
}
.p-maintenance-sect__cont {
  padding: 0 8px;
}
@media (min-width: 768px) {
  .p-maintenance-sect__cont {
    width: calc((100% - 16px) / 2);
    padding: 0;
  }
}
.p-maintenance-sect__ttl {
  margin-bottom: 8px;
  font-size: 1.25rem;
}
@media (min-width: 1024px) {
  .p-maintenance-sect__ttl {
    margin-bottom: 16px;
    font-size: 1.5rem;
  }
}
.p-maintenance-sect__txt {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-maintenance-sect__txt {
    font-size: 1rem;
  }
}
.p-maintenance-sect__photo {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .p-maintenance-sect__photo {
    width: calc((100% - 16px) / 2);
    margin-bottom: 0;
  }
}
.p-maintenance-difference {
  margin-top: 40px;
}
@media (min-width: 1024px) {
  .p-maintenance-difference {
    margin-top: 80px;
  }
}
.p-maintenance-difference__ttl {
  margin-bottom: 16px;
  font-size: 1.25rem;
}
@media (min-width: 1024px) {
  .p-maintenance-difference__ttl {
    margin-bottom: 24px;
    font-size: 1.5rem;
  }
}
.p-maintenance-difference__maker {
  padding-right: 8px;
  background: linear-gradient(transparent 80%, #F3EEDD 0%);
}
@media (min-width: 768px) {
  .p-maintenance-difference__maker {
    padding-right: 16px;
  }
}
.p-maintenance-difference__txt {
  font-size: 0.875rem;
}
.p-maintenance-difference__txt:not(:first-of-type) {
  margin-top: 8px;
}
@media (min-width: 1024px) {
  .p-maintenance-difference__txt {
    font-size: 1rem;
  }
  .p-maintenance-difference__txt:not(:first-of-type) {
    margin-top: 16px;
  }
}
.p-maintenance-difference__photo {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
@media (min-width: 1024px) {
  .p-maintenance-difference__photo {
    margin-top: 24px;
  }
}
.p-maintenance-difference__img {
  width: 864px;
}
.p-maintenance-difference-summary {
  margin-top: 32px;
}
.p-maintenance-difference-summary__ttl {
  padding-left: 8px;
  margin-bottom: 8px;
  border-left: 4px solid #F3EEDD;
  font-size: 1.125rem;
}
@media (min-width: 1024px) {
  .p-maintenance-difference-summary__ttl {
    font-size: 1.375rem;
  }
}
.p-maintenance-difference-summary__txt {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-maintenance-difference-summary__txt {
    font-size: 1rem;
  }
}
.p-maintenance-process {
  margin-top: 16px;
}
@media (min-width: 1024px) {
  .p-maintenance-process {
    margin-top: 24px;
  }
}
.p-maintenance-process__desc {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-maintenance-process__desc {
    font-size: 1rem;
  }
}
.p-maintenance-process__txt {
  margin-top: 4px;
  font-size: 0.75rem;
}
@media (min-width: 1024px) {
  .p-maintenance-process__txt {
    font-size: 0.875rem;
  }
}
.p-maintenance-process__cont {
  margin-top: 8px;
}
@media (min-width: 768px) {
  .p-maintenance-process-list {
    display: flex;
  }
}
.p-maintenance-process-list__item:not(:first-child) {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .p-maintenance-process-list__item {
    width: calc((100% - 16px) / 2);
  }
  .p-maintenance-process-list__item:not(:first-child) {
    margin-top: 0;
    margin-left: 16px;
  }
}
.p-maintenance-comparison {
  margin-top: 16px;
}
@media (min-width: 1024px) {
  .p-maintenance-comparison {
    margin-top: 24px;
  }
}
.p-maintenance-comparison__ttl {
  margin-bottom: 8px;
  font-size: 1.125rem;
}
@media (min-width: 1024px) {
  .p-maintenance-comparison__ttl {
    font-size: 1.375rem;
  }
}
.p-maintenance-comparison__txt {
  margin-top: 8px;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-maintenance-comparison__txt {
    font-size: 1rem;
  }
}
.p-maintenance-comparison-list {
  display: flex;
}
.p-maintenance-comparison-list__item:not(:first-child) {
  margin-left: 16px;
}
.p-maintenance-comparison-list__caption {
  margin-top: 4px;
  font-size: 0.75rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .p-maintenance-comparison-list__caption {
    font-size: 0.875rem;
  }
}

.p-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: rgba(47, 47, 47, 0.6);
}
.p-modal.is-show {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-notfound {
  min-height: calc(100vh - 64px - 320px);
  padding: 32px 8px;
}
@media (min-width: 1024px) {
  .p-notfound {
    padding: 32px 0;
  }
}
.p-notfound__msg {
  padding: 16px;
  background-color: #ffd9d9;
  font-size: 0.75rem;
  font-weight: bold;
  text-align: center;
  color: #ee5656;
}
@media (min-width: 1024px) {
  .p-notfound__msg {
    padding: 32px;
    font-size: 1rem;
  }
}

.p-pagination {
  padding: 0 8px;
  margin-bottom: 16px;
}
@media (min-width: 1024px) {
  .p-pagination {
    padding: 0;
  }
}
.p-pagination-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.p-pagination-list__item {
  margin-right: 8px;
}
.p-pagination-list__item:first-child {
  width: calc((100% - 16px) / 2);
  margin-right: 16px;
  margin-bottom: 16px;
}
.p-pagination-list__item:nth-child(2) {
  width: calc((100% - 16px) / 2);
  margin-right: 0;
  margin-bottom: 16px;
}
.p-pagination-list__item:last-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .p-pagination-list__item:first-child {
    order: -1;
    width: 112px;
    margin-bottom: 0;
  }
  .p-pagination-list__item:nth-child(2) {
    order: 1;
    width: 112px;
    margin-right: 0;
    margin-bottom: 0;
  }
  .p-pagination-list__item:last-child {
    margin-right: 16px;
  }
}
.p-pagination-list__txt, .p-pagination-list__link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 32px;
  height: 40px;
  border: 1px solid #E5E5E5;
  font-size: 0.75rem;
  font-weight: bold;
  text-decoration: none;
  color: #2F2F2F;
}
.p-pagination-list__txt.-arrow, .p-pagination-list__link.-arrow {
  width: 100%;
  font-size: 0.875rem;
}
.p-pagination-list__txt.-arrow::before, .p-pagination-list__link.-arrow::before {
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.p-pagination-list__txt.-arrow.-prev::before, .p-pagination-list__link.-arrow.-prev::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(135deg);
  left: 16px;
}
.p-pagination-list__txt.-arrow.-next::before, .p-pagination-list__link.-arrow.-next::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(-45deg);
  right: 16px;
}
.p-pagination-list__txt.-current, .p-pagination-list__link.-current {
  background-color: #EECF56;
  border-color: #EECF56;
  color: #ffffff;
}
.p-pagination-list__txt.-current.-prev::before, .p-pagination-list__txt.-current.-next::before, .p-pagination-list__link.-current.-prev::before, .p-pagination-list__link.-current.-next::before {
  border-color: #ffffff;
}
.p-pagination-list__txt.-ellipsis, .p-pagination-list__link.-ellipsis {
  width: 8px;
  border: none;
}
@media (min-width: 768px) {
  .p-pagination-list__txt, .p-pagination-list__link {
    width: 40px;
  }
}
.p-pagination-list__link:hover {
  background-color: #EECF56;
  border-color: #EECF56;
  color: #ffffff;
  text-decoration: none;
}
.p-pagination-list__link:hover.-prev::before, .p-pagination-list__link:hover.-next::before {
  border-color: #ffffff;
}

.p-privacy {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-privacy {
    padding: 32px 0 64px;
  }
}
.p-privacy__header {
  margin-bottom: 32px;
}
.p-privacy__ttl {
  margin-bottom: 8px;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-privacy__ttl {
    font-size: 1.75rem;
  }
}
.p-privacy__lead {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-privacy__lead {
    font-size: 1rem;
  }
}
.p-privacy-sect {
  margin-bottom: 40px;
}
.p-privacy-sect:last-of-type {
  margin-bottom: 0;
}
.p-privacy-sect__ttl {
  margin-bottom: 16px;
  font-size: 1.25rem;
}
@media (min-width: 1024px) {
  .p-privacy-sect__ttl {
    font-size: 1.5rem;
  }
}
.p-privacy-sect__txt {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-privacy-sect__txt {
    font-size: 1rem;
  }
}

.p-product-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  margin-bottom: 64px;
}
@media (min-width: 1024px) {
  .p-product-list {
    padding: 0;
  }
}
.p-product-list__item {
  width: calc((100% - 16px) / 2);
  margin-right: 16px;
  margin-bottom: 16px;
}
.p-product-list__item:nth-child(even) {
  margin-right: 0;
}
.p-product-list__item:nth-last-child(-n+2) {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-product-list__item {
    width: calc((100% - 48px) / 4);
  }
  .p-product-list__item:nth-child(even) {
    margin-right: 16px;
  }
  .p-product-list__item:nth-child(4n) {
    margin-right: 0;
  }
  .p-product-list__item:nth-last-child(-n+4) {
    margin-bottom: 0;
  }
}

.p-product-block {
  position: relative;
}
.p-product-block:hover {
  opacity: 0.8;
}
.p-product-block__ttl {
  margin-bottom: 4px;
  font-size: 0.75rem;
}
@media (min-width: 1024px) {
  .p-product-block__ttl {
    font-size: 0.875rem;
  }
}
.p-product-block__link {
  color: #2F2F2F;
  text-decoration: none;
}
.p-product-block__link::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-product-block__feature {
  display: flex;
  align-items: center;
  overflow: hidden;
  aspect-ratio: 3/2;
  margin-bottom: 8px;
}
.p-product-block__info {
  font-size: 1rem;
}
@media (min-width: 1024px) {
  .p-product-block__info {
    font-size: 1.125rem;
  }
}
.p-product-block__price {
  display: inline-block;
  margin-right: 4px;
}
.p-product-block__tax {
  font-size: 0.75rem;
}
@media (min-width: 1024px) {
  .p-product-block__tax {
    font-size: 0.875rem;
  }
}
.p-recommend {
  overflow: hidden;
  position: relative;
  margin-bottom: 64px;
}
.p-recommend-list {
  display: flex;
}
.p-recommend-block {
  position: relative;
}
.p-recommend-block:hover {
  opacity: 0.8;
}
.p-recommend-block__ttl {
  margin-bottom: 4px;
  font-size: 0.75rem;
}
@media (min-width: 1024px) {
  .p-recommend-block__ttl {
    font-size: 0.875rem;
  }
}
.p-recommend-block__link {
  color: #2F2F2F;
  text-decoration: none;
}
.p-recommend-block__link::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-recommend-block__feature {
  margin-bottom: 8px;
}
.p-recommend-block__info {
  font-size: 1rem;
}
@media (min-width: 1024px) {
  .p-recommend-block__info {
    font-size: 1.125rem;
  }
}
.p-recommend-block__price {
  display: inline-block;
  margin-right: 4px;
}
.p-recommend-block__tax {
  font-size: 0.75rem;
}
@media (min-width: 1024px) {
  .p-recommend-block__tax {
    font-size: 0.875rem;
  }
}

.p-service {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-service {
    padding: 32px 0 64px;
  }
}
.p-service__header {
  margin-bottom: 32px;
}
.p-service__ttl {
  margin-bottom: 8px;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-service__ttl {
    font-size: 1.75rem;
  }
}
.p-service__lead {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-service__lead {
    font-size: 1rem;
  }
}
.p-service-sect {
  margin-bottom: 32px;
}
.p-service-sect:last-of-type {
  margin-bottom: 0;
}
.p-service-sect__header {
  margin-bottom: 16px;
}
.p-service-sect__ttl {
  font-size: 1.25rem;
  text-align: center;
}
@media (min-width: 768px) {
  .p-service-sect__ttl {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .p-service-sect__ttl {
    font-size: 1.5rem;
  }
}
.p-service-sect__maker {
  padding-right: 8px;
  padding-left: 8px;
  background: linear-gradient(transparent 80%, #F3EEDD 0%);
}
@media (min-width: 768px) {
  .p-service-sect__maker {
    padding-right: 16px;
    padding-left: 0;
  }
}
.p-service-sect__lead {
  margin-top: 8px;
  font-size: 0.875rem;
  text-align: center;
}
@media (min-width: 768px) {
  .p-service-sect__lead {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .p-service-sect__lead {
    font-size: 1rem;
  }
}
.p-service-sect__txt {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .p-service-sect__txt {
    flex-grow: 1;
  }
}
@media (min-width: 1024px) {
  .p-service-sect__txt {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .p-service-sect__inner {
    display: flex;
  }
}
.p-service-sect__banner {
  width: calc((100% - 16px * (8 - 1)) / 8 * 6 + 16px * (6 - 1));
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
}
@media (min-width: 768px) {
  .p-service-sect__banner {
    width: calc((100% - 16px * (8 - 1)) / 8 * 3 + 16px * (3 - 1));
    max-width: none;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .p-service-sect__banner {
    width: calc((100% - 16px * (12 - 1)) / 12 * 4 + 16px * (4 - 1));
  }
}
@media (min-width: 768px) {
  .p-service-sect__cont {
    display: flex;
    flex-flow: column;
    width: calc((100% - 16px * (8 - 1)) / 8 * 5 + 16px * (5 - 1));
  }
}
@media (min-width: 1024px) {
  .p-service-sect__cont {
    width: calc((100% - 16px * (12 - 1)) / 12 * 8 + 16px * (8 - 1));
  }
}
.p-service-sect__footer {
  margin-top: 8px;
  text-align: right;
}
.p-service-sect__link {
  position: relative;
  width: 100%;
  padding-right: 16px;
  margin-top: 8px;
  font-size: 0.875rem;
  text-align: right;
}
.p-service-sect__link:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 1px solid #3579BF;
  border-bottom: 1px solid #3579BF;
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 4px;
}
@media (min-width: 768px) {
  .p-service-sect-list {
    display: flex;
    flex-wrap: wrap;
  }
}
.p-service-sect-list__item {
  margin-bottom: 16px;
}
.p-service-sect-list__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-service-sect-list__item {
    width: calc((100% - 16px) / 2);
    margin-right: 16px;
  }
  .p-service-sect-list__item:nth-child(2n) {
    margin-right: 0;
  }
  .p-service-sect-list__item:nth-child(n+3) {
    margin-bottom: 0;
  }
}
.p-service-purchase__ttl {
  margin-bottom: 8px;
  font-size: 1.125rem;
}
@media (min-width: 1024px) {
  .p-service-purchase__ttl {
    font-size: 1.25rem;
  }
}
.p-service-purchase__txt {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-service-purchase__txt {
    width: calc((100% - 16px * (6 - 1)) / 6 * 3 + 16px * (3 - 1));
  }
}
.p-service-purchase__inner:after {
  content: "";
  display: block;
  clear: both;
}
@media (min-width: 1024px) {
  .p-service-purchase__inner {
    display: flex;
  }
}
.p-service-purchase__banner {
  float: left;
  width: calc((100% - 16px) / 2);
  margin-right: 16px;
}
@media (min-width: 1024px) {
  .p-service-purchase__banner {
    float: none;
    width: calc((100% - 16px * (6 - 1)) / 6 * 3 + 16px * (3 - 1));
  }
}
.p-service-note {
  padding-left: 24px;
  margin-top: 16px;
}
.p-service-note__item {
  position: relative;
  margin-bottom: 4px;
  font-size: 0.875rem;
}
.p-service-note__item:last-child {
  margin-bottom: 0;
}
.p-service-note__item::before {
  content: "※";
  position: absolute;
  left: -20px;
}
.p-service-important {
  margin-top: 16px;
}
.p-service-important__ttl {
  margin-bottom: 8px;
  font-size: 1.125rem;
}
@media (min-width: 1024px) {
  .p-service-important__ttl {
    font-size: 1.25rem;
  }
}
.p-service-important-list {
  padding-left: 24px;
  list-style: disc;
}
.p-service-important-list__item {
  margin-bottom: 4px;
  font-size: 0.875rem;
}
.p-service-important-list__item:last-child {
  margin-bottom: 0;
}

.p-shop {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-shop {
    padding: 32px 0 64px;
  }
}
.p-shop__header {
  margin-bottom: 32px;
}
.p-shop__title {
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-shop__title {
    font-size: 1.75rem;
  }
}
.p-shop__inner {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .p-shop__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;
  }
}
.p-shop-image {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .p-shop-image {
    flex: 1 1 512px;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.p-shop-image__photo {
  margin-bottom: 16px;
}
.p-shop-image__photo:last-child {
  margin-bottom: 0;
}
.p-shop-image__caption {
  margin-top: 4px;
  text-align: center;
}
@media (min-width: 768px) {
  .p-shop-info {
    flex: 1 1 512px;
  }
}
.p-shop-info__text {
  margin-bottom: 16px;
  font-size: 1rem;
}
@media (min-width: 1024px) {
  .p-shop-info__text {
    margin-bottom: 32px;
  }
}
.p-shop-list {
  margin-bottom: 16px;
}
.p-shop-list:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .p-shop-list {
    display: flex;
    align-items: baseline;
  }
}
.p-shop-list__title {
  margin-bottom: 4px;
  font-size: 1rem;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .p-shop-list__title {
    flex: 0 0 120px;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.p-shop-list__content {
  font-size: 1rem;
}
@media (min-width: 1024px) {
  .p-shop-list__content {
    flex: 1 1 auto;
  }
}
.p-shop-sns {
  display: flex;
  margin-top: 8px;
}
.p-shop-sns__item {
  margin-right: 16px;
}
.p-shop-sns__item:last-child {
  margin-right: 0;
}
.p-shop-sns__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: #ffffff;
}
.p-shop-sns__link:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .p-shop-sns__link:hover {
    opacity: 0.7;
  }
}
.p-shop-sns__link.-facebook {
  background-color: #1877F2;
}
.p-shop-sns__link.-facebook::before {
  content: "\e90b";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.75rem;
}
.p-shop-sns__link.-instagram {
  background-color: #E1306C;
}
.p-shop-sns__link.-instagram::before {
  content: "\e90a";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.75rem;
}
.p-shop-sns__link.-youtube {
  background-color: #FF0000;
}
.p-shop-sns__link.-youtube::before {
  content: "\e909";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.75rem;
}
.p-shop-sns__link.-line {
  background-color: #00C300;
}
.p-shop-sns__link.-line::before {
  content: "\e90c";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tanuki-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.75rem;
}

.p-staff {
  padding: 32px 8px 64px;
}
@media (min-width: 1024px) {
  .p-staff {
    padding: 32px 0 64px;
  }
}
.p-staff__header {
  margin-bottom: 32px;
}
.p-staff__ttl {
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .p-staff__ttl {
    font-size: 1.75rem;
  }
}
.p-staff-sect {
  display: flex;
  flex-flow: column-reverse;
  justify-content: space-between;
  margin-bottom: 48px;
}
@media (min-width: 768px) {
  .p-staff-sect {
    flex-flow: row-reverse;
  }
  .p-staff-sect:nth-of-type(even) {
    flex-flow: row;
  }
}
@media (min-width: 768px) {
  .p-staff-sect__inner {
    width: calc((100% - 16px) / 2);
    padding: 0;
  }
}
.p-staff-sect__ttl {
  margin-bottom: 16px;
  font-size: 1.25rem;
}
@media (min-width: 1024px) {
  .p-staff-sect__ttl {
    margin-bottom: 16px;
    font-size: 1.5rem;
  }
}
.p-staff-sect__txt {
  margin-bottom: 16px;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-staff-sect__txt {
    font-size: 1rem;
  }
}
.p-staff-sect__comment {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .p-staff-sect__comment {
    font-size: 1rem;
  }
}
.p-staff-sect__photo {
  position: relative;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .p-staff-sect__photo {
    width: calc((100% - 16px) / 2);
    margin-bottom: 0;
  }
}
.p-staff-sect__img {
  width: 100%;
}
.p-staff-sect__img.-on {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.7s;
}
.p-staff-sect__img.-on.is-fade {
  opacity: 1;
}

.p-stocklist {
  margin-bottom: 24px;
  background-color: #F7F7F7;
}
.p-stocklist-box {
  position: relative;
  overflow: hidden;
  height: 88px;
  padding: 16px;
}
.p-stocklist-box::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  background-image: linear-gradient(180deg, rgba(247, 247, 247, 0.5), #f7f7f7);
}
.p-stocklist-box.is-close {
  height: auto;
}
.p-stocklist-box.is-close::before {
  display: none;
}
@media (min-width: 768px) {
  .p-stocklist-box {
    overflow: visible;
    height: auto;
  }
}
.p-stocklist-box__ttl {
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: bold;
  color: #ee5656;
}
.p-stocklist-desc {
  padding-left: 16px;
  font-size: 0.75rem;
  list-style-type: disc;
}
.p-stocklist-desc__item {
  margin-bottom: 8px;
  font-size: 0.75rem;
}
.p-stocklist-desc__item:last-child {
  margin-bottom: 0;
}
.p-stocklist-desc__note {
  display: block;
  font-size: 0.625rem;
}
.p-stocklist__readmore {
  position: relative;
  width: 100%;
  height: 24px;
}
.p-stocklist__readmore::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(45deg);
  top: 4px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.p-stocklist__readmore.is-close {
  position: relative;
}
.p-stocklist__readmore.is-close::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #666666;
  border-bottom: 1px solid #666666;
  transform: rotate(-135deg);
  top: 8px;
}
@media (min-width: 768px) {
  .p-stocklist__readmore {
    display: none;
  }
}

.p-swiper-controller {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  width: 100%;
}
.p-swiper-controller.-recommend {
  top: 26%;
}
@media (min-width: 768px) {
  .p-swiper-controller.-recommend {
    top: 30%;
  }
}
.p-swiper-controller.-history, .p-swiper-controller.-soldout {
  top: 20%;
}
@media (min-width: 1024px) {
  .p-swiper-controller.-history, .p-swiper-controller.-soldout {
    display: none;
  }
}
.p-swiper-controller.-gallery {
  top: 0;
  bottom: 0;
  height: 40px;
  margin: auto;
}
.p-swiper-pagination {
  display: flex;
  justify-content: center;
}
.p-swiper-pagination-bullet {
  display: block;
  width: 14px;
  height: 14px;
  margin-right: 7px;
  border: 2px solid #666666;
  border-radius: 50%;
}
.p-swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.p-swiper-pagination-bullet.is-swiper-active {
  background-color: #666666;
}
.p-swiper-btn {
  position: relative;
  z-index: 1;
  width: 32px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.6);
}
.p-swiper-btn::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  margin: auto 0;
  border-top: 1px solid #666666;
  border-right: 1px solid #666666;
}
.p-swiper-btn.is-disabled {
  background-color: transparent;
}
.p-swiper-btn.is-disabled::before {
  display: none;
}
.p-swiper-btn.-prev::before {
  left: 12px;
  transform: rotate(-135deg);
}
.p-swiper-btn.-next::before {
  right: 12px;
  transform: rotate(45deg);
}

.p-update-box {
  padding: 16px;
}
.p-update-box__ttl {
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: bold;
}
.p-update-list__item {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}
.p-update-list__item:not(:first-child) {
  margin-top: 8px;
}
.p-update-list__date {
  flex: 0 0 72px;
  margin-right: 8px;
  font-size: 0.75rem;
}
.p-update-list__txt {
  font-size: 0.75rem;
}

.p-utility-nav {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 48px;
  margin: auto 0;
}
@media (min-width: 1440px) {
  .p-utility-nav {
    position: static;
  }
}
.p-utility-nav__item {
  position: relative;
}
.p-utility-nav__item:nth-child(2) {
  display: none;
}
@media (min-width: 1440px) {
  .p-utility-nav__item {
    margin-right: 8px;
  }
  .p-utility-nav__item:nth-child(2) {
    display: block;
  }
}
.p-utility-nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 48px;
}
.p-utility-nav__link::before {
  font-size: 1rem;
}
.p-utility-nav__link:hover {
  opacity: 0.7;
}
.p-utility-search {
  display: none;
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 20;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}
.p-utility-search.is-show {
  display: block;
}
@media (min-width: 1440px) {
  .p-utility-search {
    top: 88px;
  }
  .is-thin .p-utility-search {
    top: 64px;
  }
}
.p-utility-search__select {
  position: relative;
  width: 100%;
  height: 40px;
}
.p-utility-search__select::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 4px;
  width: 8px;
  height: 8px;
  margin: auto 0;
  border-right: 2px solid #666666;
  border-bottom: 2px solid #666666;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  pointer-events: none;
}
.p-utility-search__select > select {
  vertical-align: middle;
  width: 100%;
  height: 40px;
  padding: 0 40px 0 16px;
  line-height: 1;
  border: 1px solid #E5E5E5;
  border-radius: 24px;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (min-width: 768px) {
  .p-utility-search__select > select {
    border-right-color: transparent;
    border-radius: 24px 0 0 24px;
  }
}
.p-utility-search__select > select::-ms-expand {
  display: none;
}
.p-utility-search__select > select:focus {
  outline: none;
  border-color: #EECF56;
}
.p-utility-search__input {
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 0 8px 0 16px;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  border-left: 1px solid #E5E5E5;
  border-radius: 24px 0 0 24px;
  font-size: 1rem;
  -webkit-appearance: none;
}
@media (min-width: 768px) {
  .p-utility-search__input {
    border-radius: 0;
  }
}
.p-utility-search__input:focus {
  border-color: #EECF56;
  outline: none;
}
.p-utility-search__btn {
  width: 72px;
  height: 100%;
  background-color: #4A545F;
  border-radius: 0 24px 24px 0;
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
}
.p-utility-search__btn:hover {
  background-color: #606D7C;
}
@media (min-width: 1440px) {
  .p-utility-search__btn {
    width: 88px;
  }
}
.p-utility-search-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 104px;
  padding: 8px;
}
@media (min-width: 768px) {
  .p-utility-search-box {
    flex-wrap: nowrap;
    max-width: 1040px;
    height: 56px;
    margin: 0 auto;
  }
}
.p-utility-search-box__item {
  height: 40px;
}
.p-utility-search-box__item:first-child {
  width: 100%;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .p-utility-search-box__item:first-child {
    flex: 0 0 184px;
    margin-bottom: 0;
  }
}
.p-utility-search-box__item:nth-child(2) {
  flex: 1;
}
.p-utility-search-box__item:last-child {
  flex: 0;
}