.p-detail {
  display: flex;
  flex-flow: column-reverse;
  @include mq(tablet) {
    flex-flow: row-reverse;
    justify-content: space-between;
    padding: 16px 8px;
  }
  @include mq(laptop) {
    padding: 32px 0;
  }
  &__inner {
    padding: 0 8px 32px;
    @include mq(tablet) {
      @include grid(8,3,16px);
      padding: 0;
    }
    @include mq(laptop) {
      width: 304px;
    }
    @include mq(desktop) {
      width: 336px;
    }
  }
  &-photo {
    margin-bottom: 16px;
    @include mq(tablet) {
      @include grid(8,5,16px);
      margin-bottom: 0;
    }
    @include mq(laptop) {
      width: 624px;
    }
    @include mq(desktop) {
      width: 688px;
    }
  }
  &__ttl {
    margin-bottom: 8px;
    font-size: rem(22);
    @include mq(tablet) {
      margin-bottom: 16px;
    }
    @include mq(desktop) {
    }
  }
  &__price {
    display: inline-block;
    margin-left: 4px;
  }
  &__tax {
    margin-left: 8px;
    font-size: rem(14);
  }
  &-designer {
    margin-bottom: 8px;
    &__link {
      display: flex;
      align-items: center;
      font-size: rem(14);
      text-decoration: none;
      color: colors(font-light);
      &:hover {
        opacity: .7;
        &::before {
          opacity: .7;
        }
      }
      &::before {
        position: static;
        margin-right: 8px;
        font-size: rem(16);
        color: colors(font-light);
      }
    }
  }
  &-action {
    &__cart {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 56px;
      background-color: colors(secondary);
      border-radius: 2px;
      font-size: rem(18);
      font-weight: bold;
      color: colors(base);
      &::before {
        position: static;
        margin-right: 8px;
        font-size: rem(24);
        color: colors(base);
      }
      &:hover {
        opacity: .7;
      }
    }
    &__disabled {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 48px;
      background-color: colors(disabled);
      border-radius: 2px;
      font-size: rem(16);
      font-weight: bold;
      color: colors(base);
    }
  }
  &-shipping {
    margin-top: 16px;
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 48px;
      border: 2px solid colors(border);
      border-radius: 2px;
      font-size: rem(14);
      font-weight: bold;
      color: colors(font);
      &::before {
        position: static;
        margin-right: 8px;
        font-size: rem(20);
      }
      &:hover {
        opacity: .7;
      }
    }
    &__image {
      width: 862px;
    }
  }
  &-price {
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
    &__ttl {
      position: relative;
      width: 64px;
      margin-right: 8px;
      font-size: rem(14);
      &::before {
        content: '：';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &__body {
      flex: 1;
      font-size: rem(24);
    }
    &__num {
      margin-left: 4px;
      font-size: rem(24);
    }
    &__tax {
      font-size: rem(14);
    }
  }
  &-id {
    display: flex;
    margin-bottom: 8px;
    &__ttl {
      position: relative;
      width: 64px;
      margin-right: 8px;
      font-size: rem(14);
      &::before {
        content: '：';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &__body {
      flex: 1;
      font-size: rem(16);
    }
  }
  &-stock {
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;
    &__ttl {
      position: relative;
      width: 64px;
      margin-right: 8px;
      font-size: rem(14);
      &::before {
        content: '：';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &__body {
      font-size: rem(16);
    }
  }
  &-service {
    padding: 16px;
    margin-bottom: 24px;
    border: 1px solid colors(border);
    border-radius: 2px;
    &__txt {
      margin-bottom: 8px;
      font-size: rem(16);
      color: colors(alert);
    }
    &__link {
      display: flex;
      justify-content: flex-end;
      position: relative;
      padding-right: 8px;
      font-size: rem(14);
      &:after {
        @include arrow_line(right,8,1,colors(link),middle);
        right: 0;
      }
    }
    &-list {
      padding-left: 24px;
      margin-bottom: 8px;
      list-style: disc;
      &__item {
        margin-bottom: 4px;
        font-size: rem(14);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &-explain {
    padding-bottom: 16px;
    margin: 32px 0 16px;
    border-bottom: 1px solid colors(border);
  }
  &-box {
    margin-bottom: 16px;
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(16);
      font-weight: bold;
    }
    &__body {
      font-size: rem(16);
    }
  }
  &-sect {
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(16);
      font-weight: bold;
    }
    &__txt {
      font-size: rem(16);
    }
  }
  &-gallery {
    margin-bottom: 8px;
  }
  &-inquiry {
    margin-bottom: 32px;
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(14);
      font-weight: bold;
      text-align: center;
    }
    &-list {
      display: flex;
      justify-content: space-between;
      &__item {
        flex: 1 1 auto;
        &:first-child {
          width: calc((100% - 16px) / 3 * 2);
          margin-right: 16px;
        }
        &:last-child {
          width: calc((100% - 16px) / 3 * 1);
        }
      }
      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 2px;
        font-size: rem(14);
        font-weight: bold;
        &:hover {
          text-decoration: none;
        }
        &::before {
          margin-right: 8px;
          font-size: rem(24);
        }
        &.-form {
          border: 1px solid colors(formrun);
          color: colors(font);
          &::before {
            @include icon(form);
            color: colors(formrun);
          }
          &:hover {
            @include mq(laptop) {
              background-color: colors(formrun);
              color: colors(base);
              &::before {
                color: colors(base);
              }
            }
          }
        }
        &.-line {
          border: 1px solid colors(line);
          color: colors(font);
          &::before {
            @include icon(line);
            color: colors(line);
          }
          &:hover {
            @include mq(laptop) {
              background-color: colors(line);
              color: colors(base);
              &::before {
                color: colors(base);
              }
            }
          }
        }
      }
    }
  }
  &-menu {
    margin-bottom: 32px;
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      padding-right: 32px;
      font-size: rem(14);
      color: colors(link);
      &:after {
        @include arrow_line(right,8,1,colors(link),middle);
        right: 16px;
      }
    }
  }
  &-share {
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(14);
      text-align: center;
    }
    &-list {
      display: flex;
      padding: 8px;
      background-color: colors(bg);
      &__item {
        width: calc((100% - 16px * 3 ) / 4);
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
      &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 32px;
        &::before {
          font-size: rem(20);
          color: colors(base);
        }
        &:hover {
          text-decoration: none;
          @include mq(laptop) {
            opacity: .7;
          }
        }
        &.-facebook {
          background-color: colors(facebook);
          &::before {
            @include icon(facebook);
          }
        }
        &.-x {
          background-color: colors(x);
          &::before {
            @include icon(x);
          }
        }
        &.-line {
          background-color: colors(line);
          &::before {
            @include icon(line);
          }
        }
        &.-mail {
          background-color: colors(icon);
          &::before {
            @include icon(mail);
          }
        }
      }
      &__txt {
        display: none;
      }
    }
  }
}