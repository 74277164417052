.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 64px;
  background-color: colors(base);
  @include mq(desktop) {
    height: 88px;
    transition: all 0.3s ease;
    &.is-thin {
      height: 64px;
      box-shadow: 0 0 4px 0 rgba(0,0,0,.075);
    }
  }
  &__inner {
    padding: 8px 0;
    @include mq(desktop) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 0 32px;
      margin: 0 auto;
    }
  }
}

.p-logo {
  margin: auto;
  width: 144px;
  font-size: rem(16);
  @include mq(desktop) {
    width: 160px;
    margin-right: 0;
    margin-left: 0;
  }
  &__link {
    display: block;
  }
  &__img {
    width: 100%;
  }
}

.p-hamburger {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 64px;
  @include mq(desktop) {
    display: none;
  }
  &__line {
    display: block;
    position: relative;
    width: 16px;
    height: 2px;
    margin: auto;
    background: colors(icon);
    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      width: 16px;
      height: 2px;
      margin: 0 auto;
      background: colors(icon);
    }
    &::before {
      margin-top: -6px;
    }
    &:after {
      margin-top: 6px;
    }
  }
}

.p-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: rgba(colors(base), .6);
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 24px;
    right: 16px;
    width: 24px;
    height: 2px;
    background: colors(icon);
  }
  &::before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(135deg);
  }
  &.is-show {
    display: block;
  }
}