.p-global {
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: -264px;
  z-index: 30;
  width: 264px;
  height: 100vh;
  background-color: colors(primary-dark);
  transition: left .2s ease-in-out;
  &.is-show {
    left: 0;
  }
  @include mq(desktop) {
    overflow: visible;
    position: static;
    left: auto;
    top: auto;
    max-width: device(desktop-inner);
    width: calc(100% - 160px - 96px - 32px);
    height: 100%;
    background-color: transparent;
    transition: none;
  }
  &-nav {
    @include mq(desktop) {
      display: flex;
      justify-content: center;
      height: 100%;
    }
    &__item {
      border-bottom: 1px solid colors(primary-light);
      @include mq(desktop) {
        margin-right: 24px;
        border-bottom: none;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__txt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 56px;
      padding-right: 16px;
      padding-left: 16px;
      font-size: rem(16);
      color: colors(base);
      cursor: pointer;
      &:before,
      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 12px;
        bottom: 0;
        width: 16px;
        height: 1px;
        margin-top: auto;
        margin-bottom: auto;
        background: colors(base);
        transition-duration: .3s;
      }
      &::before {
        transform: rotate(90deg);
      }
      &.is-open {
        &::before {
          transform: rotate(0deg);
        }
      }
      @include mq(desktop) {
        height: 100%;
        padding-left: 0;
        font-size: rem(14);
        color: colors(font);
        &::before {
          display: none;
        }
        &:after {
          top: -6px;
          right: 0;
          width: 8px;
          height: 8px;
          background-color: transparent;
          border-top: 1px solid colors(icon);
          border-right: 1px solid colors(icon);
          transition-duration: 0;
          transform: rotate(135deg);
        }
        &:hover {
          opacity: .7;
        }
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 56px;
      padding-right: 16px;
      padding-left: 16px;
      font-size: rem(16);
      color: colors(base);
      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-top: 1px solid colors(base);
        border-right: 1px solid colors(base);
        transform: rotate(45deg);
      }
      @include mq(desktop) {
        height: 100%;
        padding-right: 0;
        padding-left: 0;
        font-size: rem(14);
        color: colors(font);
        &:after {
          display: none;
        }
        &:hover {
          text-decoration: none;
          opacity: .7;
        }
      }
    }
    &__inner {
      display: none;
      background-color: colors(primary);
      @include mq(desktop) {
        position: absolute;
        left: 0;
        width: 100%;
      }
    }
  }
}

.p-cat {
  &-nav {
    @include mq(desktop) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: device(desktop-inner);
      padding-top: 32px;
      padding-bottom: 32px;
      margin-right: auto;
      margin-left: auto;
      &::before {
        @include arrow(top,8,colors(primary));
        top: -20px;
        right: 672px;
        left: 0;
        margin-right: auto;
        margin-left: auto;
      }
    }
    &__block {
      @include mq(desktop) {
        @include grid(12,4,16px);
        &:nth-of-type(n+4) {
          margin-top: 32px;
        }
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding-right: 16px;
      padding-left: 40px;
      border-top: 1px solid colors(primary-light);
      font-size: rem(16);
      color: colors(base);
      &::before {
        left: 8px;
        font-size: rem(24);
        color: inherit;
      }
      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-top: 1px solid colors(base);
        border-right: 1px solid colors(base);
        transform: rotate(45deg);
      }
      @include mq(desktop) {
        height: 32px;
        padding-right: 0;
        padding-left: 48px;
        margin-bottom: 16px;
        border-top: none;
        font-size: rem(18);
        &::before {
          left: 0;
          font-size: rem(32);
        }
        &:after {
          display: none;
        }
      }
    }
  }
  &-menu {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid colors(primary-light);
    @include mq(desktop) {
      border-top: none;
    }
    &__item {
      width: 50%;
      border-right: 1px solid colors(primary-light);
      border-bottom: 1px solid colors(primary-light);
      &:nth-child(even) {
        border-right: none;
      }
      &:first-child:nth-last-child(odd),
      &:first-child:nth-last-child(odd) ~ li {
        &:last-child {
          border-bottom: none;
        }
      }
      &:first-child:nth-last-child(even),
      &:first-child:nth-last-child(even) ~ li {
        &:nth-last-child(-n+2) {
          border-bottom: none;
        }
      }
      @include mq(desktop) {
        margin-bottom: 16px;
        border-right: none;
        border-bottom: none;
        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding-right: 8px;
      padding-left: 8px;
      font-size: rem(11);
      color: colors(base);
      &::before {
        display: none;
      }
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-top: 1px solid colors(base);
        border-right: 1px solid colors(base);
        transform: rotate(45deg);
      }
      @include mq(desktop) {
        height: 24px;
        padding-right: 0;
        padding-left: 32px;
        font-size: rem(12);
        &::before {
          display: block;
          left: 0;
          font-size: rem(24);
          color: inherit;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

.p-designer {
  &-nav {
    @include mq(desktop) {
      width: device(desktop-inner);
      padding-top: 32px;
      padding-bottom: 32px;
      margin-right: auto;
      margin-left: auto;
      &::before {
        @include arrow(top,8,colors(primary));
        top: -20px;
        right: 432px;
        left: 0;
        margin-right: auto;
        margin-left: auto;
      }
    }
    &__header {
      @include mq(desktop) {
        margin-bottom: 24px;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding-right: 16px;
      padding-left: 40px;
      border-top: 1px solid colors(primary-light);
      font-size: rem(16);
      color: colors(base);
      &::before {
        left: 8px;
        font-size: rem(24);
        color: inherit;
      }
      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-top: 1px solid colors(base);
        border-right: 1px solid colors(base);
        transform: rotate(45deg);
      }
      @include mq(desktop) {
        padding-right: 0;
        padding-left: 48px;
        border-top: none;
        font-size: rem(18);
        &::before {
          left: 0;
          font-size: rem(32);
        }
        &:after {
          display: none;
        }
      }
    }
  }
  &-menu {
    border-top: 1px solid colors(primary-light);
    @include mq(desktop) {
      display: flex;
      flex-wrap: wrap;
      border-top: none;
    }
    &__item {
      border-bottom: 1px solid colors(primary-light);
      @include mq(desktop) {
        display: flex;
        @include grid(12,3,16px);
        margin-right: 16px;
        margin-bottom: 16px;
        border-bottom: none;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-last-child(-n+4) {
          margin-bottom: 0;
        }
      }
    }
    &__photo {
      display: none;
      @include mq(desktop) {
        display: block;
        width: 64px;
        margin-right: 8px;
      }
    }
    &__img {
      display: none;
      @include mq(desktop) {
        display: block;
      }
    }
    &__link {
      display: flex;
      flex-flow: column;
      justify-content: center;
      position: relative;
      height: 48px;
      padding-right: 32px;
      padding-left: 8px;
      font-size: rem(12);
      color: colors(base);
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 16px;
        width: 8px;
        height: 8px;
        border-top: 1px solid colors(base);
        border-right: 1px solid colors(base);
        transform: rotate(45deg);
      }
      @include mq(desktop) {
        flex: 1;
        justify-content: flex-start;
        height: auto;
        padding-right: 0;
        padding-left: 0;
        font-size: rem(14);
        &:after {
          display: none;
        }
      }
    }
    &__en {
      display: block;
      font-size: rem(10);
      letter-spacing: .06em;
      @include mq(desktop) {
        margin-top: 4px;
        font-size: rem(12);
      }
    }
  }
}

.p-stock {
  &-nav {
    @include mq(desktop) {
      width: device(desktop-inner);
      padding-top: 32px;
      padding-bottom: 32px;
      margin-right: auto;
      margin-left: auto;
      &::before {
        @include arrow(top,8,colors(primary));
        top: -20px;
        right: 208px;
        left: 0;
        margin-right: auto;
        margin-left: auto;
      }
    }
    &__header {
      @include mq(desktop) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding-right: 16px;
      padding-left: 40px;
      border-top: 1px solid colors(primary-light);
      font-size: rem(16);
      color: colors(base);
      &::before {
        left: 8px;
        font-size: rem(24);
        color: inherit;
      }
      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-top: 1px solid colors(base);
        border-right: 1px solid colors(base);
        transform: rotate(45deg);
      }
      @include mq(desktop) {
        padding-right: 0;
        padding-left: 48px;
        border-top: none;
        font-size: rem(18);
        &::before {
          left: 0;
          font-size: rem(32);
        }
        &:after {
          display: none;
        }
      }
    }
    &__desc {
      display: none;
      @include mq(desktop) {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        padding: 8px;
        background-color: colors(base);
        font-size: rem(13);
      }
    }
    &__note {
      font-size: rem(11);
    }
  }
  &-menu {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid colors(primary-light);
    @include mq(desktop) {
      justify-content: flex-start;
      border-top: none;
    }
    &__item {
      width: 50%;
      border-right: 1px solid colors(primary-light);
      border-bottom: 1px solid colors(primary-light);
      &:nth-child(even) {
        border-right: none;
      }
      &:first-child:nth-last-child(odd),
      &:first-child:nth-last-child(odd) ~ li {
        &:last-child {
          border-bottom: none;
        }
      }
      &:first-child:nth-last-child(even),
      &:first-child:nth-last-child(even) ~ li {
        &:nth-last-child(-n+2) {
          border-bottom: none;
        }
      }
      @include mq(desktop) {
        @include grid(12,3,16px);
        border-right: none;
        border-bottom: none;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding-right: 8px;
      padding-left: 8px;
      font-size: rem(11);
      color: colors(base);
      &::before {
        display: none;
      }
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-top: 1px solid colors(base);
        border-right: 1px solid colors(base);
        transform: rotate(45deg);
      }
      @include mq(desktop) {
        height: 48px;
        padding-right: 0;
        padding-left: 40px;
        font-size: rem(14);
        &::before {
          display: block;
          left: 0;
          font-size: rem(24);
          color: inherit;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}
