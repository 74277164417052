.p-corporate {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    margin-bottom: 8px;
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &__lead {
    font-size: rem(14);
    @include mq(laptop) {
      font-size: rem(16);
    }
  }
  &-service {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(20);
      text-align: center;
      @include mq(tablet) {
        text-align: left;
      }
      @include mq(laptop) {
        font-size: rem(24);
      }
    }
    &__maker {
      padding-right: 8px;
      padding-left: 8px;
      background: linear-gradient(transparent 80%, colors(tertiary) 0%);
      @include mq(tablet) {
        padding-right: 16px;
        padding-left: 0;
      }
    }
    &__desc {
      font-size: rem(14);
      text-align: center;
      @include mq(tablet) {
        text-align: left;
      }
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
  }
  &-photo {
    margin-top: 16px;
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &__item {
        width: calc((100% - 16px) / 2);
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(n + 5) {
          width: 100%;
        }
        @include mq(tablet) {
          &:nth-child(-n + 4) {
            width: calc((100% - (16px * 3)) / 4);
          }
          &:nth-child(n + 5) {
            width: calc((100% - (16px * 1)) / 2);
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &-case {
    margin-top: 16px;
    &__inner {
      @include mq(tablet) {
        display: flex;
      }
    }
    &__banner {
      @include grid(8,6,16px);
      margin-right: auto;
      margin-bottom: 16px;
      margin-left: auto;
      @include mq(tablet) {
        @include grid(8,3,16px);
        margin-right: 16px;
        margin-bottom: 0;
        margin-left: 0;
      }
      @include mq(laptop) {
        @include grid(12,4,16px);
      }
    }
    &__cont {
      @include mq(tablet) {
        display: flex;
        flex-flow: column;
        @include grid(8,5,16px);
      }
      @include mq(laptop) {
        @include grid(12,8,16px);
      }
    }
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(18);
      text-align: center;
      @include mq(tablet) {
        text-align: left;
      }
      @include mq(laptop) {
        margin-bottom: 16px;
        font-size: rem(20);
      }
    }
    &__txt {
      font-size: rem(13);
      @include mq(tablet) {
        flex-grow: 1;
      }
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__link {
      position: relative;
      display: block;
      width: 100%;
      padding-right: 16px;
      margin-top: 8px;
      font-size: rem(14);
      text-align: right;
      &:after {
        @include arrow_line(right,8,1,colors(link),middle);
        right: 4px;
      }
      @include mq(laptop) {
        font-size: rem(14);
      }
    }
  }
}