.p-designer {
  padding: 32px 8px;
  @include mq(laptop) {
    padding: 32px 0;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    display: flex;
    align-items: center;
    position: relative;
    height: 32px;
    padding-left: 32px;
    margin-bottom: 16px;
    font-size: rem(22);
    &::before {
      left: 0;
      font-size: rem(24);
    }
    @include mq(tablet) {
      padding-left: 48px;
      margin-bottom: 32px;
      font-size: rem(24);
      &::before {
        font-size: rem(32);
      }
    }
  }
  &__desc {
    font-size: rem(14);
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 64px;
    &__item {
      width: calc((100% - 16px) / 2);
      margin-right: 16px;
      margin-bottom: 16px;
      &:nth-child(even) {
        margin-right: 0;
      }
      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
      @include mq(tablet) {
        width: calc((100% - 16px * 3) / 4);
        margin-bottom: 24px;
        &:nth-child(even) {
          margin-right: 16px;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-last-child(-n+4) {
          margin-bottom: 0;
        }
      }
    }
  }
  &-block {
    position: relative;
    &:hover {
      opacity: .8;
    }
    &__photo {
      margin-bottom: 16px;
    }
    &__img {
    }
    &__ttl {
      display: flex;
      justify-content: center;
    }
    &__link {
      font-size: rem(14);
      text-align: center;
      text-decoration: none;
      color: colors(font);
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__en {
      display: block;
      font-size: rem(12);
      letter-spacing: .1em;
    }
  }
}