.p-shop {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__title {
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &__inner {
    margin-bottom: 32px;
    @include mq(tablet) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 64px;
    }
  }
  &-image {
    margin-bottom: 32px;
    @include mq(tablet) {
      flex: 1 1 512px;
      margin-right: 16px;
      margin-bottom: 0;
    }
    &__photo {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__caption {
      margin-top: 4px;
      text-align: center;
    }
  }
  &-info {
    @include mq(tablet) {
      flex: 1 1 512px;
    }
    &__text {
      margin-bottom: 16px;
      font-size: rem(16);
      @include mq(laptop) {
        margin-bottom: 32px;
      }
    }
  }
  &-list {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include mq(laptop) {
      display: flex;
      align-items: baseline;
    }
    &__title {
      margin-bottom: 4px;
      font-size: rem(16);
      font-weight: bold;
      @include mq(laptop) {
        flex: 0 0 120px;
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
    &__content {
      font-size: rem(16);
      @include mq(laptop) {
        flex: 1 1 auto;
      }
    }
  }
  &-sns {
    display: flex;
    margin-top: 8px;
    &__item {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      color: colors(base);
      &:hover {
        text-decoration: none;
        @include mq(laptop) {
          opacity: .7;
        }
      }
      &.-facebook {
        background-color: colors(facebook);
        &::before {
          @include icon(facebook);
          font-size: rem(28);
        }
      }
      &.-instagram {
        background-color: colors(instagram);
        &::before {
          @include icon(instagram);
          font-size: rem(28);
        }
      }
      &.-youtube {
        background-color: colors(youtube);
        &::before {
          @include icon(youtube);
          font-size: rem(28);
        }
      }
      &.-line {
        background-color: colors(line);
        &::before {
          @include icon(line);
          font-size: rem(28);
        }
      }
    }
  }
}