.p-inquiry {
  padding: 16px;
  margin-top: 48px;
  border: 4px solid colors(tertiary);
  border-radius: 4px;
  @include mq(laptop) {
    max-width: 864px;
    padding: 24px 88px;
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
  }
  &__ttl {
    margin-bottom: 8px;
    font-size: rem(16);
    text-align: center;
    @include mq(laptop) {
      font-size: rem(20);
    }
  }
  &__txt {
    font-size: rem(12);
    text-align: center;
    @include mq(laptop) {
      font-size: rem(14);
    }
  }
  &-list {
    margin-top: 16px;
    @include mq(tablet) {
      display: flex;
      justify-content: space-between;
    }
    &__item {
      width: 100%;
      @include mq(tablet) {
        flex: 1 1 auto;
      }
      &:first-child {
        margin-bottom: 16px;
        @include mq(tablet) {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      font-size: rem(16);
      font-weight: bold;
      color: colors(base);
      &:hover {
        text-decoration: none;
        opacity: .7;
      }
      &::before {
        margin-right: 8px;
        font-size: rem(24);
      }
      @include mq(laptop) {
        height: 64px;
        &:before  {
          font-size: rem(32);
        }
      }
      &.-line {
        background-color: colors(line);
        &::before {
          @include icon(line);
        }
      }
      &.-form {
        background-color: colors(formrun);
        &::before {
          @include icon(form);
        }
      }
    }
    &__txt {
      margin-top: 4px;
      font-size: rem(12);
      text-align: center;
      @include mq(laptop) {
        margin-top: 8px;
      }
    }
  }
  &-tel {
    margin-top: 16px;
    @include mq(tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__num {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-size: rem(28);
      color: colors(icon);
      &::before {
        @include icon(phone);
        margin-right: 8px;
        font-size: rem(24);
      }
      &:hover {
        text-decoration: none;
        opacity: .7;
      }
    }
    &-box {
      margin-bottom: 8px;
      @include mq(tablet) {
        margin-bottom: 0;
      }
      &__ttl {
        font-size: rem(14);
        @include mq(laptop) {
          font-size: rem(18);
        }
      }
      &__cont {
        display: flex;
        flex-direction: column;
        @include mq(tablet) {
          flex-direction: row;
        }
      }
      &__txt {
        font-size: rem(12);
        @include mq(laptop) {
          font-size: rem(14);
        }
        &:first-child {
          @include mq(tablet) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}