.p-covid19 {
  max-width: 688px;
  margin-right: auto;
  margin-bottom: 32px;
  margin-left: auto;
  border: 2px solid colors(alert);
  border-radius: 4px;
  color: colors(alert);
  font-weight: bold;
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 48px;
    font-size: rem(18);
    font-weight: bold;
    cursor: pointer;
    @include mq(laptop) {
      height: 56px;
      font-size: rem(22);
      &:hover {
        opacity: .7;
      }
    }
    &::before {
      @include icon(circle-add);
      position: absolute;
      right: 8px;
      font-size: rem(24);
      @include mq(laptop) {
        right: 16px;
        font-size: rem(32);
      }
      .is-open & {
        @include icon(circle-remove);
      }
    }
  }
  &__inner {
    display: none;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    @include mq(laptop) {
      padding-right: 32px;
      padding-bottom: 32px;
      padding-left: 32px;
    }
    .is-open & {
      display: block;
    }
  }
  &__text {
    font-size: rem(14);
  }
  &-measures {
    margin-top: 16px;
    &__title {
      margin-bottom: 8px;
      font-size: rem(16);
      font-weight: bold;
      @include mq(laptop) {
        font-size: rem(20);
      }
    }
    &-list {
      padding-left: 16px;
      list-style: decimal;
      &__item {
        margin-bottom: 4px;
        font-size: rem(14);
        @include mq(laptop) {
          margin-bottom: 8px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}