.p-recommend {
  overflow: hidden;
  position: relative;
  margin-bottom: 64px;
  &-list {
    display: flex;
  }
  &-block {
    position: relative;
    &:hover {
      opacity: .8;
    }
    &__ttl {
      margin-bottom: 4px;
      font-size: rem(12);
      @include mq(laptop) {
        font-size: rem(14);
      }
    }
    &__link {
      color: colors(font);
      text-decoration: none;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__feature {
      margin-bottom: 8px;
    }
    &__img {
    }
    &__info {
      font-size: rem(16);
      @include mq(laptop) {
        font-size: rem(18);
      }
    }
    &__price {
      display: inline-block;
      margin-right: 4px;
    }
    &__tax {
      font-size: rem(12);
      @include mq(laptop) {
        font-size: rem(14);
      }
    }
  }
}