.p-list {
  padding: 32px 0 64px;
  &__header {
    padding: 0 8px;
    margin-bottom: 24px;
    @include mq(laptop) {
      padding: 0;
    }
  }
  &__ttl {
    display: flex;
    align-items: center;
    position: relative;
    height: 32px;
    padding-left: 32px;
    margin-bottom: 32px;
    font-size: rem(22);
    line-height: 1.3;
    &::before {
      left: 0;
      font-size: rem(24);
    }
    &.-search {
      font-size: rem(14);
    }
    @include mq(tablet) {
      padding-left: 48px;
      font-size: rem(24);
      &::before {
        font-size: rem(32);
      }
      &.-search {
        font-size: rem(24);
      }
    }
  }
  &__num {
    display: inline-block;
    margin-left: 8px;
    font-size: rem(14);
  }
  &-designer {
    margin-bottom: 16px;
    @include mq(tablet) {
      display: flex;
      justify-content: space-between;
    }
    &__inner {
      @include mq(tablet) {
        flex: 1 1 auto;
      }
    }
    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
      font-size: rem(18);
      @include mq(tablet) {
        flex-direction: row;
        margin-bottom: 8px;
        font-size: rem(22);
      }
    }
    &__en {
      font-size: rem(14);
      @include mq(tablet) {
        font-size: rem(16);
        margin-left: 8px;
      }
    }
    &__text {
      position: relative;
      overflow: hidden;
      height: 80px;
      font-size: rem(14);
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 16px;
        background-image: linear-gradient(180deg, rgba(colors(base),.8), colors(base));
      }
      &.is-close {
        height: auto;
        &::before {
          display: none;
        }
      }
      @include mq(tablet) {
        height: 120px;
      }
      @include mq(laptop) {
        height: 144px;
      }
      @include mq(desktop) {
        overflow: visible;
        height: auto;
        &::before {
          display: none;
        }
      }
    }
    &__feature {
      @include grid(16,12,16px);
      margin-right: auto;
      margin-bottom: 8px;
      margin-left: auto;
      text-align: center;
      @include mq(tablet) {
        flex: 0 0 calc((100% - 16px * 3)/ 4);
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }
  &__msg {
    padding: 16px;
    margin-bottom: 24px;
    border: 4px solid colors(tertiary);
    font-size: rem(14);
    font-weight: bold;
    text-align: center;
  }
  &__desc {
    position: relative;
    margin-bottom: 24px;
  }
  &__txt {
    position: relative;
    overflow: hidden;
    height: 40px;
    font-size: rem(12);
    @include mq(tablet) {
      font-size: rem(14);
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 16px;
      background-image: linear-gradient(180deg, rgba(colors(base),.8), colors(base));
    }
    &.is-close {
      height: auto;
      &::before {
        display: none;
      }
    }
    @include mq(tablet) {
      overflow: visible;
      height: auto;
      &::before {
        display: none;
      }
    }
  }
  &__readmore {
    position: relative;
    width: 100%;
    height: 24px;
    &::before {
      @include arrow_line(bottom,10,1,colors(icon));
      top: 4px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    &.is-close {
      position: relative;
      &::before {
        @include arrow_line(top,10,1,colors(icon));
        top: 8px;
      }
    }
    @include mq(desktop) {
      display: none;
    }
  }
  &-filter {
    position: relative;
    margin-bottom: 24px;
    &__btn {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 48px;
      padding-left: 16px;
      background-color: colors(base);
      border: 1px solid colors(border);
      font-size: rem(14);
      font-weight: bold;
      &::before {
        @include arrow_line(bottom,12,1,colors(icon));
        top: -4px;
        right: 16px;
        bottom: 0;
        margin: auto 0;
      }
      &.-show {
        &::before {
          @include arrow_line(top,12,1,colors(icon));
          top: 6px;
        }
      }
      @include mq(laptop) {
        display: none;
      }
    }
  }
  &-menu {
    display: none;
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: colors(base);
    border-right: 1px solid colors(border);
    border-bottom: 1px solid colors(border);
    border-left: 1px solid colors(border);
    @include mq(laptop) {
      display: flex;
      flex-wrap: wrap;
      position: static;
      border: none;
    }
    &__item {
      border-bottom: 1px solid colors(border);
      &:last-child {
        border-bottom: none;
      }
      @include mq(laptop) {
        margin-right: 16px;
        margin-bottom: 16px;
        border: none;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__link {
      &:hover {
        opacity: .7;
      }
    }
    &__txt,
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 48px;
      padding-right: 8px;
      padding-left: 40px;
      font-size: rem(14);
      text-decoration: none;
      color: colors(font);
      @include mq(laptop) {
        padding-right: 16px;
        border: 1px solid colors(border);
        font-size: rem(12);
        font-weight: bold;
      }
      &::before {
        left: 8px;
        font-size: rem(24);
        color: inherit;
      }
      &.-current {
        background-color: colors(disabled);
        color: colors(base);
      }
      &.-stocklist {
        background-color: colors(primary);
        color: colors(base);
      }
    }
  }
  &-sort {
  }
  &-order {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include mq(laptop) {
      width: 280px;
      margin-left: auto;
    }
    &__item {
      position: relative;
      width: calc((100% - 16px * 2) / 3);
      margin-right: 16px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -8px;
        bottom: 0;
        margin: auto 0;
        width: 1px;
        height: 12px;
        border-right: 1px solid colors(border);
      }
      &:last-child {
        margin-right: 0;
        &::before {
          display: none;
        }
      }
    }
    &__txt,
    &__link {
      display: block;
      font-size: rem(14);
      text-align: center;
    }
    &__txt {
      font-weight: bold;
    }
  }
  &-result {
    font-size: rem(12);
    text-align: center;
  }
}