.p-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: rgba(colors(font), .6);
  &.is-show {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}