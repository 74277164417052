.p-pagination {
  padding: 0 8px;
  margin-bottom: 16px;
  @include mq(laptop) {
    padding: 0;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &__item {
      margin-right: 8px;
      &:first-child {
        width: calc((100% - 16px) / 2);
        margin-right: 16px;
        margin-bottom: 16px;
      }
      &:nth-child(2) {
        width: calc((100% - 16px) / 2);
        margin-right: 0;
        margin-bottom: 16px;
      }
      &:last-child {
        margin-right: 0;
      }
      @include mq(tablet) {
        &:first-child {
          order: -1;
          width: 112px;
          margin-bottom: 0;
        }
        &:nth-child(2) {
          order: 1;
          width: 112px;
          margin-right: 0;
          margin-bottom: 0;
        }
        &:last-child {
          margin-right: 16px;
        }
      }
    }
    &__txt,
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 32px;
      height: 40px;
      border: 1px solid colors(border);
      font-size: rem(12);
      font-weight: bold;
      text-decoration: none;
      color: colors(font);
      &.-arrow {
        width: 100%;
        font-size: rem(14);
        &::before {
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
        &.-prev {
          &::before {
            @include arrow_line(left,10,1,colors(icon));
            left: 16px;
          }
        }
        &.-next {
          &::before {
            @include arrow_line(right,10,1,colors(icon));
            right: 16px;
          }
        }
      }
      &.-current {
        background-color: colors(secondary);
        border-color: colors(secondary);
        color: colors(base);
        &.-prev,
        &.-next {
          &::before {
            border-color: colors(base);
          }
        }
      }
      &.-ellipsis {
        width: 8px;
        border: none;
      }
      @include mq(tablet) {
        width: 40px;
      }
    }
    &__link {
      &:hover {
        background-color: colors(secondary);
        border-color: colors(secondary);
        color: colors(base);
        text-decoration: none;
        &.-prev,
        &.-next {
          &::before {
            border-color: colors(base);
          }
        }
      }
    }
  }
}