@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$font-family: (
  key0: "Roboto",
  key1: "游ゴシック体",
  key2: "YuGothic",
  key3: "游ゴシック",
  key4: "Yu Gothic",
  key5: "メイリオ",
  key6: "Hiragino Kaku Gothic ProN",
  key7: "Hiragino Sans",
  key8: sans-serif,
);

$font: (
  size: 100%,
  family: map-values($font-family),
  weight: 500,
  height: 1.5,
  spacing: .03em,
);
