.p-staff {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &-sect {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
    margin-bottom: 48px;
    @include mq(tablet) {
      flex-flow: row-reverse;
      &:nth-of-type(even) {
        flex-flow: row;
      }
    }
    &__inner {
      @include mq(tablet) {
        width: calc((100% - 16px) / 2);
        padding: 0;
      }
    }
    &__ttl {
      margin-bottom: 16px;
      font-size: rem(20);
      @include mq(laptop) {
        margin-bottom: 16px;
        font-size: rem(24);
      }
    }
    &__txt {
      margin-bottom: 16px;
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__comment {
      font-size: rem(14);
      @include mq(laptop) {
        font-size: rem(16);
      }
    }
    &__photo {
      position: relative;
      margin-bottom: 24px;
      @include mq(tablet) {
        width: calc((100% - 16px) / 2);
        margin-bottom: 0;
      }
    }
    &__img {
      width: 100%;
      &.-on {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: .7s;
        &.is-fade {
          opacity: 1;
        }
      }
    }
  }
}