.p-law {
  padding: 32px 8px 64px;
  @include mq(laptop) {
    padding: 32px 0 64px;
  }
  &__header {
    margin-bottom: 32px;
  }
  &__ttl {
    font-size: rem(24);
    @include mq(laptop) {
      font-size: rem(28);
    }
  }
  &-box {
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include mq(tablet) {
      display: flex;
    }
    &__ttl {
      margin-bottom: 8px;
      font-size: rem(16);
      font-weight: bold;
      @include mq(tablet) {
        min-width: 200px;
        margin-bottom: 0;
      }
    }
    &__body {
      font-size: rem(16);
      @include mq(tablet) {
        width: calc(100% - 200px);
      }
    }
  }
}