.p-home {
  &-hero {
    position: relative;
    height: 40vh;
    background-image: url("https://file001.shop-pro.jp/PA01327/545/assets/img/home-hero.webp");
    background-size: cover;
    background-position: 50% 0;
    @include mq(tablet) {
      background-position: bottom;
      height: 80vh;
      background-image: url("https://file001.shop-pro.jp/PA01327/545/assets/img/home-hero_large.jpg");
    }
  }
  &-free {
    background-color: colors(bg);
    &__inner {
    }
    &__link {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 24px 8px 8px;
      font-size: rem(14);
      text-align: center;
      text-decoration: none;
      color: colors(font);
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &:after {
        @include arrow_line(right,8,1,colors(icon),middle);
        right: 8px;
      }
      &:hover {
        text-decoration: underline;
        opacity: .7;
      }
      @include mq(laptop) {
        &:after {
          position: relative;
          right: 0;
        }
      }
    }
  }
  &-info {
    background-color: colors(tertiary);
    &__inner {
      padding: 8px;
    }
    &__ttl {
      font-size: rem(13);
      font-weight: bold;
      text-align: center;
    }
    &__txt {
      font-size: rem(12);
      text-align: center;
      margin-top: 4px;
    }
  }
  &-sect {
    &__inner {
      @include mq(desktop) {
        width: device(desktop-inner);
      }
    }
  }
  &-cat {
    @include mq(laptop) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 32px;
    }
    &__block {
      @include mq(laptop) {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-between;
        width: calc((100% - 16px * 2) / 3);
        margin-bottom: 32px;
        &:nth-last-child(-n+3) {
          margin-bottom: 0;
        }
      }
    }
    &__link {
      display: flex;
      align-items: center;
      position: relative;
      height: 56px;
      padding-right: 16px;
      padding-left: 48px;
      border-top: 1px solid colors(border-dark);
      font-size: rem(16);
      color: colors(font);
      text-decoration: none;
      &::before {
        left: 8px;
        font-size: rem(32);
      }
      &:after {
        @include arrow_line(right,10,1,colors(icon),middle);
        right: 16px;
      }
      &:hover {
        opacity: .7;
      }
      @include mq(laptop) {
        border-top: none;
        width: calc((100% - 16px) / 2);
        padding-right: 0;
        font-size: rem(14);
        &::before {
          left: 0;
        }
        &:after {
          display: none;
        }
      }
      @include mq(desktop) {
        font-size: rem(16);
      }
    }
  }
  &-menu {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid colors(border);
    @include mq(laptop) {
      border-top: none;
    }
    &__item {
      width: 50%;
      border-right: 1px solid colors(border);
      border-bottom: 1px solid colors(border);
      &:nth-child(even) {
        border-right: none;
      }
      &:first-child:nth-last-child(odd),
      &:first-child:nth-last-child(odd) ~ li {
        &:last-child {
          border-bottom: none;
        }
      }
      &:first-child:nth-last-child(even),
      &:first-child:nth-last-child(even) ~ li {
        &:nth-last-child(-n+2) {
          border-bottom: none;
        }
      }
      @include mq(laptop) {
        width: calc((100% - 16px) / 2);
        border-right: none;
        border-bottom: none;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      position: relative;
      height: 48px;
      padding-right: 16px;
      padding-left: 32px;
      font-size: rem(11);
      color: colors(font);
      text-decoration: none;
      &::before {
        left: 8px;
        font-size: rem(16);
      }
      &:after {
        @include arrow_line(right,8,1,colors(icon),middle);
        right: 8px;
      }
      &:hover {
        opacity: .7;
      }
      @include mq(laptop) {
        padding-right: 0;
        &::before {
          font-size: rem(24);
          left: 0;
        }
        &:after {
          display: none;
        }
      }
      @include mq(desktop) {
        font-size: rem(12);
      }
    }
  }
  &-news {
    overflow-y: auto;
    padding: 0 8px;
    margin: 0 0 64px;
    @include mq(laptop) {
      width: 624px;
      padding: 0;
      margin: 0 auto 64px;
    }
    @include mq(desktop) {
      width: 688px;
    }
    &__item {
      display: flex;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__date {
      display: inline-block;
      width: 72px;
      margin-right: 8px;
      font-size: rem(12);
      @include mq(tablet) {
        width: 88px;
        margin-right: 16px;
        font-size: rem(14);
      }
    }
    &__txt {
      width: calc(100% - 72px - 8px);
      font-size: rem(12);
      @include mq(tablet) {
        width: calc(100% - 88px - 16px);
        font-size: rem(14);
      }
    }
  }
}