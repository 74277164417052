.c-icon {
  position: relative;
  &::before {
    display: inline-block;
    position: absolute;
    background-image: none!important;
    font-family: 'tanuki-icon' !important;
    font-style: normal !important;
    font-weight: 500;
    font-variant: normal !important;
    text-transform: none;
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    pointer-events: none;
    color: colors(icon);
  }
  &.-tanuki_logo::before{
    content: "\e900";
  }
  &.-user::before{
    content: "\e901";
  }
  &.-search::before{
    content: "\e902";
  }
  &.-cart::before{
    content: "\e903";
  }
  &.-cart-line::before{
    content: "\e904";
  }
  &.-phone::before{
    content: "\e905";
  }
  &.-mail::before{
    content: "\e906";
  }
  &.-payment::before{
    content: "\e907";
  }
  &.-truck::before{
    content: "\e908";
  }
  &.-youtube::before{
    content: "\e909";
  }
  &.-instagram::before{
    content: "\e90a";
  }
  &.-facebook::before{
    content: "\e90b";
  }
  &.-line::before{
    content: "\e90c";
  }
  &.-twitter::before{
    content: "\e90d";
  }
  &.-form::before{
    content: "\e90e";
  }
  &.-circle-add::before{
    content: "\e90f";
  }
  &.-circle-remove::before{
    content: "\e910";
  }
  &.-arrow-up::before{
    content: "\e911";
  }
  &.-arrow-right::before{
    content: "\e912";
  }
  &.-arrow-down::before{
    content: "\e913";
  }
  &.-arrow-left::before{
    content: "\e914";
  }
  &.-chair::before{
    content: "\e915";
  }
  &.-dining-chair::before{
    content: "\e916";
  }
  &.-arm-chair::before{
    content: "\e917";
  }
  &.-stool::before{
    content: "\e918";
  }
  &.-bench::before{
    content: "\e919";
  }
  &.-sofa::before{
    content: "\e91a";
  }
  &.-single-sofa::before{
    content: "\e91b";
  }
  &.-double-sofa::before{
    content: "\e91c";
  }
  &.-triple-sofa::before{
    content: "\e91d";
  }
  &.-ottoman::before{
    content: "\e91e";
  }
  &.-sofa-bed::before{
    content: "\e91f";
  }
  &.-table::before{
    content: "\e920";
  }
  &.-dining-table::before{
    content: "\e921";
  }
  &.-low-table::before{
    content: "\e922";
  }
  &.-side-table::before{
    content: "\e923";
  }
  &.-wagon-table::before{
    content: "\e924";
  }
  &.-nest-table::before{
    content: "\e925";
  }
  &.-sewing-table::before{
    content: "\e926";
  }
  &.-shelf::before{
    content: "\e927";
  }
  &.-book-shelf::before{
    content: "\e928";
  }
  &.-open-shelf::before{
    content: "\e929";
  }
  &.-wall-shelf::before{
    content: "\e92a";
  }
  &.-chest::before{
    content: "\e92b";
  }
  &.-low-chest::before{
    content: "\e92d";
  }
  &.-high-chest::before{
    content: "\e92c";
  }
  &.-side-chest::before{
    content: "\e92e";
  }
  &.-side-board::before{
    content: "\e92f";
  }
  &.-desk::before{
    content: "\e930";
  }
  &.-bureau::before{
    content: "\e931";
  }
  &.-pen::before{
    content: "\e932";
  }
  &.-stocklist::before{
    content: "\e933";
  }
  &.-basket::before{
    content: "\e934";
  }
  &.-container::before{
    content: "\e935";
  }
  &.-wholesaler::before{
    content: "\e936";
  }
  &.-sale::before{
    content: "\e937";
  }
  &.-maintenance::before{
    content: "\e938";
  }
  &.-dish::before{
    content: "\e939";
  }
  &.-light::before{
    content: "\e93a";
  }
  &.-miror::before{
    content: "\e93b";
  }
}