.p-notfound {
  min-height: calc(100vh - 64px - 320px);
  padding: 32px 8px;
  @include mq(laptop) {
    padding: 32px 0;
  }
  &__msg {
    padding: 16px;
    background-color: colors(error);
    font-size: rem(12);
    font-weight: bold;
    text-align: center;
    color: colors(alert);
    @include mq(laptop) {
      padding: 32px;
      font-size: rem(16);
    }
  }
}